
export const SampleContent = {
    heading1: "Rhoncus ultrices",
    motto: "Turpis egestas pretium aenean pharetra magna",

    menu: [
        { title: "about" },
        { title: "pricing" },
        { title: "blog" },
        { title: "sign up", button: true },
    ],

    action: "dictum dolor",

    blocks: [
        {
            content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis mattis gravida elit id mollis. Vestibulum finibus porta mattis. Quisque condimentum ante sed urna lacinia tincidunt. Nam vel ligula ultricies, efficitur ligula at, condimentum orci. Integer in ante mattis, sodales ipsum a, ullamcorper elit.`

        },
        {
            content: `## Adipiscing diam donec
        
Aliquam faucibus felis [vestibulum augue](#) tincidunt dignissim faucibus non nisi. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Nam vitae dui vel tellus malesuada placerat sit amet eu metus. Nam odio velit, fermentum id turpis at, lacinia sollicitudin nisi. Mauris efficitur sapien mauris. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Duis sollicitudin risus nisi, gravida blandit enim ultricies nec. Nunc ultricies, elit in congue laoreet, neque mi ullamcorper massa, non porta turpis orci vel nunc. Mauris tincidunt sit amet risus in congue. Suspendisse pellentesque augue nec tristique sollicitudin.
`

        },
        {
            content: `### Cursus mattis
        
Praesent vel [interdum mauris](#). Nam ac tellus dolor. Curabitur aliquam semper purus vel dictum. Morbi velit sem, vehicula id libero semper, consectetur eleifend tellus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Phasellus convallis mattis turpis. Duis commodo dui sit amet ultrices cursus. Maecenas malesuada hendrerit augue sed mattis. Maecenas quis fermentum dui. Aliquam tristique pulvinar lorem, nec malesuada elit dignissim dapibus. Ut sagittis quam nec lorem mollis, eu tempor risus pharetra. Praesent ac suscipit metus, quis convallis nibh. Etiam tempor dapibus hendrerit. Sed dictum odio sapien, eu viverra libero facilisis eget. Fusce pharetra bibendum urna vel pellentesque. Donec in odio a arcu placerat maximus.

Aenean rhoncus maximus scelerisque. [Cras maximus](#) rhoncus lacus, nec interdum lacus vestibulum sit amet. Aliquam varius elit purus, sit amet ultrices mauris rhoncus eget. Integer ullamcorper lacus lacus, eu feugiat nunc euismod pretium. Aenean ac tortor tellus. Praesent sapien leo, volutpat vitae nisi vel, finibus facilisis turpis. Suspendisse condimentum purus eu mauris mollis tincidunt. Nullam efficitur purus vitae risus placerat, non ultricies arcu consectetur. In porttitor massa at nunc rhoncus, nec scelerisque sapien faucibus. Maecenas in purus sagittis, suscipit purus vel, consequat dui. Vestibulum nisi augue, sodales non aliquam eget, pellentesque et sem. Ut posuere eu magna a facilisis. Mauris nisl arcu, pellentesque ut eleifend quis, eleifend sit amet lectus. Phasellus sodales ultrices lacus, volutpat venenatis turpis posuere suscipit. Sed ipsum urna, auctor quis metus at, vulputate dapibus diam. Curabitur tempus vel libero ultrices elementum.`
        },
        {
            content: `### Felis eget nunc
        
Duis porttitor dictum euismod. Phasellus non nibh quis turpis suscipit gravida. Nulla tempus pretium erat, ut pulvinar nulla suscipit id. Nunc ut arcu maximus odio pharetra fermentum at quis mauris. Ut condimentum fringilla lorem. Curabitur pharetra tristique sem non vulputate. Nulla quis pellentesque felis, ut suscipit massa. Suspendisse fringilla et nisi nec fermentum. Sed tellus lacus, porta nec sapien pulvinar, finibus molestie urna. Proin pulvinar convallis sem sit amet aliquet.

Vestibulum leo magna, dapibus pellentesque ex non, consequat aliquet justo. Aliquam quis sollicitudin lacus. Suspendisse nec ligula venenatis, lobortis ex a, sollicitudin urna. Vestibulum elementum ac nibh volutpat dictum. Integer massa augue, aliquam a nisi at, tincidunt malesuada sem. In orci enim, venenatis et eleifend sit amet, bibendum sit amet nulla. Sed a consequat magna. Aliquam ut orci ac diam tincidunt euismod. Aenean est tortor, scelerisque suscipit lacinia vel, accumsan sed orci.`
        },
    ],

    smallBlock: {
        content: `### Cursus mattis
    
Praesent vel [interdum mauris](#). Nam ac tellus dolor. Curabitur aliquam semper purus vel dictum. Morbi velit sem, vehicula id libero semper, consectetur eleifend tellus.

Aenean rhoncus maximus scelerisque. [Cras maximus](#) rhoncus lacus, nec interdum lacus vestibulum sit amet. Aliquam varius elit purus, sit amet ultrices mauris rhoncus eget. Integer ullamcorper lacus lacus, eu feugiat nunc euismod pretium.`
    }
}