import { useState } from "react";
import { MasterPalette } from "../../PaletteConfiguration/usePaletteConfig";
import { usePaletteList } from "../../PaletteConfiguration/usePaletteList";
import { ColorTheme } from "../Theme";
import { createColorThemeFromPalette } from "./colorTheme";
import { pickRandom } from "./util";

interface ColorThemeGenerator {
    identity: number;
    isLoading: boolean;
    theme: ColorTheme;
    createColorTheme: () => void;
    remixColors: () => void;
}

export const StubPalette: MasterPalette = {
    colors: ["#333333", "#ffffff"],
    options: [
        { colors: { back: 0, text: 1, accent: 1, accent_contrast: 0 } },
        { colors: { back: 1, text: 0, accent: 0, accent_contrast: 1 } },
    ],
    is_active: true,
}

export const useColorThemeGenerator = (): ColorThemeGenerator => {
    const { data: palettes, isLoading, } = usePaletteList(true);
    const [palette, setPalette] = useState<MasterPalette>(StubPalette);
    const [theme, setTheme] = useState<ColorTheme>(createColorThemeFromPalette(StubPalette));

    const createColorTheme = () => {
        const p = palettes.length ? pickRandom(palettes) : StubPalette;
        setPalette(p);
        setTheme(createColorThemeFromPalette(p));
    }

    const remixColors = () => {
        setTheme(createColorThemeFromPalette(palette));
    };

    return {
        identity: palettes.length,
        isLoading,
        theme,
        createColorTheme,
        remixColors,
    }
}