export interface Gtag {
    event: (action: string, category?: string, label?: string, value?: number) => void;
}

export const useGtag = (): Gtag => {
    const event = (action: string, category?: string, label?: string, value?: number) => {
        const w = window as any;
        if(w.gtag) {
            w.gtag('event', action, {
                event_category: category,
                event_label: label,
                value: value || 0,
            });
        }
    }

    return {
        event,
    }
}
