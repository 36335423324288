import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { MarkdownDisplay } from '../../../toolympus/components/schemed/Markdown';
import { SampleContent } from '../SampleContent';
import { Cards } from './Card';
import { Themed } from './Common';
import { Input } from './Input';
import { List } from './List';
import { MockListData } from './MockListData';
import { Toggle } from './Toggle';

interface Props extends Themed {
    
}

const ContentWrapper = styled.div<Themed>`
    padding: 4px ${props => props.t.geometry.horizontalPadding}px;
`;

const Row = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
`;

export const AppContent = (props: Props) => {
    const [mode, setMode] = useState<string>("cards");
    return (
        <ContentWrapper t={props.t}>
            <MarkdownDisplay text={SampleContent.smallBlock.content} />

            <Row>
                <Toggle
                    t={props.t}
                    options={["cards", "list"]}
                    selected={mode}
                    setSelected={setMode}
                    />

                <Input t={props.t} placeholder="search" />
            </Row>

            {mode === "cards" && <>
                <Cards t={props.t} items={MockListData} />
            </>}

            {mode === "list" && <>
                <List t={props.t} items={MockListData} />
            </>}
        </ContentWrapper>
    );
}
