import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import { useSendData } from '../../toolympus/hooks/useSendData';
import { FormGrid } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { useItemWithControls } from '../../toolympus/api/useWithControls';
import { FieldType } from '../../toolympus/hooks/useSchema';

interface Props {
    isOpen: boolean;
    close: () => void;
}

export interface Contact {
    contact: string;
    message: string;
}

export const ContactForm = (props: Props) => {
    const { isOpen, close } = props;
    const [isSent, setIsSent] = useState<boolean>(false);

    const { data, isSaving, send, update } = useSendData<Contact, {}>("/api/contact-form", { contact: "", message: "" }, {
        onSuccess: () => setIsSent(true),
    });

    const { controls } = useItemWithControls({ data, update: c => update({ ...data, ...c }) }, {
        schema: {
            contact: { type: FieldType.text, label: "Email"  },
            message: { type: FieldType.textlong, label: "Message"  },
        }
    })

    return (
        <Dialog open={isOpen} onClose={() => close()} fullWidth maxWidth="sm">
            <DialogTitle>
                Contact us
            </DialogTitle>
            <DialogContent>
                <FormGrid columns="1fr">
                    {!isSent && <Typography key="explanation">
                        Have a question or want to share an idea? Please do!
                    </Typography>}
                    {isSent && <Typography key="thanks">
                        Thank you! We will get back to you as soon as possible.
                    </Typography>}
                    {!isSent && controls([
                        ["contact"],
                        ["message"],
                    ])}
                </FormGrid>
            </DialogContent>
            <DialogActions>
                {!isSent && <Button onClick={() => close()}>cancel</Button>}
                {!isSent &&
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!data.contact.length || !data.message.length || isSaving}
                        onClick={() => send()}>
                        send {isSaving && <LoadingIndicator sizeVariant="s" />}
                    </Button>}
                {isSent && <Button variant="contained" color="primary" onClick={() => close()}>close</Button>}
            </DialogActions>
        </Dialog>
    );
}
