import { withTheme } from '@material-ui/core';
import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import Logo from '../../logo.svg';


const Wrapper = styled.div<{ allowScroll?: boolean }>`
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    overflow: ${props => props.allowScroll ? "auto" : "hidden"};
    padding: 50px 0 0;
    box-sizing: border-box;
`;

export const RibbonItems = styled.div`
    display: flex;
    flex-flow: row;
    gap: 1rem;
`;

const Ribbon = withTheme(styled.div`
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 50px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    z-index: +300;
    box-shadow: 0 0 10px -5px #000051cc;
    background: #ffffff;

    & > span {
        font-weight: 600;
        letter-spacing: 2px;
        font-size: 20px;
    }

    & > img {
        height: 30px;
    }

    ${props => props.theme.breakpoints.up('sm')} {
        padding: 0 100px;
    }
    ${props => props.theme.breakpoints.down('sm')} {
        & ${RibbonItems} {
            visibility: invisible;
        }
    }
`);


interface Props {
    children?: ReactNode;
    ribbonLeft?: ReactNode;
    ribbonRight?: ReactNode;
    allowScroll?: boolean;
}

export const Page = (props: Props) => {
    return (
        <Wrapper allowScroll={props.allowScroll}>
            <Ribbon>
                <RibbonItems>
                    {props.ribbonLeft}
                </RibbonItems>
                <img src={Logo} alt="baesline" />
                <RibbonItems>
                    {props.ribbonRight}
                </RibbonItems>
            </Ribbon>
            {props.children}
        </Wrapper>
    );
}
