import React from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useItemWithControls } from '../../api/useWithControls';
import { OccupyFreeSpace } from '../primitives/ActionRow';
import { ButtonOptionPicker } from '../primitives/ButtonOptionPicker';
import { Form, FormGrid } from '../primitives/Forms';
import { LoadingIndicator } from '../primitives/LoadingIndicator';
import { SearchField } from '../primitives/SearchField';
import { TableForFields } from '../schemed';
import { ContactForm, ContactFormListData } from './useContactFormList';

interface Props {
    data: ContactFormListData;
}

export const ContactFormList = (props: Props) => {
    const {
        views,
        view,
        setView,
        filter,
        setFilter,

        isLoading,
        data,
        schema,

        isEditing,
        editRecord,
        updateEdit,
        cancelEdit,
        startEdit,
    } = props.data;

    const editRecordWithControls = useItemWithControls<ContactForm | null>({ data: editRecord, update: (c: Partial<ContactForm | null>) => c && updateEdit(c) }, { schema });

    return (
        <Form
            title={<FormattedMessage id="contact_form.title" />}
            headerItems={<>
                {isLoading && <LoadingIndicator sizeVariant="m" />}
                <OccupyFreeSpace />
                <SearchField
                    filter={filter}
                    setFilter={setFilter}
                    doSearch={() => { }}
                    noButton
                    />
                <ButtonOptionPicker
                    options={views}
                    selected={view}
                    setSelected={setView}
                    />
            </>}
            >
            <TableForFields
                data={data}
                schema={schema}
                fields={[
                    ["created_datetime", { utcToLocal: true }],
                    ["message"],
                    ["contact"],
                    ["user_id"],
                    ["is_important"],
                ]}
                autoexpander={{ initialRows: 20, increment: 20, }}
                onDblClick={row => startEdit(row)}
                rowStyle={row => row.is_important ? { background: "#ff990040" } : {}}
                />

            <Dialog open={isEditing} onClose={() => cancelEdit()}>
                {editRecord && editRecordWithControls.data && <DialogContent>
                    <FormGrid columns="1fr 1fr 1fr">
                        {editRecordWithControls.controls([
                            ["created_datetime", { disabled: true }],
                            ["contact", { disabled: true }],
                            ["user_id", { disabled: true }],
                        ])}
                    </FormGrid>
                    <FormGrid columns="1fr" noMargin>
                        {editRecordWithControls.controls([
                            ["message", { disabled: true }],
                        ])}
                    </FormGrid>
                    <FormGrid columns="1fr 1fr 1fr" noMargin>
                        {editRecordWithControls.controls([
                            ["is_processed"],
                            ["is_discarded"],
                            ["is_important"],
                        ])}
                    </FormGrid>
                </DialogContent>}
                <DialogActions>
                    <Button color="primary" onClick={() => cancelEdit()}><FormattedMessage id="common.close" /></Button>
                </DialogActions>
            </Dialog>
        </Form>
    );
}
