import React from 'react';
import { FieldType } from '../../hooks/useSchema';
import { expandProps, FormControlProps } from './FormControlProps';
import { Select, DictionarySelect, DictionarySelectMulti, MultiSelect } from './Select';
import { DecimalEdit, NumberEdit } from './NumberEdit';
import styled from 'styled-components/macro';
import { Markdown } from './Markdown';
import { Checkbox } from './Checkbox';
import { TextEdit } from './TextEdit';
import { JsonEdit } from './JsonEdit';
import { DateEdit, DateTimeEdit } from './TimeEdit';

export const WrapperFlex = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-flow: row nowrap;

    & .MuiFormControl-marginDense {
        width: 100%;
    }
`;

const IconBox = styled.div`
    margin-left: 5px;
    display: flex;
`;

export const PlaceholderField = "__placeholder";

const GetFormControl = (props: FormControlProps) => {
    const { row, field, schema, onChange, value, label, isVisible, } = expandProps(props);

    if(!isVisible) {
        return <></>;
    }

    if(field === PlaceholderField) {
        return <div />;
    }

    switch(schema?.type) {
        case FieldType.select:
            return <Select {...props} />;
        case FieldType.dictionarySelect:
            return <DictionarySelect {...props} />;
        case FieldType.bool:
            return <Checkbox {...props} />;
        case FieldType.date:
            return <DateEdit {...props} />;

        case FieldType.datetime:
            return <DateTimeEdit {...props} />;

        case FieldType.number:
            return <NumberEdit {...props} />

        case FieldType.decimal:
            return <DecimalEdit {...props} />

        case FieldType.markdown:
            return <Markdown label={label} value={value} setValue={value => onChange(row, {[field]: value})} />

        case FieldType.dictionarySelectMulti:
            return <DictionarySelectMulti {...props} />

        case FieldType.multiselect:
            return <MultiSelect {...props} />

        case FieldType.json:
            return <JsonEdit {...props} />
        
        case FieldType.password:
            return <TextEdit {...props} isPassword />

        default:
            return <TextEdit {...props} />
    }
}

export const FormControl = (props: FormControlProps) => {
    const { row, field, controlProps } = expandProps(props);
    const { actions } = controlProps.view;

    const wrapperStyle = props.extraProps?.withArea ? { gridArea: field, ...props.extraProps?.wrapperStyle } : props.extraProps?.wrapperStyle;

    return <WrapperFlex style={wrapperStyle}>
        <GetFormControl {...props} />
        {actions && <IconBox>
                {actions(row, field)}
            </IconBox>
        }
    </WrapperFlex>
}
