import { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { apiFetch, FetchTypes } from "./core";
import { LoadedData, PagedLoadedData, useLoadedData, usePagedLoadedData } from "../hooks/useLoadedData"
import { useValidationErrors, ValidationErrors } from "../components/schemed";


interface ListConfig<T> {
    defaultView: string;
    newItem: Partial<T>;
    runAuto?: boolean;
    newItemPath: (created: T) => string | null;
    extraParams?: Record<string, string>;
}

const createDefaultListConfig = <T>(): ListConfig<T> => ({
    defaultView: "all",
    newItem: {},
    newItemPath: (_: T) => null,
})

interface CrudUtilData<T> {
    view: string;
    setView: (_: string) => void;
    filter: string;
    setFilter: (_: string) => void;
    create: () => Promise<T>;
}

type CrudListDataUnpaged<T> = CrudUtilData<T> & LoadedData<T[]>;
type CrudListDataPaged<T> = CrudUtilData<T> & PagedLoadedData<T>;

export type CrudListData<T> = CrudListDataPaged<T> | CrudListDataUnpaged<T>;

export const useCrudPagedList = <T>(url: string, config: Partial<ListConfig<T>>): CrudListData<T> => {
    const { defaultView, newItem, newItemPath } = { ...createDefaultListConfig<T>(), ...config };

    const [view, setView] = useState<string>(defaultView);
    const [filter, setFilter] = useState<string>("");
    const history = useHistory();

    const data = usePagedLoadedData<T>(`${url}?view=${view}&filter=${filter}`);

    const create = () => {
        return apiFetch<T>(url, FetchTypes.POST, newItem)
            .then(e => {
                const path = newItemPath(e);
                if(path) {
                    history.push(path);
                } else {
                    data.reload();
                }
                return e;
             });
    }

    return {
        view,
        setView,
        filter,
        setFilter,
        create,

        ...data,
    }
}

export const useCrudUnpagedList = <T>(url: string | null, config: Partial<ListConfig<T>>): CrudListData<T> => {
    const { defaultView, newItem, newItemPath, extraParams } = { ...createDefaultListConfig<T>(), ...config };

    const [view, setView] = useState<string>(defaultView);
    const [filter, setFilter] = useState<string>("");
    const history = useHistory();

    const params = Object.entries(extraParams || {}).concat([["view", view], ["filter", filter]]);

    const data = useLoadedData<T[]>(`${url}?${params.map(([k,v]) => `${k}=${v}`).join('&')}`, [], config.runAuto !== undefined ? config.runAuto : true);

    const create = () => {
        return url !== null ?
         apiFetch<T>(url, FetchTypes.POST, newItem)
            .then(e => {
                const path = newItemPath(e);
                if(path) {
                    history.push(path);
                } else {
                    data.reload();
                }
                return e;
             })
        : new Promise<T>(() => {return});
    }

    return {
        view,
        setView,
        filter,
        setFilter,
        create,

        ...data,
    }
}

export const useCrudList = useCrudPagedList;

interface ItemConfig<T> {
    defaultValue: T;
    returnPath?: string;
    prepareChanges?: (c: Partial<T>) => Partial<T>;
}

export interface CrudItemData<T> {
    data: T;
    changes: Partial<T>;
    hasChanges: boolean;
    isLoading: boolean;
    errors?: ValidationErrors;

    reload: () => Promise<T>;
    save: () => Promise<T>;
    remove: () => Promise<any>;
    update: (changes: Partial<T>) => void;
}

export const useCrudItem = <T>(url: string, config: ItemConfig<T>): CrudItemData<T> => {
    const { defaultValue, returnPath } = config;
    const [data, setData] = useState<T>(defaultValue);
    const [changes, setChanges] = useState<Partial<T>>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const errors = useValidationErrors();

    const prepareChanges = config.prepareChanges || (v => v);

    const history = useHistory();

    const reload = useCallback(() => {
        setIsLoading(true);
        errors.clearErrors();
        return apiFetch<T>(url)
            .then(item => { setData(item); setIsLoading(false); return item; })
            .catch(e => { setIsLoading(false); throw e; });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url]);

    const save = () => {
        setIsLoading(true);
        errors.clearErrors();
        return apiFetch<T>(url, FetchTypes.PUT, prepareChanges(changes))
            .then(item => { setData(item); setIsLoading(false); setChanges({}); return item; })
            .catch(e => { setIsLoading(false); errors.handleErrors(e); throw e;});
    }

    const remove = () => {
        setIsLoading(true);
        return apiFetch<{}>(url, FetchTypes.DELETE)
            .then(x => {
                setIsLoading(false);
                if(returnPath) {
                    history.replace(returnPath);
                }
                return x; })
            .catch(e => { setIsLoading(false); throw e; });
    }

    const update = (newChanges: Partial<T>) => {
        setChanges(old => ({ ...old, ...newChanges }));
        setData(old => ({ ...old, ...newChanges }));
    }

    
    useEffect(() => {
        reload();
    }, [reload]);

    const hasChanges = changes && Object.keys(changes).length !== 0;

    return {
        data,
        changes,
        hasChanges,
        isLoading,

        reload,
        save,
        remove,
        update,

        errors,
    }
}
