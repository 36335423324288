import React from 'react';
import { ContactFormList } from './ContactFormList';
import { useContactFormList } from './useContactFormList';

interface Props {
    apiPath?: string;
}

export const ContactFormPage = (props: Props) => {
    const data = useContactFormList(props.apiPath || "/api/contact")
    return <ContactFormList data={data} />;
}
