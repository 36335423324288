import axios from 'axios';
import jwt_decode from 'jwt-decode';

export enum FetchTypes {
    GET = "GET",
    POST = "POST",
    PUT = "PUT",
    DELETE = "DELETE"
}

export class UnauthenticatedError extends Error {
    constructor() {
        super("Authentication missing");
    }
}


let onAuthError = () => {};

export const setOnAuthError = (handler: () => void) => 
    onAuthError = handler;

export function apiFetch<T>(url: string, method: FetchTypes = FetchTypes.GET, body: any = undefined, extraParams: any = undefined): Promise<T> {
    const auth = extraParams?.noAuth ?
            {} :
            (extraParams?.authToken ?
                { "Authorization": createAuthHeader(extraParams.authToken) } :
                { "Authorization": getApiTokenForRequest() });
    let params = {
        ...extraParams,
        url,
        headers: auth,
    } as any;

    if (method !== FetchTypes.GET) {
        params = { ...params, method: method as string, data: body };
    }
    return axios.request<T>(params)
        .then(({ data }) => data)
        .catch(error => {
            if (error.response.status === 401 && !extraParams?.noAuth) {
                onAuthError();
                return {} as any; 
            } else {
                throw error;
            }
        });
}

export const apiUploadFile = (url: string, method: FetchTypes, formfield: string, file: any): Promise<any> => {
    const data = new FormData();
    data.append(formfield, file);
    return axios.request({
        url,
        method,
        data,
        headers: {
            "Authorization": getApiTokenForRequest(),
        },
        timeout: 120000,
    })
        .catch(error => {
            if (error.response.status === 401) {
                onAuthError();
                return {} as any; 
            } else {
                throw error;
            }
        });
}

export const apiFetchFile = (url: string) => apiFetch<Blob>(url, FetchTypes.GET, undefined, { responseType: 'blob' });

export const downloadFile = (url: string, filename: string) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);

    return apiFetchFile(url)
        .then(blob => {
            let objectUrl = window.URL.createObjectURL(blob);
            
            anchor.href = objectUrl;
            anchor.download = filename;
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
        })
}

const apiTokenStorageKey = 'apitoken';

export const storeApiToken = (token: string) => localStorage.setItem(apiTokenStorageKey, token);
export const clearApiToken = () => localStorage.removeItem(apiTokenStorageKey);
export const getApiToken = () => localStorage.getItem(apiTokenStorageKey);
export const decodeToken = <IToken>(token: string): IToken => jwt_decode(token) as IToken;

export const getApiTokenThrowing = () => {
    const token = getApiToken();
    if (!token) {
        onAuthError();
        return "";
    }
    return token;
}

export const createAuthHeader = (token: string) => `Bearer ${token}`;

export const getApiTokenForRequest = () => createAuthHeader(getApiTokenThrowing());
