export const enMessages = {
    error: {
        general: "Something bad happened",
        validation: "Some fields contain errors",
        noRights: "You don't have the rights to execute this action",

        files: {
            content_type_not_allowed: "This file type is not allowed. Allowed file types are: {allowed_types}",
            file_too_large: "File is too large. Maximum allowed file size is {max_size_kb}kb",
            alias_occupied: "This alias is already occupied",
        },

        auth: {
            login_taken: "A user with this email already exists",
            password_too_weak: "The password should be 8 characters or longer and should contain all of: lowercase and uppercase characters and digits",
            failed_login: "Incorrect login or password",
            invite_code_invalid: "Invite code is incorrect. Possibly the invite link has expired. Please check your email for a newer link",
            confirmation_code_invalid: "Confirmation code is incorrect. Possibly the confirmation link has expired. Please check your email for a newer link",
            reset_token_invalid: "Password reset token is incorrect. Possibly the reset link has expired. Please check your email for a newer link or request it again",
            user_already_confirmed: "User's email is already confirmed",
            user_doesnt_need_confirmation: "User's email doesn't need confirmation",
            user_blocked: "The user is blocked. This may happen if you enter invalid credentials several times in a row. In this case authorization is blocked for {minutes} minutes. If you think this is a mistake, please get in touch with the administrator",
            passwords_dont_match: 'Passwords do not match',
        },

        dictionary: {
            cant_update_synthetic_dictionary: "Can't update synthetic dictionary - update the value in the source dictionary",
        },

        export: {
            invalid_code: "Export config not found",
        },
    },
    success: {
        clipboard: "Value copied to clipboard"
    },
    login: {
        email: "Email",
        password: "Password",
        submit: "Login",
        errorcreds: "The credentials are invalid. Please try again or reset your password if you forgot it"
    },
    signup: {
        errorMessage: "Something bad happened",
        successMessage: "The signup request has been sent. You will get access, once the administrator approves it",
        email: "Email",
        password: "Password",
        passwordRepeat: "Repeat password",
        submit: "Sign up"
    },
    signupConfirmation: {
        defaultMessage: "Confirming email...",
        successMessage: "Email confirmed successfully. Thank you!",
        errorMessage: "Something went wrong. Please get in touch with us."
    },
    resetPassword: {
        email: "Email",
        submit: "Reset password"
    },
    restorePassword: {
        password: "Password",
        passwordRepeat: "Repeat password",
        submit: "Change password",
        success: "Password was changed successfully. Please log in with the new password",
    },
    emailConfirmation: {
        confirming: "Confirming the Email...",
        success: "Your Email was successfully confirmed. Thank you!",
        failure: "An error happenned. Please try reloading the page.",
    },
    searchModal: {
        title: "Search",
        searchFieldLabel: "Find...",
        pickItem: "Pick",
        emptyState: "Nothing has been found"
    },
    searchField: {
        label: "Find...",
        buttonLabel: "Load",
    },
    userManagement: {
        title: "User Management",
        header: {
            addUser: "Add user",
            sendInvites: "Send invites",
            sendInvintesTip: "Invites will be sent only to new users, no repeats",
            export_users: "Download user list",
        },
        columns: {
            email: "Email",
            lastLogin: "Last Login",
            blocked_until: "Block",
            blocked_until_explanation: "The user is blocked and won't be able to log in until the specified time",
            unblock: "Unblock user",
            block: "Block user",
            invited: "Invited",
            confirmed: "Confirmed",
            resendConfirmRequest: "Resend confirmation request",
            repeatedConfirmRequestNever: "Confirmation request was not repeated",
            repeatedConfirmRequestTime: "Confirmation request was repeated at {time}",
            invite: "Invite",
            signInAs: "Sign in",
            sendPasswordResetToken: "Send password reset code",
        },
        userPopup: {
            title: "Add users",
            email: "Email",
            submit: "Create",
            hint: "enter emails of the users to be created, separated with whitespaces or semicolons"
        },
        changeEmailPopup: {
            title: "Change user's email",
            oldEmail: "Current Email",
            newEmail: "New Email",
        },
        password_reset_email_sent: "Password change code was sent to the user",
    },
    emails: {
        fields: {
            code: "Template",
            title: "Title",
            description: "Description",
            subject: "Subject",
            body: "Body",
            active: "Active",
        },

        popup: {
            title: "Edit Template",
            emailContent: "Email content",
            testEmail: "Send a test email to myself",
            testEmailTextTip: "Use this field to enter parameters in JSON format",
            send: "Send",
            delete: "Delete the template?",
        }
    },
    dictionaries: {
        title: "Dictionaries",
        statusSaved: "Saved",
        newRecordLabel: "New record code",
        addRecord: "Add",
        selectDictionary: "Dictionary",

        fields: {
            code: "Code",
            label: "Label",
            label_translations: "Label translations",
            sortorder: "Order",
            comment: "Comment",
            disabled: "Disabled",
        }
    },
    queryconsole: {
        title: "Query title",
        query: "Query",
        run: "Run",
        history: "History",
    },
    common: {
        cancel: "Cancel",
        close: "Close",
        add: "Add",
        delete: "Delete",
        edit: "Edit",
        replace: "Replace",
        save: "Save",
        yes: "Yes",
        select: "Select",
        deselect: "Deselect",
        copy: "Copy",

        fullscreen: "Fullscreen mode",

        invalid_date_format: "Invalid date format",
    },
    currency_rates: {
        date: "Date",
        rate: "Rate",
        title: "Currency rates",
    },
    formcontrol: {
        markdown: {
            write: 'Edit',
            preview: 'Preview',
        }
    },
    fieldssettings: {
        title: "Fields settings",
        available: "Available fields",
        active: "Active fields",
        reset: "Reset",
    },
    fieldsfilters: {
        title: "Filters",
        emptyState: 'Click here or the "+" button above to add a filter',
        add: 'Add filter',
    },
    files: {
        pageTitle: "Files",
        
        filename: "File name",
        filetype: "Type",
        meta: "Properties",
        alias: "Alias",
        comments: "Comments",

        copyLink: "Copy file link",

        removeConfirmation: "Remove file?",
        editDialogTitle: "File",
    },
    statusActions: {
        action: 'Actions',
        cancel: 'Cancel'
    },
    notifications: {
        title: "Notifications",
        markasread: "Mark as read",
        markallasread: "Mark all as read",

        task: {
            title: "Title / subject",
            user_id: "User",
            email: "Email",
            kind: "Kind",
            display_name: "Name",
            info: "Info",
            text: "Notification / email text",
            link: "Link (for internal notifications)",
            comment: "Comment",
            targets: "Targets",
            is_executed: "Executed",
            executed_time: "Executed at",

            controls: {
                title: "Notifications",
                title_one: "Notification",
                show_executed: "Show executed",
                execute: "Send",
            },

            target: {
                all: "All",
                add_filtered: "Add filtered",
                remove_all: "Remove all",
                noone: "Add targets",
                count: "Total: {count} targets",
            },
        }
    },
    chat: {
        send: 'Send',
        typeMessage: 'Message...',
    },
    errorPasswordsDontMatch: 'Passwords do not match',
    comments: {
        title: "Comments",
        newComment: "Add a comment (Ctrl+Enter to send)",
        deleteConfirmation: "Delete the comment?",
    },

    entity: {
        actions: {
            action_id: "ID",
            entity: "Entity",
            record: "Record",
            action: "Action",
            parameters: "Parameters",
            user_id: "User",
            executed_at: "Time",
        },
    },
    dashboard: {
        update: 'Update',
        errors: {
            value_not_configured: 'Value field not selected for widget',
            query_not_configured: 'Query is not configured for the widget',
            details_query_not_configured: 'Details query is not configured for the widget',
            unknown: 'An error occurred'
        },
        screen: {
            dash: 'Dashboard',
            widgets: 'Widgets',
            edit: 'Edit dashboards',
            create: 'Create a dashboard',
            params: 'Parametrs',
            type: 'Type',
            add: "Create widget",
            addexisting: "Add existing",
            pie: 'Pie chart',
            bar: 'Bar chart',
            counter: 'Counter',
            placeholder: 'Placeholder',
            save: 'Save',
            title: 'Title',
            code: 'Code',
            widgettype: 'Widget type',
        },
        widget: {
            dialog: 'Dashboard settings',
            title: 'Title',
            duplicate: 'Duplicate',
            subtitle: 'Subtitle',
            resultfields: 'Result fields',
            query: 'Query',
            detailsresultfields: 'Details fields',
            label: 'Label',
            name: 'Name',
            link: 'Link',
            links_by_labels: 'Links',
            links_by_labels_hint: 'A single link for all values or several lines in the "value: link" format',
            empty_state_message: 'Empty state message',
            empty_state_message_hint: 'Displayed when there are no records that match the query',
            counter_colors: "Counter colors",
            counter_colors_hint: 'A single html color code or several lines in the "max-value: color-code" format',

            displaySettings: 'Display settings',
            show_pct: 'Show percentage',
            show_table: 'Show table',
            show_chart: 'Show chart',
            total_row: 'Total row',
            value_field: 'Value field',
            label_field: 'Label field',
            query_substitutions: 'Query string substitutions',
            query_string: 'Query string',
            details_query_string: 'Details query string',
            remove: 'Remove widget',
            params: 'Parameters',

            param: {
                label: 'Label',
                default: 'Default value',
                code: 'Code',
                fieldtype: 'Field type',
                fieldtypes: {
                    number: 'Number',
                    text: 'Text',
                }
            },

            select_res_field: {
                fieldtype: 'Result field is just value',
                entity: 'Result field is entity from dictionary',
                fieldtype_label: 'Field type',
                number: 'Number',
                text: 'Text',
                percent: 'Percent',
                entity_label: 'Entity',
                entity_field: 'Entity field',
            }
        }
    },
    pages: {
        pageListTitle: "Pages",
        editPageTitle: "Edit Page",
        componentListTitle: "Components",
        editComponentTitle: "Edit Component",
        deleteConfirmation: "Delete page?",
        page: {
            slug: "Slug",
            language: "Language",
            styles: "Styles",
            properties: "Properties"
        },
        component: {
            order: "Order",
            contenttext: "Content",
            subtypetext: "Type",
            properties: "Properties"
        }
    },

    images: {
        upload_image_hint: "Click to upload an image",
        upload: "Upload",
        delete: "Delete",
    },

    contact_form: {
        title: "Messages",

        labels: {
            message: "Message",
            user_id: "User",
            contact: "E-mail",
            is_processed: "Processed",
            is_discarded: "Spam",
            is_important: "Important",
            created_datetime: "Date",
        },
    },

    tags: {
        labels: {
            label: "Tag",
            count: "Usages count",
        },

        create: "Create tag",
        duplicate: "Duplicate tag",
        edit: "Edit tag",
        remove: "Delete tag?",
        remove_in_use: "The tag is in use. It will be detached from all records upon removal.",
        tag_usage: "Tag usage for ",
        collect_tags: "Collect tags",

        merge: {
            select_source: "Select a tag to merge",
            confirmation: "Merge tags?",
            execute: "Merge",
        },

        error: {
            cant_merge_no_tags: "Tags for merge not selected",
        },
    },

    contests: {
        scoring: {
            sheets_list_title: "Scoring sheets",
            sheet_edit_title: "Scoring sheet: {title}",
            create_sheet: "Create a scoring sheet",
            sheet_preview: "Preview",

            fields: {
                sheet: {
                    code: "Code",
                    title: "Title",
                    description: "Description",
                    criteria: "Criteria",
                },
                criteria: {
                    name: "Name",
                    comment: "Comment/hint",
                    type: "Type",
                    min_value: "Minimum",
                    max_value: "Maximum",
                    options: "Options",
                    is_required: "Required field",

                    custom: "Custom type",
                    use_chips: "Buttons",
                },
            }
        },

        rounds: {
            config: {
                title: 'Rounds',
                labels: {
                    configuration: "Configuration",
                    code: "Code",
                    has_playoff: "Playoff/knockout rounds",
                    playoff_stages_cnt: "Number of stages",
                    playoff_teams_required: "Number of teams required",
                    playoff_team_matching_rule: "Teams matching rule",
                    playoff_has_third_place_game: "3-rd place game",
                    playoff_round_title_final: "Final round title",
                    playoff_round_title_third: "3-rd place round title",
                    playoff_round_title_po: "Playoff round title",
                    
                    has_rating_rounds: "Rating rounds",
                    rating_group_kind: "Group type",
                    rating_group_kind_values: {
                        pairs_stable: "Pairs stable",
                        pairs_shift: "Pairs shifting",
                        triplets: "Triples",
                    },

                    rating_grouping_rule: "Groups matching rule",
                    rating_grouping_rule_values: {
                        random: "Random",
                        sequential: "Sequential",
                        inward: "Inward",
                        halves: "Halves",
                    },
                    rating_player_rounds_cnt: "Number of rounds per team",
                    rating_round_title: "Rating round title",
                    players_accepted_cnt: "Number of teams accepted to the rounds",
                    rating_rounds_cnt: "Number of rating rounds",
                    round_title_hint: "Placeholders available: {stage} {number}",

                    arbiter_access_url: "Arbitrator link format",
                    player_access_url: "Team link format",
                    access_url_hint: "Placeholders available: {round_id}, {party_id}, {access_code}",

                    is_launched: "Launched",
                    launch: "Launch",
                    cancel: "cancel",
                    cancel_confirmation_title: "Cancel rounds?",
                    cancel_confirmation_explanation: "All created stages, rounds, assignments and scores will be removed. Continue?",
                },

                stages: {
                    launch: "Launch",
                    launch_confirmation: "The stage will be launched, teams and arbitrators will gain access to the rounds",
                    launch_forced_confirmation: "There are problems in the rounds. Launch anyway?",
                    unlaunch: "Cancel launch",
                    unlaunch_confirmation: "Launch will be cancelled, access to rounds will be revoked.",
                    finish: "Finish",
                    calc_results: "Calculate results",
                    export: "Export data",
                    finish_confirmation: "The stage will be finished, scoring will be blocked, teams will be sent to the next stage, according to the results of this stage. Continue?",
                    finish_forced_confirmation: "There are problems in the rounds. Finish anyway?",
                    unfinish: "Resume",
                    unfinish_confirmation: "The stage will be resumed, changes to the teams' progress will be cancelled. Continue?",
                    validate: "Validate",
                    clear_problems: "Clear problems",
                    assign_players: "Assign teams",
                    assign_players_confirmation: "Existing team assignments will be overwritten. Continue?",
                    assign_arbiters: "Assign arbitrators",
                    assign_arbiters_confirmation: "Existing arbitrator assignments will be overwritten. Continue?",
                    include_tags: "Include by tags",
                    exclude_tags: "Exclude by tags",

                    clear_arbiters: "Clear arbitrators",
                    clear_arbiters_confirmation: "All arbitrator assignments in this stage will be removed. Continue?",
                    clear_players: "Clear teams",
                    clear_players_confirmation: "All team assignments in this stage will be removed. Continue?",
                    clear_stage: "Re-create",
                    clear_stage_confirmation: "All assignments and all information on the rounds in this stage will be removed. Continue?",

                    qr_codes: "Get QR codes for scoring links",

                    view: "View",
                    view_short: "Simple",
                    view_detailed: "Full",
                    view_results: "Results",

                    names: {
                        po_final: "Final",
                        po_1_2: "Semifinals",
                        po_1_4: "Quarterfinals",
                        po_1_8: "Eigth-finals",
                        po_1_16: "16th-finals",
                        rating: "Rating",
                    },

                    validation: {
                        rounds_with_problems: "There are problems in the rounds ## {value}",
                        players_unassigned: "Teams are not assigned",
                        arbiters_unassigned: "Arbitrators are not assigned",
                        conflicts: "There are conflicts",
                        is_draw: "Draw",
                        missing_scores: "Missing scores: {arbiters}",
                        unequal_player_assignment: "Unequal teams assignment: {players}", 
                    },
                },

                validation: {
                    must_have_some_rounds: "Must have some rounds",
                    specify_playoff_stages_cnt: "Specify the number of playoff/kickout stages",
                    specify_players_accepted: "Specify the number of teams accepted to the rounds",
                    not_enough_players_for_playoff: "Not enough teams for playoff/kickout rounds",
                    players_count_doesnt_match_required_for_playoff: "The number of teams doesn't match required for the playoff/kickout rounds",
                    specify_rating_rounds_cnt: "Specify the number of rating rounds per team",
                    pairs_need_even_teams: "Number of teams must be even to match them in groups",
                    triplets_need_divisible3_teams: "Number of teams must be divisible by 3 to match them in triples",
                    triplets_must_have_2_rounds_per_team: "Must have exactly two rounds per team in triples",
                    cant_update_launched: "Can't update - the rounds are already launched",
                    problems_with_config: "Please fix the issues with the configuration",
                    already_launched: "Rounds are already launched",

                    arbiter_not_found: "Arbitrator not found",
                    cant_assign_player_against_itself: "Team can't play against itself",
                    cant_assign_the_same_arbiter_to_a_round_twice: "Arbitrator can't be assigned to the same round twice",
                    dont_know_how_to_create_multiple_rounds: "Invalid configuration: mutliple pairs matching rule",
                    next_stage_is_finished: "The next stage is finished - roll it back first",
                    only_two_rounds_in_triples: "Invalid configuration:  triples should have exactly two rounds per team",
                    player_not_found: "Team not found",
                    players_count_doesnt_match_required_for_rounds: "The number of teams doesn't match that required for rounds",
                    stage_has_problems: "There are problems with the stage",
                    stage_is_finished: "The stage is already finished",
                    stage_is_launched: "The stage is already launched",
                    stage_is_not_finished: "The stage was not finished yet",
                    stage_is_not_launched: "The stage was not launched yet",
                    unknown_matching_rule: "Invalid configuration: team matching rule",
                },
            },

            stage: {
                labels: {
                    kind: "Type",
                    is_launched: "Launched",
                    is_finished: "Finished",
                },
            },

            round: {
                edit: "Round {title}",
                labels: {
                    title: "Title",
                    number: "Number",
                    time: "Time",
                    description: "Description",
                    winner: "Winner",
                    arbiters: "Arbitrators",
                    vs: "vs",
                    player1: "Team 1",
                    player2: "Team 2",
                    player_unassigned: "not selected",
                    player1_score: "Team 1 result",
                    player2_score: "Team 2 result",
                    arbiter: "Arbitrator {position}",
                    arbiter_unassigned: "not selected",
                },
            },

            player_standing: {
                sort_order: "#",
                display_name: "Team",
                score: "Result",
                is_round_winner: "Won the round",
                pass: "Passes further",

                pass_yes: "Yes",
                pass_no: "No",
            },

            player: {
                select: "Select a team",
                labels: {
                    display_name: "Number",
                    description: "Description",
                    rounds_assigned: "Rounds assigned",
                    tags: "Tags",
                }
            },

            arbiter: {
                select: "Select an arbitrator",
                labels: {
                    display_name: "Name",
                    description: "Description",
                    rounds_assigned: "Rounds assigned",
                }
            },

            scores: {
                score: "Score",
                scored: "Scored",
                select_player: "Select",
                selected_player: "Selected",
            },

            public: {
                stage_names: {
                    po_final: "Final",
                    po_1_2: "Semifinals",
                    po_1_4: "Quarterfinals",
                    po_1_8: "8th-finals",
                    po_1_16: "16th-finals",
                    rating: "Rating rounds",
                }
            }
        }
    },
}