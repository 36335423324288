import React, { useState } from 'react';
import { Button, Dialog, DialogActions, Typography } from '@material-ui/core';
import styled, { css } from 'styled-components/macro';
import { Color } from '../ThemeBrowser/Theme';
import { ChromePicker } from 'react-color';

const colorSquare = (color: Color) => css`
    width: 24px;
    height: 24px;
    border-radius: 2px;
    margin-left: 8px;
    background: ${color};
    box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.4);
`;

export const ColorSquareUnset = styled.div`
    display: block;
    margin-right: 12px;
    ${() => colorSquare("#ffffff")}
`;


export const ColorSqareS = styled.div<{ color: Color }>`
    display: block;
    margin-right: 12px;
    ${props => colorSquare(props.color)}
`;

const ColorViewWrapper = styled.div<{ color: Color }>`
    display: flex;
    flex-flow: row;
    position: relative;
`;

interface ColorViewProps {
    color: Color;
    canEdit?: boolean;
    onChange?: (c: Color) => void;
}

export const ColorView = ({ color, canEdit, onChange }: ColorViewProps) => {
    const [curColor, setCurColor] = useState<Color>(color);
    const [isEditing, setIsEditing] = useState<boolean>(false);

    const startEdit = () => {
        if(canEdit) {
            setCurColor(color);
            setIsEditing(true);
        }
    }

    const completeEdit = () => {
        setIsEditing(false);
        if(onChange) {
            onChange(curColor);
        }
    }

    const cancelEdit = () => {
        setIsEditing(false);
    }

    return <ColorViewWrapper color={color} >
        <ColorSqareS color={color} onClick={startEdit} />
        <Typography component="span" onClick={startEdit}>{color.toUpperCase()}</Typography>
        {canEdit && <Dialog open={isEditing} onClose={cancelEdit}>
            <ChromePicker
                color={curColor}
                onChange={c => setCurColor(c.hex)}
                styles={{ default: { picker: { boxShadow: "none" }} }}
                />
            <DialogActions>
                <Button onClick={cancelEdit}>cancel</Button>
                <Button color="primary" variant="contained" onClick={completeEdit}>save</Button>
            </DialogActions>
        </Dialog>}
    </ColorViewWrapper>

}