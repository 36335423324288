import React from 'react';
import styled from 'styled-components/macro';
import { LogoConfig, PseudoLogo } from '../PseudoLogo';
import { Themed } from './Common';
import { Menu, NotificationsOutlined } from '@material-ui/icons';
import { shadow } from '../Common.css';

interface Props extends Themed {
    logo: LogoConfig;    
}

const Central = styled.div<Themed>`
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: center;
    gap: ${props => props.t.geometry.paddingUnit}px;
    flex: 1;
`;

const RibbonWrapper = styled.div<Themed>`
    min-height: 40px;
    z-index: 200;
    
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
    
    background: ${props => props.t.colors.menu.background};
    color: ${props => props.t.colors.menu.text};
    padding: 4px ${props => props.t.geometry.horizontalPadding}px;

    border-bottom-left-radius: ${props => props.t.elements.ribbon.rounding};
    border-bottom-right-radius: ${props => props.t.elements.ribbon.rounding};

    ${props => shadow('strong-block', props.t.elements.ribbon.separation.strength)}

    & > ${Central} > h4 {
        margin: 0 !important;
    }

    & > ${Central} > svg {
        max-height: 30px;
    }
`;

export const Ribbon = (props: Props) => {
    const { is_present, has_logo, has_app_title } = props.t.elements.ribbon;
    return is_present ? (
        <RibbonWrapper {...props}>
            <Menu />

            <Central {...props}>
                {has_logo &&
                    <PseudoLogo
                        logo={props.logo}
                        color={props.t.logo.color_variant === "text" ? props.t.colors.block.text : props.t.colors.accent}
                        />}
                {has_app_title && <h4>Rhoncus ultrices</h4>}
            </Central>

            <NotificationsOutlined />
        </RibbonWrapper>
    ) : null;
}
