import { Button, ButtonGroup } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { NotificationsNone } from '@material-ui/icons';
import { Page } from '../Common/Page';
import { Link } from 'react-router-dom';
import { ContactForm } from '../ThemeBrowser/ContactForm';
import { UpdatesForm } from '../ThemeBrowser/UpdatesForm';
import { ColorThemeData, useColorTheme } from './useColorTheme';
import { ColorThemeEditor } from './ColorThemeEditor';
import { ColorThemePreview } from './ColorThemePreview';
import { ColorThemeExplanationDialog } from './ColorThemeExplanationDialog';

const PageWrapper = styled.div`
    width: 100%;
    padding: 25px 50px 0;
    gap: 25px;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: max-content 1fr;
    overflow: hidden;
    align-items: center;

    max-height: 100%;
`;


const TopSection = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
`;

const PreviewContainer = styled.div`
    width: 90%;
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.6);
    overflow-y: auto;
    justify-self: center;
    max-height: 100%;

    ::-webkit-scrollbar {
        width: 1px;
        background-color: #aaa;
    }
`;

const PageWrapperX = styled(PageWrapper)`
    max-height: initial;
    padding-bottom: 30px;
`;


interface Props extends ColorThemeData {
}

const useSettings = () => {
    const explanation_k = '__bs_colors_explanation_shown';

    return {
        explanationShown: localStorage.getItem(explanation_k) === 'y',
        setExplanationShown: (v: boolean) => localStorage.setItem(explanation_k, v ? 'y' : 'n'),
    }
}

export const ColorThemeBrowser = (props: Props) => {
    const [isContactOpen, setIsContactOpen] = useState<boolean>(false);
    const [isUpdatesOpen, setIsUpdatesOpen] = useState<boolean>(false);
    
    const settings = useSettings();
    const [isExplanationOpen, setIsExplanationOpenX] = useState<boolean>(!settings.explanationShown);
    const setIsExplanationOpen = (x: (v: boolean) => boolean) => {
        settings.setExplanationShown(true);
        setIsExplanationOpenX(x);
    }

    return (
        <Page
            allowScroll
            ribbonLeft={<>
                <ButtonGroup variant="outlined" disableElevation color="primary" size="small">
                    <Link to="/" style={{ textDecoration: 'none' }}><Button>app</Button></Link>
                    <Link to="/" style={{ textDecoration: 'none' }}><Button>site</Button></Link>
                </ButtonGroup>
            </>}
            ribbonRight={<>
                <Button size="small" onClick={() => setIsContactOpen(true)}>contact</Button>
                <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        startIcon={<NotificationsNone />}
                        onClick={() => setIsUpdatesOpen(true)}>
                    updates
                </Button>
            </>}>
            <PageWrapperX>
                <TopSection>
                    <ColorThemeEditor theme={props} showExplanation={() => setIsExplanationOpen(() => true)} />
                </TopSection>
                
                <PreviewContainer>
                    <ColorThemePreview theme={props.theme} isLoading={props.isLoading} />
                </PreviewContainer>
                
            </PageWrapperX>
            

            <ContactForm isOpen={isContactOpen} close={() => setIsContactOpen(false)} />
            <UpdatesForm isOpen={isUpdatesOpen} close={() => setIsUpdatesOpen(false)} />
            <ColorThemeExplanationDialog isOpen={isExplanationOpen} close={() => setIsExplanationOpen(x => false)} />
        </Page>
    );
}

export const ColorThemeBrowserContainer = () => {
    const tg = useColorTheme();
    return <ColorThemeBrowser {...tg} />;
}
