import { flatten } from 'flat';
import React, { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { messagesDict } from './messagesDict';

export interface LocalizationProviderProps {
    locale: string;
    defaultLocale: string;
    messages?: any;
    children?: ReactNode;
}

const LocalizationProvider: React.FC<LocalizationProviderProps> = (props: LocalizationProviderProps) => {
    const { locale, defaultLocale, messages } = props;

    let mergedMessages = flatten(messages || {}) as any;

    const typedMessagesDict = messagesDict as { [locale: string]: any };

    if (typedMessagesDict[locale]) {
        const localeMessages = flatten(typedMessagesDict[locale]) as Object;
        mergedMessages = { ...localeMessages, ...mergedMessages };
    }

    return (
        <IntlProvider messages={mergedMessages} locale={locale} defaultLocale={defaultLocale}>
            {props.children}
        </IntlProvider>
    )
}

export default LocalizationProvider;