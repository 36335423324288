import React from 'react';
import styled from 'styled-components';
import { buttonShadow, fontParamsCss } from '../Common.css';
import { BlockRoundings } from '../Theme';
import { Themed } from './Common';

interface Props extends Themed {
    options: string[];
    selected: string;
    setSelected: (v: string) => void;
}

const Button = styled.div<Themed & { isSelected?: boolean}>`
    border: 1px solid ${props => props.isSelected ? props.t.colors.action.background : props.t.colors.main.text};
    color: ${props => props.isSelected ? props.t.colors.action.text : props.t.colors.main.text};
    background: ${props => props.isSelected ? props.t.colors.action.background : props.t.colors.main.background};
    cursor: pointer;
    padding: ${props => props.t.geometry.actionPadding.map(p => `${p}px`).join(' ')};

    ${props => fontParamsCss(props.t.fonts.params.action)}
    ${props => buttonShadow(props.t.action.shadow)}
`;

const pickRounding = (r: BlockRoundings, i: number) => Array.isArray(r) ? r[i] : r;

const Wrapper = styled.div<Themed>`
    display: flex;
    flex-flow: row nowrap;
    width: auto;

    & > ${Button}:first-child {
        border-top-left-radius: ${props => pickRounding(props.t.geometry.actionRoundings, 0)};
        border-bottom-left-radius: ${props => pickRounding(props.t.geometry.actionRoundings, 3)};
    }
    & > ${Button}:last-child {
        border-top-right-radius: ${props => pickRounding(props.t.geometry.actionRoundings, 1)};
        border-bottom-right-radius: ${props => pickRounding(props.t.geometry.actionRoundings, 2)};
    }
`;

export const Toggle = (props: Props) => {
    const { options, selected, setSelected, t } = props;
    return (
        <Wrapper t={t}>
            {options.map(o => (
                <Button t={t} isSelected={o === selected} onClick={() => setSelected(o)}>{o}</Button>
            ))}
            
        </Wrapper>
    );
}
