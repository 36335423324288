import React, { ReactNode, useContext } from 'react';
import moment, { MomentInput } from "moment";
import "moment-timezone";
import { useIntl } from 'react-intl';
// @ts-ignore
import "moment/locale/ru";

export const ShortFormat = "D MMM HH:mm";

export type ThousandSeparatorType = ',' | ' ' | ''

const DefaultFormats = {
    date: "yyyy/MM/DD",
    datetime: " DD.MM.yyyy HH:mm",
    shortDatetime: "D MMM HH:mm",
    thousandSeparator: '' as ThousandSeparatorType
}

type Formats = typeof DefaultFormats;

export const FormatsContext = React.createContext<Formats>(DefaultFormats);

export const useFormats = () => {
    const fmts = useContext(FormatsContext);
    const { locale } = useIntl();

    const format = (f: string) => (v: MomentInput) => {
            moment.locale(locale);
            return (!v || v === "") ? "" : moment(v).format(f);
        };

    return {
        ...fmts,
        formatDate: format(fmts.date),
        formatDatetime: format(fmts.datetime),
        formatDatetimeShort: format(fmts.shortDatetime),
    }
};

interface ProviderProps {
    formats?: Partial<Formats>;
    children: ReactNode;
}

export const FormatsProvider = (props: ProviderProps) => {
    return <FormatsContext.Provider value={{ ...DefaultFormats, ...props.formats}}>
        {props.children}
    </FormatsContext.Provider>
}
