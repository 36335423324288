import { useCrudItem } from '../../toolympus/api/useSimpleCrud';
import { Color } from '../ThemeBrowser/Theme';
import { pickRandom } from '../ThemeBrowser/themeGenerator/util';

export interface ColorGroup {
    back: number;
    text: number;
    accent: number;
    accent_contrast: number;
}

export interface Palette {
    colors: ColorGroup;
}

export interface MasterPalette {
    _id?: string;
    colors: Color[];
    options: Palette[];
    is_active: boolean;
}

const DefaultPalette: MasterPalette = {
    colors: [],
    options: [],
    is_active: false,
}

export interface PaletteConfig {
    palette: MasterPalette;

    update: (changes: Partial<MasterPalette>) => void;
    save: () => void;
    hasChanges: boolean;
    isLoading: boolean;
    
    changeColor: (idx: number, color: Color) => void;
    addColor: () => void;
    
    addOption: () => void;
    cloneOption: (palette: Palette) => void;
    removeOption: (option: number) => void;
    cycleColor: (option: number, color: keyof ColorGroup) => void;
    shuffleOption: (option: number) => void;
    isOptionDuplicated: (option: number) => boolean;
    addRandomOptions: () => void;
 
}

export const usePaletteConfig = (id: string): PaletteConfig => {
    const data = useCrudItem<MasterPalette>(`/api/palette/${id}`, {
        defaultValue: DefaultPalette,
        returnPath: '/palette',
    });

    const { data: palette, update, save } = data;

    const addOption = (option?: Palette) => {
        const toAdd: Palette = option ?
            { ...option } :
            palette.options.length > 0 ? { ...palette.options.slice(-1)[0] } : {
                colors: {
                    back: 0,
                    text: 0,
                    accent: 0,
                    accent_contrast: 0,
                }
            };
        update({ options: [...palette.options, toAdd] });
    }

    const cloneOption = (source: Palette) => addOption({ ...source });

    const removeOption = (option: number) => {
        const options = [...palette.options];
        options.splice(option, 1);
        update({ options });
    }

    const nextColor = (color: number) => {
        const nextColorIdx = color + 1;
        return nextColorIdx >= palette.colors.length ? 0 : nextColorIdx;
    }

    const cycleColor = (option: number, color: keyof ColorGroup) => {
        const options = [...palette.options];
        const thatOption = options[option];
        if(thatOption) {
            const newColor = nextColor(thatOption.colors[color]);

            const updated = {
                ...thatOption,
                colors: { ...thatOption.colors, [color]: newColor } ,
            }

            options.splice(option, 1, updated);

            update({ options });
        }
    }

    const takeRandomColor = () => pickRandom(palette.colors.map((_,i) => i));

    const randomOption = () => ({
        colors: {
            back: takeRandomColor(),
            text: takeRandomColor(),
            accent: takeRandomColor(),
            accent_contrast: takeRandomColor(),
        },
    });

    const shuffleOption = (option: number) => {
        const options = [...palette.options];
        const thatOption = options[option];
        if(thatOption) {
            options.splice(option, 1, randomOption());
            update({ options });
        }
    }

    const addRandomOptions = () => {
        const options = [...palette.options, ...Array(5).fill(0).map(_ => randomOption())];
        update({ options });
    }

    const optionSignature = ({ colors }: Palette) => `${colors.back}${colors.text}${colors.accent}${colors.accent_contrast}`;

    const duplicateInfo = palette.options.map(optionSignature)
        .reduce((r,v) => {
            return { ...r, [v]: (r[v]+1 || 0)}
        }, {} as Record<string, number>);
    
    const isOptionDuplicated = (option: number) => palette.options[option] && duplicateInfo[optionSignature(palette.options[option])] > 0;

    const changeColor = (idx: number, color: Color) => {
        const colors = [...palette.colors];
        colors.splice(idx, 1, color);
        update({ colors});
    }

    const addColor = () => {
        update({ colors: [...palette.colors, "#ffffff" ]});
    }

    return {
        palette,
        update,
        save,
        hasChanges: data.hasChanges,
        isLoading: data.isLoading,

        addOption,
        cloneOption,
        removeOption,
        cycleColor,
        shuffleOption,
        isOptionDuplicated,
        addRandomOptions,

        changeColor,
        addColor,
    };
}