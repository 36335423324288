import React from 'react';
import styled from 'styled-components/macro';
import { fontParamsCss, paddingUnits, shadow } from '../Common.css';
import { LogoConfig, PseudoLogo } from '../PseudoLogo';
import { Themed } from './Common';

interface Props extends Themed {
    logo: LogoConfig;
}

const UpperWrapper = styled.div`
`;

const MenuPane = styled.div<Themed>`
    min-height: 100%;
    width: 200px;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
    z-index: +300;

    gap: 30px;

    background: ${props => props.t.colors.block.background};
    color: ${props => props.t.colors.block.text};
    ${props => shadow('strong-block', props.t.elements.menu.separation.shadow)}
    ${props => {
        const borderStrength = props.t.elements.menu.separation.border;
        if(!borderStrength) {
            return '';
        }

        const borderPosition = props.t.elements.menu.side === 'right' ? 'border-left' : 'border-right';
        return `${borderPosition}: ${borderStrength}px solid ${props.t.colors.accent};`;
    }}

    & svg {
        max-width: 150px;
        padding: ${props => paddingUnits(props.t, 4)}px;
    }

    & ul {
        width: 100%;
        list-style: none;
        padding: 0;

        & li {
            ${props => fontParamsCss(props.t.fonts.params.h4)}
            padding: ${props => props.t.geometry.actionPadding[0]}px ${props => paddingUnits(props.t, 6)}px;

            &.active {
                ${props => {
                    const { background, borders, border_strength, border_color } = props.t.elements.menu.active_higlight;
                    const [border_h_s, border_v_s] = border_strength;


                    let back = 'transparent';
                    switch(background) {
                        case 'light':
                            back = '#ffffff20';
                            break;
                        case 'dark':
                            back = '#00000020';
                            break;
                    }

                    const bordersItems = [];

                    const borderColor = border_color === 'accent' ? props.t.colors.accent : props.t.colors.block.text;

                    if(borders.includes('b')) {
                        bordersItems.push(`border-bottom: ${border_h_s}px solid ${borderColor};`);
                    }
                    if(borders.includes('t')) {
                        bordersItems.push(`border-top: ${border_h_s}px solid ${borderColor};`);
                    }
                    if(borders.includes('l')) {
                        bordersItems.push(`border-left: ${border_v_s}px solid ${borderColor};`);
                    }
                    if(borders.includes('r')) {
                        bordersItems.push(`border-right: ${border_v_s}px solid ${borderColor};`);
                    }

                    return `
                        background: ${back};
                        ${bordersItems.join('\n')}
                    `;
                }}
            }
        }
    }

    & > ${UpperWrapper} {
        padding-top: ${props => paddingUnits(props.t, 8)}px;
        width: 100%;
        display: flex;
        flex-flow: column;
        align-items: center;
    }
`;

export const Menu = (props: Props) => {
    const theme = props.t;
    const { is_present, has_logo } = theme.elements.menu;
    return is_present ? (
        <MenuPane {...props}>
            <UpperWrapper>
                {has_logo && <PseudoLogo
                    logo={props.logo}
                    color={theme.logo.color_variant === "text" ? theme.colors.block.text : theme.colors.accent}
                    />}
                <ul>
                    <li>Tellus malesuada</li>
                    <li className="active">Lacus lacus</li>
                    <li>Nunc euismod</li>
                    <li>Nullam</li>
                </ul>
            </UpperWrapper>

            <ul>
                <li>Log out</li>
            </ul>
        </MenuPane>
    ) : null;
}
