import { CrudListData, useCrudUnpagedList } from "../../toolympus/api/useSimpleCrud";
import { Palettes } from "../ThemeBrowser/palettes";
import { MasterPalette } from "./usePaletteConfig";

type PaletteList = CrudListData<MasterPalette>;

export const usePaletteList = (activeOnly?: boolean): PaletteList => {
    return useCrudUnpagedList<MasterPalette>('/api/palette', {
        defaultView: activeOnly ? "active" : "all",
        newItem: { colors: [], options: [], is_active: false },
        newItemPath: i => `/palette/${i._id}`,
    });
}

export const usePaletteListX = (): PaletteList => {
    const palettes = Palettes.map(colors => ({
        colors,
        options: [],
        is_active: true,
    }));

    return {
        data: palettes,
        setData: () => { },
        isLoading: false,
        reload: () => { },

        
        view: "all",
        setView: () => { },
        filter: "",
        setFilter: () => { },
        
        create: () => Promise.resolve({} as MasterPalette),
    }
}
