import React from 'react';
import { TextField, Button } from '@material-ui/core';
import { Login } from '../../hooks/auth';
import { OuterCentralizedForm } from '../primitives/Forms';
import { FormattedMessage } from 'react-intl';

interface Props {
    loginData: Login;
}

export const LoginForm = (props: Props) => {
    const { data: credentials, update: onChangeCredentials, send: submit } = props.loginData;

    return (
        <form onSubmit={e => { e.preventDefault(); submit(); }}>
            <OuterCentralizedForm>
                <TextField
                    label={<FormattedMessage id="login.email"/>}
                    name='email'
                    variant='outlined'
                    value={credentials.login}
                    onChange={e => onChangeCredentials({ ...credentials, login: e.target.value || '' })} />
                <TextField
                    label={<FormattedMessage id="login.password"/>}
                    name='password'
                    type='password'
                    variant='outlined'
                    value={credentials.password}
                    onChange={e => onChangeCredentials({ ...credentials, password: e.target.value || '' })} />
                <Button type='submit' variant='contained' color='primary'><FormattedMessage id="login.submit"/></Button>
            </OuterCentralizedForm>
        </form>
    )
};
