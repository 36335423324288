import React from 'react';
import {
    InputLabel,
    MenuItem,
    Select as SelectMUI,
    FormControl as FormControlMaterial,
    FormHelperText,
    Checkbox,
    ListItemText,
    Chip,
    makeStyles,
    Input,
} from '@material-ui/core';
import { expandProps, FormControlBaseProps, FormControlProps, DisplayProps } from './FormControlProps';
import { Dictionaries, useDictionaries } from '../../hooks/useDictionaries';
import { FieldSchema } from '../../hooks/useSchema';
import { FormattedMessage } from 'react-intl';

export const Select = (props: FormControlProps) => {
    const { field, row, label, value, schema, controlProps, extraProps, onChange, error } = expandProps(props);

    const showValues = extraProps?.showValues;
    const labelIdPrefix = extraProps?.labelIdPrefix;

    return (
        <FormControlMaterial style={{width: '100%'}} margin="dense" error={error}>
            <InputLabel>{label}</InputLabel>
            <SelectMUI
                value={value || ""}
                {...controlProps}
                onChange={e => onChange(row, { [field]: e.target.value })}>
                {(schema.values || []).concat([{ value: null, label: "-"}]).map(({ value, label}) => (
                    <MenuItem key={value || 'undef'} value={value}>
                        {showValues ?
                            value :
                            labelIdPrefix ? (value ? <FormattedMessage id={`${labelIdPrefix}.${value}`} /> : label)
                            : label}
                    </MenuItem>
                ))}
            </SelectMUI>
            {extraProps?.hint && <FormHelperText>{extraProps.hint}</FormHelperText>}
        </FormControlMaterial>);
}

export const adjustDictionarySelectSchema = (schema: FieldSchema, dictionaries: Dictionaries): FieldSchema => {
    if(!schema?.dictionary) {
        return schema;
    } else {
        const thisDict = dictionaries[schema.dictionary];
        return { ...schema, values: thisDict.values, valueDict: thisDict.valueDict };
    }
}

const adjustDictionarySelectProps = <T extends FormControlBaseProps>(props: T, dictionaries: Dictionaries): T => {
    const { schema } = props;
    const newSchema = adjustDictionarySelectSchema(schema, dictionaries);

    return { ...props, schema: newSchema };
}


export const DictionarySelect = (props: FormControlProps) => {
    const dictionaries = useDictionaries();
    return <Select {...adjustDictionarySelectProps(props, dictionaries)} />
}

export const SelectDisplay = ({ schema, row, field }: DisplayProps) => {
    const value = row[field];
    return <>{schema.valueDict[value] || value}</>;
}

export const DictionarySelectDisplay = (props: DisplayProps) => {
    const dictionaries = useDictionaries();
    return <SelectDisplay {...adjustDictionarySelectProps(props, dictionaries)} />    
}

export const SelectMultiDisplay = ({ schema, row, field }: DisplayProps) => {
    const value = row[field] as string[];
    return <>{(value || []).map(v => schema.valueDict[v] || v).join(", ")}</>;
}

export const DictionarySelectMultiDisplay = (props: DisplayProps) => {
    const dictionaries = useDictionaries();
    return <SelectMultiDisplay {...adjustDictionarySelectProps(props, dictionaries)} />    
}


const useMultiStyle = makeStyles({
    chipsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    chip: {
        margin: 2,
    },

    inputId: {},
    labelId: {}
});

type DictionarySelectMultiType = (props: FormControlProps) => JSX.Element

export const DictionarySelectMulti: DictionarySelectMultiType = (props) => {
    const dictionaries = useDictionaries();
    return <MultiSelect {...adjustDictionarySelectProps(props, dictionaries)} />
}


type MultiSelectType = (props: FormControlProps) => JSX.Element

export const MultiSelect: MultiSelectType = (props) => {
    const { chipsContainer, chip, inputId, labelId } = useMultiStyle();
    const { field, row, label, value, schema, controlProps, extraProps, error, onChange } = expandProps(props);

    const selected = (value || []) as any[];

    const options = schema.values || [];
    const labelIdPrefix = extraProps?.labelIdPrefix;

    return <FormControlMaterial fullWidth margin="dense">
        <InputLabel id={labelId}>{label}</InputLabel>
        <SelectMUI
            id={inputId}
            labelId={labelId}
            multiple
            error={error}
            {...controlProps}
            value={selected}
            onChange={e => onChange(row, { [field]: e.target.value})}
            input={<Input />}
            renderValue={_ => (
                <div className={chipsContainer}>
                    {options
                        .filter(({value}) => selected.includes(value))
                        .map(({value, label}) => 
                            <Chip
                                className={chip}
                                key={value || 'undef'}
                                label={labelIdPrefix ? (value ? <FormattedMessage id={`${labelIdPrefix}.${value}`} /> : label) : label} />)
                    }
                </div>
            )}
        >
            {options.map(({ value, label }) => (
                <MenuItem onClick={e => e.preventDefault()} key={value || 'undef'} value={value}>
                    <Checkbox checked={selected.includes(value)} />
                    <ListItemText primary={labelIdPrefix ? (value ? <FormattedMessage id={`${labelIdPrefix}.${value}`} /> : label) : label} />
                </MenuItem>
            ))}
        </SelectMUI>
        {extraProps?.hint && <FormHelperText>{extraProps.hint}</FormHelperText>}
    </FormControlMaterial>
}
