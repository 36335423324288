import { useState } from "react";
import { apiFetch, FetchTypes } from "../../api/core";
import { LoadedData, useLoadedData } from "../../hooks/useLoadedData"
import { useQueryParams } from "../../hooks/useQueryParamsState";
import { Schema, useSingleSchema } from "../../hooks/useSchema";

export interface ContactForm {
    _id: string;
    message: string;
    user_id?: string | null;
    contact?: string | null;

    is_processed: boolean;
    is_discarded: boolean;
    is_important: boolean;
    created_datetime: string;
}

export interface ContactFormListData extends LoadedData<ContactForm[]> {
    schema: Schema;

    view: string;
    setView: (v: string) => void;
    views: [string, string][];
    filter: string;
    setFilter: (v: string) => void;

    editRecord: ContactForm | null;
    cancelEdit: () => void;
    startEdit: (record: ContactForm) => void;
    updateEdit: (changes: Partial<ContactForm>) => void;
    isEditing: boolean;
}

export const useContactFormList = (apiPath: string): ContactFormListData => {
    const { params, update } = useQueryParams({ "view": 'new' });
    const viewParam = `view=${params.view}`;
    
    const [filter, setFilter] = useState<string>("");
    const filterParam = filter.trim().length ? `filter=${filter}` : "";
    
    const data = useLoadedData<ContactForm[]>(`${apiPath}?${viewParam}&${filterParam}`, []);
    const { schema } = useSingleSchema(`${apiPath}/uiconfig`);

    const [editRecord, setEditRecord] = useState<ContactForm | null>(null);

    const startEdit = (record: ContactForm) => setEditRecord(record);
    const cancelEdit = () => setEditRecord(null);

    const updateEdit = (changes: Partial<ContactForm>) => {
        if(editRecord) {
            const original = { ...editRecord };
            setEditRecord({ ...editRecord, ...changes });
            apiFetch<ContactForm>(`${apiPath}/${original._id}`, FetchTypes.PUT, changes)
                .then(result => {
                    setEditRecord(result);
                    data.reload();
                })
        }
    }

    return {
        ...data,
        schema,

        view: params.view,
        setView: (view: string) => update({ view }),
        filter,
        setFilter,
        views: [
            ["New", "new"],
            ["Important", "important"],
            ["Discarded", "discarded"],
            ["Processed", "processed"],
            ["All", "all"],
        ],

        editRecord,
        startEdit,
        cancelEdit,
        updateEdit,
        isEditing: !!editRecord,
    }
}
