import React from 'react';
import styled, { css } from 'styled-components/macro';
import { MarkdownDisplay } from '../../toolympus/components/schemed/Markdown';
import { buttonShadow, fontParamsCss, roundingCss, ShadowOpacities } from './Common.css';
import { LogoConfig, PseudoLogo } from './PseudoLogo';
import { SampleContent } from './SampleContent';
import { AlignOptions, BlockSeparation, Color, HeaderProperties, TextDecoration, Theme } from './Theme';

interface Props {
    theme: Theme;
    logo: LogoConfig;
}

interface Themed {
    t: Theme;
}

export const blockBaseSizing = css`
    width: 100%;
    box-sizing: border-box;
`;


const AButton = styled.a<Themed>`
    background: ${props => props.t.colors.action.background} !important;
    color: ${props => props.t.colors.action.text} !important;
    display: block;
    padding: ${props => props.t.geometry.actionPadding[0]}px ${props => props.t.geometry.actionPadding[1]}px;
    text-decoration: none;
    cursor: pointer;
    
    ${props => roundingCss(props.t.geometry.actionRoundings)}

    ${props => buttonShadow(props.t.action.shadow)}
`;


const MenuItem = styled.a`
    text-decoration: none;
    cursor: pointer;
`;


const blockSeparationCss = ({ type, strength, position }: BlockSeparation, blockColor: Color, contrastColor: Color) => {
    switch(type) {
        case 'line':
            const borderVal = `${strength || 1}px solid ${contrastColor}`;
            return css`
                ${position !== 'bottom' ? `border-top: ${borderVal}` : ''};
                ${position !== 'top' ? `border-bottom: ${borderVal}` : ''};
            `;
        case 'shadow':
            const shadowColor = `#000000${ShadowOpacities[strength || 1]}`
            return css`
                box-shadow: 0 0 7px 0 ${shadowColor};
            `;
        default:
            return '';
    }
}

const Menu = styled.div<Themed>`
    z-index: 500;
    position: relative;
    display: flex;
    flex-flow: row;
    justify-content: ${props => {
        switch(props.t.menu.behavior) {
            case 'center':
                return 'center';
            case 'spread':
                return 'space-between';
            case 'right':
                return 'flex-end';
            default:
                return 'flex-start';
        }}};
    align-items: center;

    gap: ${props => props.t.menu.gap}px;


    & ${MenuItem} {
        ${props => fontParamsCss(props.t.fonts.params.menu)}
    }

    & ${AButton}:last-child {
        ${props => fontParamsCss(props.t.fonts.params.menu)}
        background: ${props => props.t.colors.menuAction.background} !important;
        color: ${props => props.t.colors.menuAction.text} !important;
        ${props => props.t.menu.behavior === 'left-right' ? 'margin-left: auto;' : ''}
    }
`;

const MenuWrapper = styled.div<Themed>`
    z-index: 500;
    position: relative;
    display: flex;
    flex-flow: row;
    align-items: center;
    
    background: ${props => props.t.colors.menu.background};
    color: ${props => props.t.colors.menu.text};

    padding: ${props => props.t.menu.padding[0]}px ${props => props.t.menu.padding[1]}px;
    gap: ${props => props.t.menu.gap}px;

    
    ${props => blockSeparationCss(props.t.menu.separation, props.t.colors.menu.background, props.t.colors.menu.text)}

    & > ${Menu} {
        flex: 1;
        height: 100%;
    }

    & > .logo {
        height: ${props => 2*props.t.fonts.params.menu.size}px;
    }
`;

const Block = styled.div<Themed>`
    ${blockBaseSizing}
    padding: ${props => props.t.geometry.horizontalPadding}px;
    background: ${props => props.t.colors.block.background};
    color: ${props => props.t.colors.block.text};
`;

const Tint = styled.div``;

const TintStrength = {
    'normal': '80',
    'strong': 'cc',
}

const blockPositioning = (h: HeaderProperties) => css`
    ${() => {
        switch(h.caption_behavior.align_horiozontal) {
            case 'start':
                return 'grid-column-start: 1; text-align: left; align-items: start;'
            case 'end':
                return 'grid-column-start: 3; text-align: right; align-items: end;';
            case 'center':
                return 'grid-column-start: 2; text-align: center; align-items: center;';
        }
    }}
`;

const headerOpposeBlockPositioning = (t: Theme) => {
    let horiz = [0, "align"];
    switch(t.header.caption_behavior.align_horiozontal) {
        case 'start':
            horiz =  [3, "end"];
            break;
        case 'end':
            horiz =  [1, "start"];
            break;
        case 'center':
            horiz =  [1, "start"];
            break;
    }
    const [col, align] = horiz;

    let vert = "";
    switch(t.header.caption_behavior.align_vertical) {
        case 'start':
            vert = "start";
            break;
        case 'end':
            vert = "start";
            break;
        case 'center':
            vert = "center";
            break;
    }

    return css`
        grid-column-start: ${col};
        justify-self: ${align};
        align-self: ${vert};
    `;
}

const tintBehavior = (t: Theme) => {
    const tint = t.header.tint_behavior;

    if(!tint) {
        return '';
    }

    const gs = tint.color === 'grayscale' ? 'filter: grayscale();' : '';

    const position = tint.behavior === 'full' ?
        css`
            grid-column-start: span 3;
            grid-row-start: span 3;
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
        `
        : css`
            height: 100%;
            width: 100%;
            ${blockPositioning(t.header)}
        `;

    const mainColor = `${t.colors.block.background}`;
    const colorTransparent = `${mainColor}${TintStrength[tint.strength]}`;
    const gradientOrientation = t.header.caption_behavior.align_horiozontal === 'end' ? '-90deg' : '90deg';

    const getColor = () => {
        switch(tint.gradient) {
            case 'from-opaque':
                return `linear-gradient(${gradientOrientation}, ${mainColor} 0%, ${colorTransparent} 100%)`;
            case 'from-transparent':
                return `linear-gradient(${gradientOrientation}, ${colorTransparent} 0%, transparent 100%)`
            default:
                return colorTransparent;
        }
    }

    return css`
        ${position}
        background: ${getColor()};
        ${gs}
    `
};

const HeaderInner = styled.div`
    ${blockBaseSizing}
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: absolute;
    top: 0;
    left: 0;
`;

const Header = styled.div<Themed>`
    ${blockBaseSizing}
    height: ${props => props.t.header.block_height}px;
    position: relative;
    overflow: hidden;


    & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        object-fit: cover;
        z-index: 0;
        ${props => props.t.header.image_behavior.color === 'grayscale' ? 'filter: grayscale();' : ''}
    }

    & ${HeaderInner} > ${Tint} {
        z-index: +50;
        ${props => tintBehavior(props.t)}
    }

    & ${HeaderInner} > ${Block} {
        z-index: +100;
        ${props => blockPositioning(props.t.header)}
        width: auto;
        height: 100%;
        padding: ${props => props.t.geometry.innerPadding}px ${props => props.t.header.block_fit_padding ? props.t.geometry.horizontalPadding : props.t.geometry.innerPadding}px;

        display: flex;
        flex-flow: column;
        justify-content: ${props => {
            switch(props.t.header.caption_behavior.align_vertical) {
                case 'start':
                    return 'flex-start'
                case 'end':
                    return 'flex-end';
                case 'center':
                    return 'center';
            }
        }};

        background: ${props => {
            switch(props.t.header.caption_behavior.background) {
                case 'opaque':
                    return props.t.colors.block.background;
                case 'transparent':
                    return 'transparent';
            }
            
        }};
        color: ${props => props.t.colors.block.text};

        & > h1, p {
            margin: 0;
        }

        & > .logo {
            height: ${props => props.t.logo.size * 2}em;
            width: ${props => props.t.logo.size * 2}em;
            padding-bottom: 0.25em;
        }
    }

    & ${HeaderInner} > .logo {
        grid-row-start: 1;
        ${props => headerOpposeBlockPositioning(props.t)};
        z-index: +100;
        height: ${props => props.t.logo.size * 4}em;
        width: auto;
        max-height: 90%;
        
        padding: ${props => props.t.geometry.innerPadding}px ${props => props.t.header.block_fit_padding ? props.t.geometry.horizontalPadding : props.t.geometry.innerPadding}px;
    }
    
`;

export const textSizes = ({ params }: Theme["fonts"]) => css`
    & ${Header} h1 {
        ${fontParamsCss(params.caption)}
    }
    & ${Header} p {
        ${fontParamsCss(params.motto)}
    }

    & h1 {
        ${fontParamsCss(params.h1)}
    }

    & h2 {
        ${fontParamsCss(params.h2)}
    }

    & h3 {
        ${fontParamsCss(params.h3)}
    }

    & h4 {
        ${fontParamsCss(params.h4)}
    }

    ${fontParamsCss(params.main)}

    & a {
        ${fontParamsCss(params.accent)}
    }

    & ${AButton} {
        ${fontParamsCss(params.action)}
    }

`;

const textDecorationCss = ({ kind, strength, alteration }: TextDecoration, t: Theme) => {
    const pad = (((alteration || 0) % 3) + 1) * t.geometry.paddingUnit;

    switch(kind) {
        case 'underline':
            return css`
                border-bottom: ${strength}px solid ${t.colors.accent};
                padding-bottom: ${pad}px;
            `;
        case 'twoline':
            return css`
                border-bottom: ${strength}px solid ${t.colors.accent};
                border-top: ${strength}px solid ${t.colors.accent};
                padding-bottom: ${pad}px;
                padding-top: ${pad}px;
            `;
        case 'firstletter':
            const growth = 1 + 0.1*((alteration || 0) % 3);
            return css`
                &::first-letter {
                    color: ${t.colors.accent};
                    font-size: ${growth}em;
                }
            `;
        case 'lead':
            const rotation = (((alteration || 0) % 3) - 1)*30;
            const l = rotation === 0 ? 0 : 0.5;
            const p = rotation === 0 ? 0.6 : 1.2;
            return css`
                position: relative;
                padding-left: ${p}em;
                &:after {
                    position: absolute;
                    content: ' ';
                    top: 0.15em;
                    left: ${l}em;
                    height: 0.85em;
                    width: 1px;
                    border-left: ${strength}px solid ${t.colors.accent};
                    transform: rotate(${rotation}deg);
                }
            `;
        default:
            return '';
    }
}

const Wrapper = styled.div<Themed>`
    position: relative;
    ${blockBaseSizing}
    background: ${props => props.t.colors.main.background};
    color: ${props => props.t.colors.main.text};

    font-family: ${props => props.t.fonts.main};

    & h1, h2, h3, h4 {
        font-family: ${props => props.t.fonts.caption};
        display: inline-block;
        margin-top: 0.75em;
        margin-bottom: 0;
    }

    & a {
        text-decoration: none;
        font-weight: 600;
    }
    
    & p a {
        color: ${props => props.t.colors.accent};
    }

    ${props => textSizes(props.t.fonts)}
`;

const headersDecorationCss = (decoration: TextDecoration, t: Theme) => css`
    & h1, h2, h3, h4 {
        ${textDecorationCss(decoration, t)};
    }
`;

const MainContent = styled.div<Themed>`
    ${blockBaseSizing}
    width: 100%;
    padding: 20px ${props => props.t.geometry.horizontalPadding}px;

    ${props => headersDecorationCss(props.t.decoration.headers || { kind: 'none' }, props.t)}
`;

const alignToJustify = (align: typeof AlignOptions[number]) => {
    switch(align) {
        case 'start':
            return 'flex-start';
        case 'end':
            return 'flex-end';
        default:
            return 'center';
    }
}

const ImageAttribution = ({ author, link }: Theme["header"]["image_params"]) => <>
    The image used in the header was created by <a target="_blank" rel="noopener noreferrer" href={link}>{author} and can be found on Pexels</a>.
</>;

export const ThemePreview = (props: Props) => {
    const { theme, logo } = props;
    return (
        <Wrapper t={theme}>
            <MenuWrapper t={theme}>
            {theme.logo.position === "menu-left" &&
                <PseudoLogo
                    logo={logo}
                    color={theme.logo.color_variant === "text" ? theme.colors.menu.text : theme.colors.menuAction.background}
                    />}
                <Menu t={theme}>
                    {SampleContent.menu.map(({ title, button}) => button ?
                        <AButton key={title} t={theme}>{title}</AButton> :
                        <MenuItem key={title}>{title}</MenuItem>)}
                </Menu>
            </MenuWrapper>
            <Header t={theme}>
                <HeaderInner>
                    <Block t={theme}>
                        {theme.logo.position === "header-block" &&
                            <PseudoLogo logo={logo} color={theme.colors.block.text} />}
                        <h1>{SampleContent.heading1}</h1>
                        <p>{SampleContent.motto}</p>
                    </Block>
                    {theme.logo.position === "header-oppose-block" &&
                        <PseudoLogo
                            logo={logo}
                            color={theme.logo.color_variant === "text" ? theme.colors.block.text : theme.colors.block.background}
                            />}
                </HeaderInner>
                <HeaderInner>
                    <Tint />
                </HeaderInner>
                <img src={theme.header.image_url} alt="" />
            </Header>

            <MainContent t={theme}>
                {SampleContent.blocks.slice(0,1).map((b,i) => <MarkdownDisplay key={i} text={b.content} />)}
                <p><ImageAttribution {...theme.header.image_params} /></p>
                <div style={{ display: 'flex', flexFlow: 'row', justifyContent: alignToJustify(theme.action.align_horizontal) }}>
                    <AButton t={theme}>{SampleContent.action}</AButton>
                </div>

                {SampleContent.blocks.slice(1).map((b,i) => <MarkdownDisplay key={i} text={b.content} />)}
            </MainContent>
            
        </Wrapper>
    );
}
