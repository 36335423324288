import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import {
    Card,
    Container,
    Paper,
    PaperProps,
    Typography,
    withTheme,
} from '@material-ui/core';

export const FormWrapper = styled(Paper)`
    width: 100%;
    min-height: 100%;

    padding: 0.5rem 1rem;
    box-sizing: border-box;
`;

export const FormHeader = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;

    & > button {
        margin-left: 10px;
    }

    margin-bottom: 10px;
`;

export const FormTitle = styled(Typography).attrs(props => ({ variant: 'h4', ...props }))`
`;
export const FormSmallerTitle = styled(Typography).attrs(props => ({ variant: 'h5', ...props }))`
`;

export const SectionTitle = withTheme(styled(Typography).attrs(props => ({ variant: 'h6', ...props }))`
`);

interface FormGridProps {
    noMargin?: boolean;
    columns?: string;
    gap?: 'normal' | 'dense';
    areas?: string[];
    forceEvenColumns?: boolean;
};

export const FormGrid = styled.div<FormGridProps>`
    display: grid;
    height: max-content;
    grid-template-columns: ${props => props.columns || '1fr 1fr 1fr'};
    gap: ${props => props.gap === 'dense' ? '0.25rem 0.75rem' : '0.35rem 36px'};
    ${props => props.areas && props.areas.length ?
        `grid-template-areas: \n${props.areas.map(a => "'" + a + "'").join('\n')};`
        : ''}

    margin-top: ${props => props.noMargin ? 0 : '1rem'};

    ${props => props.forceEvenColumns ? `
        & > * {
            min-width: 0;
        }
    ` : ''}

`;

export const createColumns = (c: string, n: number) => Array(n).fill(c).join(" ");
export const cols = createColumns;

export const FormRow = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;

    &:first-child {
        margin-top: 1rem;
    }

    &:not(:first-child) {
        margin-top: 2rem;
    }
`;

const Column = styled.div`
    display: flex;
    flex-flow: column;

    & > * {
        margin: 0.35rem 0;
    }
`;

export const FormColumn = styled(Column)`
    width: 330px;
    & > * {
        width: 300px;
    }
`;

export const FormWiderColumn = styled(Column)`
    width: 490px;
    & > * {
        width: 460px;
    }
`;

export const FormFullColumn = styled(Column)`
    width: 100%;
`;

export const FormBody = styled.div`
    display: flex;
    flex-flow: column;
`;

interface SimpleFormProps {
    title?: string | ReactNode;
    headerItems?: ReactNode;
    children: ReactNode;
    formPaperProps?: PaperProps;
    formBodyProps?: { style?: React.CSSProperties };
    fitFullHeight?: boolean;
}

export const Form = (props: SimpleFormProps) => {
    const { title, headerItems, children, formPaperProps, formBodyProps } = props;

    let formPaperStyle = formPaperProps?.style || {};
    let formBodyStyle = formBodyProps?.style || {};

    if(props.fitFullHeight) {
        formPaperStyle = {
            display: 'flex',
            flexFlow: 'column',
            height: "100%",
            ...formPaperStyle
        };
        formBodyStyle = {
            maxHeight: "100%",
            overflowY: 'hidden',
            ...formBodyStyle,
        }
    }

    return (
        <FormWrapper {...formPaperProps} style={formPaperStyle} >
            <FormHeader>
                <FormTitle>{title}</FormTitle>

                {headerItems}
            </FormHeader>

            <FormBody {...formBodyProps} style={formBodyStyle} >
                {children}
            </FormBody>
        </FormWrapper>);
}

export const SimpleForm = (props: SimpleFormProps) => {
    const { title, headerItems, children } = props;

    return (
        <Form title={title} headerItems={headerItems}>
            <FormColumn>
                {children}
            </FormColumn>
        </Form>);
}


const OuterCentralizedFormWrapper = styled(Card)`
    display: flex;
    flex-flow: column;
    align-items: center;

    & > * {
        width: 300px;
        margin: 6px;
    }
`;

export const OuterCentralizedForm = (props: SimpleFormProps) => {
    const { children } = props;

    return (
        <Container>
            <OuterCentralizedFormWrapper elevation={0}>
                {children}
            </OuterCentralizedFormWrapper>
        </Container>
    )
}