import { useState } from "react"
import { pickRandom } from "../themeGenerator/util";

interface LogoLine {
    offset_x: number;
    offset_y: number;
    angle: 0 | 45 | 90;
    size: number;
}

export interface LogoConfig {
    border: 'circle' | 'square';
    border_radius: number;
    stroke_width: number;
    lines: LogoLine[];
}

export interface LogoGenerator {
    logo: LogoConfig;
    next: () => void;
}

const generateLine = (): LogoLine => ({
    offset_x: pickRandom([-3, -2, -1, 0, 1, 2, 3]),
    offset_y: pickRandom([-3, -2, -1, 0, 1, 2, 3]),
    angle: pickRandom([0, 45, 90]),
    size: pickRandom([1, 2, 3]),
})

const generateRandomLogo = (): LogoConfig => ({
    border: pickRandom(['circle', 'square']),
    border_radius: pickRandom([0, 1, 2, 4]),
    lines: Array(pickRandom([2,3,4,5,6])).fill(0).map(() => generateLine()),
    stroke_width: pickRandom([1, 1.5, 2, 2.5]),
});

export const useLogoGenerator = (): LogoGenerator => {
    const [logo, setLogo] = useState<LogoConfig>(generateRandomLogo());

    return {
        logo,
        next: () => { setLogo(generateRandomLogo()) },
    }
}