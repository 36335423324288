import React from 'react';
import { LogoConfig } from '.';
import { Color } from '../Theme';

interface Props {
    logo: LogoConfig;
    color: Color;
}

export const PseudoLogo = (props: Props) => {
    const { border, border_radius, lines, stroke_width } = props.logo;

    const radiusUnit = 15;

    return (
        <svg viewBox="0 0 300 300" fill="none" strokeWidth={5*stroke_width} stroke={props.color} className="logo">
            <style type="text/css">
            </style>
            {border === 'circle' &&
                <circle className="border" cx="150" cy="150" r="145" />}
            {border === 'square' &&
                <rect x="5" y="5" width="290" height="290" rx={border_radius*radiusUnit} />}
            {lines.map(({ offset_x, offset_y, angle, size }, idx) => {
                const c = 150;
                const step = 30;
                const cx = c + offset_x * step;
                const cy = c + offset_y * step;
                const hl = 0.35*size*150;
                const ar = angle*(Math.PI/180);
                const sx = cx - hl*Math.cos(ar);
                const sy = cy - hl*Math.sin(ar);
                const ex = cx + hl*Math.cos(ar);
                const ey = cy + hl*Math.sin(ar);
                return <path className="line" key={idx} d={`M ${sx} ${sy} L ${ex} ${ey}`} />
            })}
        </svg>
    );
}
