import React from 'react';
import { Button, IconButton, Typography } from '@material-ui/core';
import { Add, ArrowBack, BlurOn, Delete, FileCopyOutlined, NavigateBefore, NavigateNext } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components/macro';
import { Color } from '../ThemeBrowser/Theme';
import { ColorGroup, PaletteConfig, usePaletteConfig } from './usePaletteConfig';
import { calcContrast, toColorExt } from '../ThemeBrowser/themeGenerator/colorUtil';
import { ColorSqareS, ColorView } from './ColorViewers';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { SaveButton } from '../../toolympus/components/primitives/Buttons';
import { FormControl } from '../../toolympus/components/schemed';
import { FieldType } from '../../toolympus/hooks/useSchema';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { usePaletteList } from './usePaletteList';
import { Link } from 'react-router-dom';



const MainWrapper = styled.div`
    padding: 0.5rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 40px;
`;

const ColorSetWrapper = styled.div`
    display: flex;
    flex-flow: row;
    gap: 24px;
`;

const PaletteWrapper = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 12px;

    & > :nth-child(1) {
    }

    & > :nth-child(3) {
        width: 300px;
    }

    & > :nth-child(4) {
        width: 150px;
    }

    & > :nth-child(5) {
    }
`;

const ColorSeq = styled.div`
    display: flex;
    flex-flow: row;
    padding: 0.5rem 0;
`;

const PreviewContainer = styled.div`
    font-size: 20px;
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.6);

    & > div {
        width: 100%;
        height: 50px;
        padding: 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;

        & > a {
            display: inline-block;
            margin-left: 10px;
            border-radius: 4px;
            text-decoration: none;
            padding: 0.25rem 0.5rem;
        }
    }
`;

interface PaletteOptionProps extends PaletteConfig {
    idx: number;
}

const PaletteOptionView = ({ idx, palette, cycleColor, shuffleOption, cloneOption, removeOption, isOptionDuplicated }: PaletteOptionProps ) => {
    const colorKeys: (keyof ColorGroup)[] = ["back", "text", "accent", "accent_contrast"];

    const paletteOption = palette.options[idx];

    const getColor = (n: number) => palette.colors[n];

    const renderPreviewBlock = (pg: ColorGroup) => (
        <div style={{ background: getColor(pg.back), color: getColor(pg.text) }}>
            Lorem ipsum
            <a href="/#" style={{ background: getColor(pg.accent), color: getColor(pg.accent_contrast) }}>
                do it
            </a>
        </div>
    )

    const calcContrastX = (c1: Color, c2: Color) => {
        return calcContrast(toColorExt(c1), toColorExt(c2));
    }

    const ContrastInfo = (props: { label: string, color1: Color, color2: Color }) => {
        const contrast = calcContrastX(props.color1, props.color2);
        const color = contrast < 1.3 ? "#aa1111" : contrast < 2 ? "#f1cf0e" : undefined;
        return <>
            <Typography component="span">{props.label}&nbsp;</Typography>
            <Typography component="span" style={{ color }}>{contrast.toFixed(1)}</Typography>
        </>
    }

    const c = (n: number) => palette.colors[n];

    return <PaletteWrapper>
        <div>
            <Typography style={isOptionDuplicated(idx) ? { borderBottom: "3px solid #aa1111"} : {}}>{idx+1}.</Typography>
        </div>
        <div>
            <ColorSeq>
                {colorKeys.map(k => <ColorSqareS key={k} color={c(paletteOption.colors[k])} onClick={() => cycleColor(idx, k)} />)}
            </ColorSeq>
        </div>
        <div>
            <PreviewContainer>
                {renderPreviewBlock(paletteOption.colors)}
            </PreviewContainer>
        </div>

        <div>
            <div>
                <ContrastInfo label="text / back:" color1={c(paletteOption.colors.back)} color2={c(paletteOption.colors.text)} />
            </div>
            <div>
                <ContrastInfo label="accent / back:" color1={c(paletteOption.colors.back)} color2={c(paletteOption.colors.accent)} />
            </div>
            <div>
                <ContrastInfo label="accent / accent:" color1={c(paletteOption.colors.accent)} color2={c(paletteOption.colors.accent_contrast)} />
            </div>
        </div>

        <div>
            <IconButton size="small" onClick={() => shuffleOption(idx)}>
                <BlurOn />
            </IconButton>
            <IconButton size="small" onClick={() => cloneOption(paletteOption)}>
                <FileCopyOutlined />
            </IconButton>
            <IconButton size="small" onClick={() => removeOption(idx)}>
                <Delete />
            </IconButton>
        </div>
    </PaletteWrapper>;
}

const PaletteOptions = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;
    gap: 30px;
`;


interface Props extends PaletteConfig {
    goNext: () => void;
    goPrev: () => void;
}

export const PaletteConfigurator = (props: Props) => {
    const {
        palette,
        addOption,
        addRandomOptions,
        addColor,
        save,
        update,
        isLoading,
        hasChanges,
        goNext,
        goPrev,
    } = props;

    return (
        <MainWrapper>
            <ActionRow>
                <Link to="/palette">
                    <IconButton size="small">
                        <ArrowBack />
                    </IconButton>
                </Link>
                <Typography variant="h6">Palette</Typography>
                {isLoading && <LoadingIndicator sizeVariant="m" />}
                <OccupyFreeSpace />
                <FormControl
                    row={{ is_active: palette.is_active }}
                    field="is_active"
                    onChange={(o,c) => update(c)}
                    schema={{ type: FieldType.bool, label: "Active" }}
                    />
                <IconButton size="small" onClick={goPrev}>
                    <NavigateBefore />
                </IconButton>
                <IconButton size="small" onClick={goNext}>
                    <NavigateNext />
                </IconButton>
                {hasChanges && <SaveButton action={save}></SaveButton>}
            </ActionRow>
            <ColorSetWrapper>
                {palette.colors.map((c,i) => <ColorView key={c+i} canEdit color={c} onChange={x => props.changeColor(i, x)} />)}
                <IconButton size="small" onClick={addColor}>
                    <Add />
                </IconButton>
            </ColorSetWrapper>

            <PaletteOptions>
                {palette.options.map((po, i) =>
                    <PaletteOptionView
                        idx={i}
                        {...props}
                        />)}
            </PaletteOptions>

            <Button onClick={() => addOption()}>add variant</Button>
            <Button onClick={() => addRandomOptions()}>add 5 random variants</Button>
        </MainWrapper>
    );
}

export const PaletteConfiguratorPage = () => {
    const { id } = useParams<{ id: string }>();
    const history = useHistory();
    const palettes = usePaletteList();
    const data = usePaletteConfig(id);

    const go = (direction: number) => {
        const ps = palettes.data;
        const currentIdx = ps.findIndex(x => x._id === id);
        const nextIdxPropos = currentIdx + direction;
        const nextIdx = nextIdxPropos < 0 ? ps.length -1 : nextIdxPropos >= ps.length ? 0 : nextIdxPropos;

        history.push(`/palette/${ps[nextIdx]._id}`);
    }

    return <PaletteConfigurator {...data} goNext={() => go(1)} goPrev={() => go(-1)} />
}

