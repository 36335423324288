import { css } from "styled-components";
import { BlockRoundings, FontAlterations, FontParams, Theme } from "./Theme";

export const fontAlterationsCss = (alts: FontAlterations) => css`
    font-style: ${alts.italic ? "italic" : "normal"};

    ${alts.transforms === "smallcaps" ? "font-variant: small-caps;" : ""}
    text-transform: ${alts.transforms === "smallcaps" ? "none" : alts.transforms || "none"};

`;

export const fontParamsCss = (params: FontParams) => css`
    font-size: ${params.size}px;
    font-weight: ${params.weight};
    line-height: ${params.lineHeight};
    ${fontAlterationsCss(params)};
`;


export const roundingCss = (r: BlockRoundings) => css`
    border-radius: ${Array.isArray(r) ? r.join(" ") : r};
`;


export const ShadowOpacities = {
    1: '40',
    2: '70',
    3: 'aa',
}

const ShadowParams = {
    none: '',
    button: '4px 4px 8px -2px',
    'light-button': '2px 2px 6px -2px',
    block: '0 0 10px -5px',
    'strong-block': '0 0 7px 0',
}

export const shadow = (kind: keyof typeof ShadowParams, strength?: 0 | 1 | 2 | 3) => {
    if(!strength || kind === 'none') {
        return '';
    } else {
        const shadowColor = `#333333${ShadowOpacities[strength]}`
        return `box-shadow: ${ShadowParams[kind]} ${shadowColor};`;
    }
}

export const buttonShadow = (strength?: 0 | 1 | 2 | 3) => shadow('button', strength);

export const lightButtonShadow = (strength?: 0 | 1 | 2 | 3) => shadow('light-button', strength);

export const blockShadow = (strength?: 0 | 1 | 2 | 3) => shadow('block', strength);

export const paddingUnits = (t: Theme, units: number) => t.geometry.paddingUnit*units;