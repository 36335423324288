import React from "react";
import { FormControlLabel, Checkbox as CheckboxMUI, useTheme } from "@material-ui/core";
import { expandProps, FormControlProps } from "./FormControlProps";

export const Checkbox = (props: FormControlProps) => {
    const { field, row, label, value, controlProps, onChange, error } = expandProps(props);

    const theme = useTheme();

    return <FormControlLabel
                label={label}
                style={error ? { color: theme.palette.error.main } : undefined}
                control={<CheckboxMUI
                            checked={value || false}
                            {...controlProps}
                            onChange={e => onChange(row, {[field]: e.target.checked})} />} />;
}