import { FeatureImages } from "../FeatureImages";
import { Palettes } from "../palettes";
import { Theme, BlockRoundings, MenuProperties, MenuBehaviorOptions, CommonGeometry, HeaderProperties, AlignOptions, BlockSeparation, ColorTheme, TextDecoration, LogoProperties, ElementsProperties, Rounding } from "../Theme";
import { createColorTheme } from "./colorTheme";
import { createRandomFontTheme } from "./fontTheme";
import { pickRandom } from "./util";

const nonUniformRoundingPatterns = [
    [1, 0, 1, 0],
    [0, 1, 0, 1],
]

const smallRoundingOptions = [2, 4, 8];
const mediumRoundingOptions = [12, 16];
const largeRoundingOptions = [24, 36];

export const getRoundingOptions = (allowed: string, noZero?: boolean): Rounding[] => {
    const toPx = (x: number) => `${x}px`;
    return [
        ...(allowed.includes('s') ? smallRoundingOptions : []).map(toPx),
        ...(allowed.includes('m') ? mediumRoundingOptions : []).map(toPx),
        ...(allowed.includes('l') ? largeRoundingOptions : []).map(toPx),
        ...(noZero ? [] : [0] as 0[]),
    ]
}

const roundingOptions = getRoundingOptions('sml');

const generateBlockRoundings = (): BlockRoundings => {
    const uniformRounding = Math.random() > 0.2;
    if(uniformRounding) {
        return pickRandom(roundingOptions) as string | 0;
    } else {
        const noRound = pickRandom([0, "2px"]);
        const round = pickRandom(roundingOptions.filter(x => x !== 0 && x !== "2px"));

        const pattern = pickRandom(nonUniformRoundingPatterns);

        return pattern.map(x => x ? round : noRound) as BlockRoundings;
    }
}

const generateBlockSeparation = (): BlockSeparation => {
    return {
        type: pickRandom(['none', 'line', 'shadow']),
        strength: pickRandom([1,2,3]),
        position: pickRandom(['bottom', 'both']),
    };
}

const generateMenuProperties = (commonGeometry: CommonGeometry): MenuProperties => {
    const verticalPaddingFactors = [1, 2, 5];
    const gapFactors = [2, 4, 6, 8];

    return {
        behavior: pickRandom(MenuBehaviorOptions),
        padding: [pickRandom(verticalPaddingFactors) * commonGeometry.paddingUnit, commonGeometry.horizontalPadding],
        gap: pickRandom(gapFactors) * commonGeometry.paddingUnit,
        separation: generateBlockSeparation(),
    }
}

const generateLogoProperties = (): LogoProperties => {
    const position = pickRandom(['menu-left', 'header-block', 'header-oppose-block']) as LogoProperties["position"];
    return {
        position,
        color_variant: pickRandom(['text', 'accent']),
        size: pickRandom([1,2,3]),
    }
}

const generateHeaderProperties = (): HeaderProperties => {
    const [img_name, img_author, img_link] = pickRandom(FeatureImages);

    const tint_behavior: HeaderProperties["tint_behavior"] = {
        strength: pickRandom(['normal', 'strong']),
        color: pickRandom(['normal', 'grayscale']),
        gradient: pickRandom(['none', 'from-transparent', 'from-opaque']),
        behavior: pickRandom(['full', 'block']),
    };

    return {
        includeImage: true,
        image_url: `/img/feature/${img_name}`,
        image_params: {
            author: img_author,
            link: img_link,
        },
        block_height: pickRandom([200, 250, 300, 400, 500]),
        block_fit_padding: pickRandom([true, false]),
        caption_behavior: {
            align_horiozontal: pickRandom(AlignOptions),
            align_vertical: pickRandom(AlignOptions),
            background: tint_behavior ? pickRandom(['opaque', 'transparent']) : 'opaque',
        },
        tint_behavior,
        image_behavior: {
            color: pickRandom(['asis', 'grayscale']),
        }
    }
}

const generateTextDecoration = (): TextDecoration => {
    const kind = Math.random() > 0.5 ? 'none' : pickRandom(['underline', 'twoline', 'lead', 'firstletter'] as TextDecoration["kind"][]);

    if(kind !== 'none') {
        return {
            kind,
            strength: pickRandom([1,2,3, 4]),
            alteration: pickRandom([0,1,2,3,4]),
        }
    }

    return {
        kind,
    }
}

const generateElementsProperties = (): ElementsProperties => {
    return {
        card: {
            shadow: pickRandom([1, 2, 3]),
            rounding: pickRandom(roundingOptions) as string,
            even: pickRandom([true, false]),
        },

        tag: {
            variant: pickRandom([ 'outlined', 'contained' ]),
            shadow: pickRandom([0, 1, 2]),
            rounding: pickRandom(getRoundingOptions('s')),
        },

        list: {
            separator: pickRandom([1, 2]),
        },

        input: {
            rounding: pickRandom(getRoundingOptions('s')),
            variant: pickRandom(['outlined', 'simple']),
            highlight: pickRandom(['lighten', 'darken', 'none']),
        },

        ribbon: {
            is_present: pickRandom([true, false]),
            has_logo: pickRandom([true, false]),
            has_app_title: pickRandom([true, false]),
            separation: {
                strength: pickRandom([1, 2, 3]),
            },
            rounding: pickRandom(getRoundingOptions('sm')),
        },

        menu: {
            is_present: pickRandom([true, false]),
            has_logo: pickRandom([true, false]),
            side: pickRandom(['left', 'right']),
            position: pickRandom(['static', 'on-top']),
            
            separation: {
                shadow: pickRandom([0, 1, 2, 3]),
                border: pickRandom([0, 0, 0, 1, 2]),
            },
            
            active_higlight: {
                borders: pickRandom(['b', 'tb', 'l', 'r', 'lr', 'tbl', 'tbr', '']),
                border_strength: [pickRandom([1, 2]), pickRandom([2, 4, 6])],
                border_color: pickRandom(['accent', 'text']),
                background: pickRandom(['none', 'light', 'dark']),
            },
        },
    }
}

const getSomeColorTheme = (): ColorTheme => {
    const palette = Palettes[Math.floor(Palettes.length * Math.random())];

    const injectOffBackground = Math.random() > 0.5;

        let result = null;
        const levels = [3.0, 2.0, 1.05,];

        for (const level of levels) {
            try {
                result = createColorTheme(palette, {
                    injectOffBackground,
                    minContrast: level,
                })
            } catch (_) {
            }
            if(result) {
                break;
            }
        }
        
        return result || createColorTheme(palette, {
            injectOffBackground,
            minContrast: 1,
        });
}

interface Config {
    createColorTheme?: () => ColorTheme;
    colorTheme?: ColorTheme;
}

export const createRandomTheme = (cfg?: Config): Theme => {
    const colors = cfg?.colorTheme || (cfg?.createColorTheme || getSomeColorTheme)();

    const paddingUnit = 5;

    const geometry = {
        paddingUnit,
        horizontalPadding: pickRandom([20, 60, 120]),
        innerPadding: paddingUnit * 4,
        actionRoundings: generateBlockRoundings(),
        actionPadding: [pickRandom([1, 1.5, 2, 2.5])*paddingUnit, pickRandom([2, 3, 4, 5, 6])*paddingUnit] as [number,number],
    };
    
    return {
        colors,
    
        fonts: createRandomFontTheme(),

        geometry,

        decoration: {
            headers: generateTextDecoration(), 
        },

        menu: generateMenuProperties(geometry),
        header: generateHeaderProperties(),
        logo: generateLogoProperties(),

        action: {
            align_horizontal: pickRandom(AlignOptions),
            shadow: pickRandom([0,1,2]),
        },

        elements: generateElementsProperties(),
    };
}

export const replaceThemeColors = (t: Theme, colors: ColorTheme): Theme => {
    return {
        ...t,
        colors,
    }
}

export const remixThemeColors = (t: Theme): Theme => {
    return replaceThemeColors(t, createColorTheme(t.colors.palette, { injectOffBackground: false, minContrast: 0 }));
}
