export interface MockItem {
    title: string;
    description: string;
    tags: string[];
}

export const MockListData: MockItem[] = [
    {
        title: "Non ultricies",
        description: "Purus vitae risus placerat",
        tags: ["praesent", "libero", "morbi",],
    },
    {
        title: "Ut posuere",
        description: "Nulla tempus pretium erat, ut pulvinar nulla",
        tags: ["vel", "pellentesque"],
    },
    {
        title: "Curabitur pharetra",
        description: "Ut suscipit massa",
        tags: ["praesent", "pellentesque", "malesuada", "fames"],
    },
    {
        title: "Aenean est tortor",
        description: "Scelerisque suscipit lacinia vel, accumsan sed orci",
        tags: ["praesent", "vel", "semper", "purus", "fames"],
    },
]