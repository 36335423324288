import { IconButton, withTheme } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { ClipboardButton } from '../../toolympus/components/primitives/Buttons';
import { Form, FormGrid } from '../../toolympus/components/primitives/Forms';
import { FieldDefinition, FormControlsForFields } from '../../toolympus/components/schemed';
import { FieldType } from '../../toolympus/hooks/useSchema';

interface Props {
    
}

interface SvgConfig {
    width: number;
    height: number;
    content: string;
    styles: string;
    darkbg: boolean;
}

const genSvg = ({ content, styles, width: w, height: h }: SvgConfig) => `<?xml version="1.0" encoding="utf-8"?>
<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 ${w} ${h}" style="enable-background:new 0 0 ${w} ${h};" xml:space="preserve">
<style type="text/css">
	${styles}
</style>
${content}
</svg>
`

const useSimpleSvgEditor = () => {
    const [config, setConfig] = useState<SvgConfig>({
        width: 300,
        height: 300,
        content: '<circle class="st1" cx="150" cy="150" r="50"/>',
        styles: '.st1{fill:#0000b1;stroke:#cc00b1; stroke-width: 7;}',
        darkbg: false,
    })

    const svg = genSvg(config);

    return {
        config,
        svg,
        update: (changes: Partial<SvgConfig>) => setConfig(c => ({ ...c, ...changes })),

        download: () => {
            const anchor = document.createElement("a");
            document.body.appendChild(anchor);
            const blob = new Blob([svg], { type: "text/plain;charset=utf-8" });
            const objectUrl = window.URL.createObjectURL(blob);
            
            anchor.href = objectUrl;
            anchor.download = "image.svg";
            anchor.click();

            window.URL.revokeObjectURL(objectUrl);
        }
    }
}

const WrapperOut = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
`;

const WrapperIn = withTheme(styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    ${props => props.theme.breakpoints.down('sm')} {
        display: flex;
        flex-flow: column-reverse;
    }
`);

const Block = styled.div`
    width: 100%;
`;

const SvgWrapper = styled.div<{ w: number, h: number, darkbg?: boolean }>`
    min-width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 2rem 0;

    & > svg {
        width: ${props => props.w}px;
        height: ${props => props.h}px;
        box-shadow: 0 0 10px -5px #000051cc;
        background: ${props => props.darkbg ? '#333333' : 'transparent'};
    }
`;

const Buttons = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 0.5rem;
`;

export const SimpleSvgEditor = (props: Props) => {
    const { config, update, svg, download } = useSimpleSvgEditor();

    const controls = (fields: FieldDefinition[]) => (
        <FormControlsForFields
            fields={fields}
            schema={{
                width: { type: FieldType.number, label: "Width" },
                height: { type: FieldType.number, label: "Height" },
                styles: { type: FieldType.textlong, label: "Styles" },
                content: { type: FieldType.textlong, label: "SVG" },
                darkbg: { type: FieldType.bool, label: "Dark background (preview)"}
            }}
            data={config}
            onChange={(o,c) => update(c)}
            />
    )

    const w = Math.min(500, config.width);
    const h = config.height * w / config.width;

    return (
        <Form
            title="SVG Editor"
            formPaperProps={{ elevation: 0 }}
        >
            <WrapperOut>
                <WrapperIn>
                    <Block>
                        <SvgWrapper w={w} h={h} darkbg={config.darkbg} dangerouslySetInnerHTML={{ __html: svg}} />
                        <Buttons>
                            <ClipboardButton getValue={() => svg} />
                            <IconButton size="small" onClick={download}><GetApp /></IconButton>
                        </Buttons>
                    </Block>

                    <Block>
                        <FormGrid columns="100px 100px 1fr">
                            {controls([
                                ["width"],
                                ["height"],
                                ["darkbg"],
                            ])}
                        </FormGrid>
                        <FormGrid columns="1fr" noMargin>
                            {controls([
                                ["styles", { autoRows: true }],
                                ["content", { autoRows: true }],
                            ])}
                        </FormGrid>
                    </Block>

                    
                </WrapperIn>
            </WrapperOut>
        </Form>
    );
}
