export const Palettes = [
    [
        "#F98866",
        "#FF420E",
        "#80BD9E",
        "#89DA59",
    ],
    [
        "#90AFC5",
        "#336B87",
        "#2A3132",
        "#763626",
    ],
    [
        "#46211A",
        "#693D3D",
        "#BA5536",
        "#A43820",
    ],
    // 04. Crisp & Dramatic
    [
        "#505160",
        "#68829E",
        "#AEBD38",
        "#598234",
    ],
    // 05. Cool blues
    [
        "#003B46",
        "#07575B",
        "#66A5AD",
        "#C4DFE6",
    ],
    // 06. Outdoorsy & Natural
    [
        "#2E4600",
        "#486B00",
        "#A2C523",
        "#7D4427",
    ],
    // 07. Watery Blue-Greens
    [
        "#021C1E",
        "#004445",
        "#2C7b73",
        "#6FB98F",
    ],
    // 08. Vibrant
    [
        "#375E97",
        "#FB6542",
        "#FFBB00",
        "#3F681C",
    ],
    // 09. Refreshing & Pretty
    [
        "#98DBC6",
        "#5BC8AC",
        "#E6D72A",
        "#F18D9E",
    ],
    // 10. Playful Greens & Blues
    [
        "#324851",
        "#86AC41",
        "#34675C",
        "#7DA3A1",
    ],
    // 11. Fresh & energetci
    [
        "#4CB5F5",
        "#B7B8B6",
        "#34675C",
        "#B3C100",
    ],
    // 12.
    [
        "#F4CC70",
        "#DE7A22",
        "#20948B",
        "#6AB187",
    ],
    // 13.
    [
        "#8D230F",
        "#1E434C",
        "#9B4F0F",
        "#C99E10",
    ],
    // 14.
    [
        "#F1F1F2",
        "#BCBABE",
        "#A1D6E2",
        "#1995AD",
    ],
    // 15
    [
        "#9A9EAB",
        "#5D535E",
        "#EC96A4",
        "#DFE166",
    ],
    // 16
    [
        "#011A27",
        "#063852",
        "#F0810F",
        "#E6DF44",
    ],
    // 17
    [
        "#75B1A9",
        "#D9B44A",
        "#4F6457",
        "#ACD0C0",
    ],
    // 18
    [
        "#EB8A44",
        "#F9DC24",
        "#4B7447",
        "#8EBA43",
    ],
    // 19
    [
        "#363237",
        "#2D4262",
        "#73605B",
        "#D09683",
    ],
    // 20
    [
        "#F52549",
        "#FA6775",
        "#FFD64D",
        "#9BC01C",
    ],
    // 21
    [
        "#2E2300",
        "#6E6702",
        "#C05805",
        "#DB9501",
    ],
    // 22
    [
        "#50312F",
        "#CB0000",
        "#E4EA8C",
        "#3F6C45",
    ],
    // 23
    [
        "#4D85BD",
        "#7CAA2D",
        "#F5E356",
        "#CB6318",
    ],
    // 24
    [
        "#0F1B07",
        "#FFFFFF",
        "#5C821A",
        "#C6D166",
    ],
    // 25
    [
        "#00293C",
        "#1E656D",
        "#F1F3CE",
        "#F62A00",
    ],
    // 26
    [
        "#626D71",
        "#CDCDC0",
        "#DDBC95",
        "#B38867",
    ],
    // 27
    [
        "#258039",
        "#F5BE41",
        "#31A9B8",
        "#CF3721",
    ],
    // 28
    [
        "#EE693F",
        "#F69454",
        "#FCFDFE",
        "#739F3D",
    ],
    // 29
    [
        "#5C8F22",
        "#752A07",
        "#FBCB7B",
        "#EB5E30",
    ],
    // 30
    [
        "#1E1F26",
        "#283655",
        "#4D648D",
        "#D0E1F9",
    ],
    // 31
    [
        "#F70025",
        "#F7EFE2",
        "#F25C00",
        "#F9A603",
    ],
    // 32
    [
        "#A1BE95",
        "#E2DFA2",
        "#92AAC7",
        "#ED5752",
    ],
    // 33
    [
        "#4897D8",
        "#FFDB5C",
        "#FA6E59",
        "#F8A055",
    ],
    // 34
    [
        "#AF4425",
        "#662E1C",
        "#EBDCB2",
        "#C9A66B",
    ],
    // 35
    [
        "#C1E1DC",
        "#FFCCAC",
        "#EFEB94",
        "#FDD475",
    ],
    // 36
    [
        "#4C3F54",
        "#D13525",
        "#F2C057",
        "#486824",
    ],
    // 37
    [
        "#FAAF08",
        "#FA812F",
        "#FA4032",
        "#FEF3E2",
    ],
    // 38
    [
        "#F4EC6A",
        "#BBCF4A",
        "#E73F0B",
        "#A11F0C",
    ],
    // 39
    [
        "#FEF2E4",
        "#FD974F",
        "#C60000",
        "#805A3B",
    ],
    // 40
    [
        "#F77604",
        "#B8D20B",
        "#F56C57",
        "#231B12",
    ],
    // 41
    [
        "#7F152E",
        "#D61800",
        "#EDAE01",
        "#E94F08",
    ],
    // 42
    [
        "#EAE2D6",
        "#D5C3AA",
        "#867666",
        "#E1B80D",
    ],
    // 43
    [
        "#265C00",
        "#68A225",
        "#B3DE81",
        "#FDFFFF",
    ],
    // 44
    [
        "#A10115",
        "#D72C16",
        "#F0EFEA",
        "#C0B2B5",
    ],
    // 45
    [
        "#C7DB00",
        "#7AA802",
        "#F78B2D",
        "#E4B600",
    ],
    // 46
    [
        "#301B28",
        "#523634",
        "#B6452C",
        "#DDC5A2",
    ],
    // 47
    [
        "#EBDF00",
        "#7E7B15",
        "#563E20",
        "#B38540",
    ],
    // 48
    [
        "#662225",
        "#B51D0A",
        "#EAD39C",
        "#7D5E3C",
    ],
    // 49
    [
        "#4B4345",
        "#102A49",
        "#F79B77",
        "#755248",
    ],
    // 50
    [
        "#D8412F",
        "#FE7A47",
        "#FCFDFE",
        "#F5CA99",
    ],
    // 51
    [
        "#2988BC",
        "#2F496E",
        "#F4EADE",
        "#ED8C72",
    ],
    // 52
    [
        "#000B29",
        "#D70026",
        "#F8F5F2",
        "#EDB83D",
    ],
    // 53
    [
        "#1E0000",
        "#500805",
        "#9D331F",
        "#BC6D4F",
    ],
    // 54
    [
        "#F9BA32",
        "#426E86",
        "#F8F1E5",
        "#2F3131",
    ],
    // 55
    [
        "#04202C",
        "#304040",
        "#5B7065",
        "#C9D1C8",
    ],
    // 56
    [
        "#D24136",
        "#EB8A3E",
        "#EBB582",
        "#785A46",
    ],
    // 57
    [
        "#217CA3",
        "#E29930",
        "#32384D",
        "#211F30",
    ],
    // 
    [
        "#004D47",
        "#128277",
        "#52958B",
        "#B9C4C9",
    ],
    // 
    [
        "#506D2F",
        "#2A2922",
        "#F3EBDD",
        "#7D5642",
    ],
    // 
    [
        "#F47D4A",
        "#E1315B",
        "#FFEC5C",
        "#008DCB",
    ],
    // 
    [
        "#A4CABC",
        "#EAB364",
        "#B2473E",
        "#ACBD78",
    ],
    // 
    [
        "#16253D",
        "#002C54",
        "#EFB509",
        "#CD7213",
    ],
    // 
    [
        "#8593AE",
        "#5A4E4D",
        "#7E675E",
        "#DDA288",
    ],
    // 
    [
        "#2B616D",
        "#B2DBD5",
        "#FFFFFF",
        "#FA8D62",
    ],
    // 
    [
        "#00CFFA",
        "#FF0038",
        "#FFCE38",
        "#020509",
    ],
    // 
    [
        "#A5C3CF",
        "#F3D3B8",
        "#E59D5C",
        "#A99F3C",
    ],
    // 
    [
        "#257985",
        "#5EA8A7",
        "#FFFFFF",
        "#FF4447",
    ],
    // 
    [
        "#FCC875",
        "#BAA896",
        "#E6CCB5",
        "#E38B75",
    ],
    // 
    [
        "#335252",
        "#D4DDE1",
        "#AA4B41",
        "#2D3033",
    ],
    // 
    [
        "#FFCCBB",
        "#6EB5C0",
        "#006C84",
        "#E2E8E4",
    ],
    // 
    [
        "#8C0004",
        "#C8000A",
        "#E8A735",
        "#E2C499",
    ],
    // 
    [
        "#2C4A52",
        "#537072",
        "#8E9B97",
        "#F4EBDB",
    ],
    // 
    [
        "#C5001A",
        "#E4E3DB",
        "#113743",
        "#C5BEBA",
    ],
    // 
    [
        "#D35C37",
        "#BF9A77",
        "#D6C6B9",
        "#97B8C2",
    ],
    // 
    [
        "#919636",
        "#524A3A",
        "#FFFAE1",
        "#5A5F37",
    ],
    // 
    [
        "#B1D7D2",
        "#E5E2CA",
        "#432E33",
        "#E7472E",
    ],
    // 
    [
        "#2F2E33",
        "#D5D6D2",
        "#FFFFFF",
        "#3A5199",
    ],
    // 
    [
        "#756867",
        "#D5D6D2",
        "#353C3F",
        "#FF8D3F",
    ],
    // 
    [
        "#31A2AC",
        "#AF1C1C",
        "#F0EFFE",
        "#2F2F28",
    ],
    // 
    [
        "#6C5F5B",
        "#CDAB81",
        "#DAC3B3",
        "#4F4A45",
    ],
    // 
    [
        "#444C5C",
        "#CE5A57",
        "#78A5A3",
        "#E1B16A",
    ],
    // 
    [
        "#20232A",
        "#ACBEBE",
        "#F4F4EF",
        "#A01D26",
    ],
    // 
    [
        "#D55448",
        "#FFA577",
        "#F9F9FF",
        "#896E69",
    ],
    // 
    [
        "#344D90",
        "#5CC5EF",
        "#FFB745",
        "#E7552C",
    ],
    // 
    [
        "#080706",
        "#EFEFEF",
        "#D1B280",
        "#594D46",
    ],
    // 
    [
        "#5F968E",
        "#BFDCCF",
        "#E05858",
        "#D5C9B1",
    ],
    // 
    [
        "#962715",
        "#FFFFFF",
        "#1E1E20",
        "#BBC3C6",
    ],
    // 88
    [
        "#688B8A",
        "#A0B084",
        "#FAEFD4",
        "#A57C65",
    ],
    // 
    [
        "#262F34",
        "#F34A4A",
        "#F1D3BC",
        "#615049",
    ],
    // 
    [
        "#882426",
        "#CDBEA7",
        "#323030",
        "#C29545",
    ],
    // 
    [
        "#42313A",
        "#6C2D2C",
        "#9F4636",
        "#F1DCC9",
    ],
    // 
    [
        "#FBCD4B",
        "#A3A599",
        "#282623",
        "#88A550",
    ],
    // 
    [
        "#FFBEBD",
        "#FCFCFA",
        "#337BAE",
        "#1A405F",
    ],
    // 
    [
        "#0F1F38",
        "#8E7970",
        "#F55449",
        "#1B4B5A",
    ],
    // 
    [
        "#81715E",
        "#FAAE3D",
        "#E38533",
        "#E4535E",
    ],
    // 
    [
        "#061283",
        "#FD3C3C",
        "#FFB74C",
        "#138D90",
    ],
    // 
    [
        "#061283",
        "#FD3C3C",
        "#FFB74C",
        "#138D90",
    ],
    // 
    [
        "#DDDEDE",
        "#232122",
        "#A5C05B",
        "#7BA4A8",
    ],
    // 
    [
        "#B3DBC0",
        "#FE0000",
        "#FDF6F6",
        "#67BACA",
    ],
    // 
    [
        "#A49592",
        "#727077",
        "#EED8C9",
        "#E99787",
    ],
    // 
    [
        "#488A99",
        "#DBAE58",
        "#4D585B",
        "#B4B4B4",
    ],
    
]