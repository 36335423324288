import React from 'react';
import styled from 'styled-components/macro';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { calcContrastRL, ColorExt } from '../ThemeBrowser/themeGenerator/colorUtil';
import { ColorTheme } from './useColorTheme';

interface Props {
    theme: ColorTheme;
    isLoading: boolean;
}

const Wrapper = styled.div``;

const TextPreviewWrapper = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 10px 20px;
    font-family: Roboto;
    font-size: 22px;
    line-height: 1.4;
`;

const Underline = styled.span`
    padding-bottom: 1px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
`;

const LoadingWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding-top: 30px;
`;

const ColorToColorBlock = styled.div<{ n: number }>`
    display: grid;
    font-size: 16px;
    grid-template-columns: 1fr repeat(${props => props.n-1}, 1fr) 1fr;
    grid-template-rows: 40px 40px;


    & > div {
        width: 100%;
        height: 100%;
    }

    justify-items: center;
    align-items: center;
`;

const PreviewBlock = ({ colors }: { colors: ColorExt[] }) => {
    const colorCodes = colors.map(c => c.code);
    return (
        <TextPreviewWrapper style={{ backgroundColor: colorCodes[0], color: colorCodes[1]  }}>
            <p>
                Praesent vel <Underline style={{ borderColor: colorCodes[2] }}>interdum mauris</Underline>. Nam ac tellus dolor. Curabitur aliquam semper purus vel dictum. Morbi velit sem, vehicula id libero semper, consectetur eleifend tellus. Duis mattis gravida elit id mollis. Vestibulum finibus porta mattis. Quisque condimentum ante sed urna lacinia tincidunt. Nam vel ligula ultricies, efficitur ligula at.
            </p>

            <ColorToColorBlock n={colors.length}>
                <div key="placeholder3" />
                    {colors.slice(1).map((c,i) => <span key={`contrast_${i}_${c.code}`} style={{ color: c.code }}>Contrast: {calcContrastRL(c, colors[0]).toFixed(1)}</span>)}
                <div key="placeholder4" />

                <div key="placeholder1" />
                {colors.slice(1).map((c,i) => <div key={`${i}_${c.code}`} style={{ background: c.code }}> </div>)}
                <div key="placeholder2" />
            </ColorToColorBlock>
        </TextPreviewWrapper>
    )
}

export const ColorThemePreview = ({ theme, isLoading }: Props) => {
    return isLoading ? 
        <LoadingWrapper>
            <LoadingIndicator />
        </LoadingWrapper>
        : (
            <Wrapper>
                {theme.options.map((option,i) =>
                    <PreviewBlock key={i} colors={option.colors.map(cv => theme.makeColorVariant(theme.colors[cv.colorIdx], cv))} />)}
            </Wrapper>
        );
}
