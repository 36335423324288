import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { blockShadow, roundingCss } from '../Common.css';
import { Themed } from './Common';
import { Checkbox, Tags } from './Controls';
import { MockItem } from './MockListData';

interface Props extends Themed {
    item: MockItem;
}

const CardWrapper = styled.div<Themed>`
    display: flex;
    flex-flow: column;
    padding: ${({ t }) => t.geometry.actionPadding.map(x => `${x*2}px`).join(" ")};
    gap: 0.5rem;

    width: ${({t}) => t.elements.card.even ? '30%' : 'auto'};
    box-sizing: border-box;

    ${props => blockShadow(props.t.elements.card.shadow)}
    ${props => roundingCss(props.t.elements.card.rounding)}

    & > * {
        margin: 0;
    }
`;

export const Card = (props: Props) => {
    const { item } = props;
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [id] = useState<string>(Math.ceil(10000*Math.random()).toString());

    return (
        <CardWrapper t={props.t}>
            <h4>{item.title}</h4>
            <p>{item.description}</p>
            <Checkbox
                id={id}
                label="turpis orci vel"
                isChecked={isSelected}
                setIsChecked={setIsSelected}
                t={props.t}
                />
            <Tags tags={item.tags} t={props.t} />
        </CardWrapper>
    );
}



const CardsContainer = styled.div<Themed>`
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    padding: ${({t}) => t.geometry.paddingUnit*4}px 0;
`;

interface CardsProps extends Themed {
    items: MockItem[];
}

export const Cards = (props: CardsProps) => {
    return <CardsContainer t={props.t}>
        {props.items.map((item,idx) => <Card key={idx} t={props.t} item={item} />)}
    </CardsContainer>
}
