import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, IconButton, Slider, Typography } from '@material-ui/core';
import styled from 'styled-components/macro';
import { ColorView } from '../PaletteConfiguration/ColorViewers';
import { ColorThemeData, ColorThemeOption, Offset, OffsetValues } from './useColorTheme';
import { ActionRow, OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { ClipboardButton } from '../../toolympus/components/primitives/Buttons';
import {
    ArrowLeft,
    ArrowRight,
    CasinoOutlined,
    DeleteOutlined,
    VisibilityOffOutlined,
    VisibilityOutlined,
    InfoOutlined
} from '@material-ui/icons';

interface Props {
    theme: ColorThemeData;
    showExplanation: () => void;
}

const Controls = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    gap: 12px;
    padding: 12px 0;
`;

const ColorEditWrapper = styled.div`
    display: flex;
    flex-flow: row;
    gap: 12px;
`;

const ColorOptionEditWrapper = styled(ColorEditWrapper)`
    margin-top: 12px;
    padding: 12px 0;
    border-top: 1px solid #ddd;
`;

const ColorEdit = styled.div<{ rows: number }>`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(${props => props.rows}, 32px);
    align-items: center;
    gap: 6px;
    min-width: 150px;
    padding: 0 6px;
`;

const ColorEditLabels = styled(ColorEdit)`
    min-width: 140px;
    padding-right: 12px;

    & > * {
        min-height: 31px;
    }
`;

export const ColorThemeEditor = (props: Props) => {
    const {
        theme,
        nextPalette,
        updateColor,
        removeColor,
        addColor,
        moveOptionColor,
        addOption,
        removeOption,
        updateOptionColorVariant,
        randomOptionVariant,
    } = props.theme;

    const [isColorCopyOpen, setIsColorCopyOpen] = useState<boolean>(false);

    const [isOptionCollapsed, setIsOptionCollapsed] = useState<boolean[]>(theme.options.map(() => false));
    const toggleOptionCollapsed = (option: number) => setIsOptionCollapsed(opts => theme.options.map((_,i) => i === option ? !opts[i] : (opts[i] || false)));

    const optionCss = (option: ColorThemeOption, prefix: string) => option.colors.length < 2 ? "" :
        `${prefix ? "." + prefix + " {\n" : ""}${prefix ? "    " : ""}background-color: ${theme.makeColorVariant(theme.colors[option.colors[0].colorIdx], option.colors[0]).code}
${prefix ? "    " : ""}color: ${theme.makeColorVariant(theme.colors[option.colors[1].colorIdx], option.colors[1]).code}
${prefix ? "}\n" : ""}
${option.colors.slice(2).map((accent,accentIdx) => `${prefix ? "." + prefix + " " : ""}.accent${accentIdx+1} { color: ${theme.makeColorVariant(theme.colors[accent.colorIdx], accent).code} }` ).join("\n")}`

    const colorsCSS = theme.options.map((o,i) => optionCss(o, i === 0 ? "" : `option${i}`)).join("\n\n");


    return (
        <div>
            <Controls>
                <Button onClick={nextPalette}>next palette</Button>
                <Button onClick={addColor}>add color</Button>
                <Button onClick={addOption}>add option</Button>
                <Button onClick={() => setIsColorCopyOpen(true)}>export css</Button>
                <IconButton size="small" onClick={props.showExplanation}><InfoOutlined /></IconButton>
            </Controls>

            <ColorEditWrapper>
                <ColorEditLabels key={`legend`} rows={1}>
                    <Typography key="Base" variant="caption">Colors</Typography>
                </ColorEditLabels>
                {theme.colors.map((c,i) => (
                    <ColorEdit key={`${i}_${c.code}`} rows={1}>
                        <div style={{ display: "flex", flexFlow: "row", alignItems: 'center', justifyContent: "flex-start", width: "100%" }}>
                            <ColorView
                                color={c.code}
                                canEdit
                                onChange={v => updateColor(i, v)}
                                />
                            {theme.colors.length > 3 && <IconButton size="small" onClick={() => removeColor(i)}><DeleteOutlined /></IconButton>}
                        </div>
                    </ColorEdit>
                ))}
            </ColorEditWrapper>

            {theme.options.map((option,optionIdx) => (<ColorOptionEditWrapper key={optionIdx}>
                <ColorEditLabels key={`legend`} rows={isOptionCollapsed[optionIdx] ? 1 : 5}>
                    <Typography key="Base" variant="caption">
                        Option {optionIdx+1}
                        <IconButton size="small" onClick={() => randomOptionVariant(optionIdx)}><CasinoOutlined /></IconButton>
                        <IconButton size="small" onClick={() => toggleOptionCollapsed(optionIdx)}>{isOptionCollapsed[optionIdx] ? <VisibilityOutlined /> : <VisibilityOffOutlined />}</IconButton>
                        <IconButton size="small" onClick={() => removeOption(optionIdx)}><DeleteOutlined /></IconButton>
                    </Typography>
                    {!(isOptionCollapsed[optionIdx] || false) && <>
                        <Typography key="Base" variant="caption">Base</Typography>
                        <Typography key="Lightness" variant="caption">Lightness</Typography>
                        <Typography key="Saturation" variant="caption">Saturation</Typography>
                        <Typography key="Result" variant="caption"> </Typography>
                    </>}
                </ColorEditLabels>
                {option.colors.map((c,i) => (
                    <ColorEdit key={`${i}_${theme.colors[c.colorIdx].code}`} rows={isOptionCollapsed[optionIdx] ? 1 : 5}>
                        {!(isOptionCollapsed[optionIdx] || false) && <>
                            <div style={{ display: "flex", flexFlow: "row", justifyContent: "center", width: "100%" }}>
                                <IconButton size="small" onClick={() => moveOptionColor(optionIdx, i, -1)}><ArrowLeft /></IconButton>
                                <IconButton size="small" onClick={() => moveOptionColor(optionIdx, i, 1)}><ArrowRight /></IconButton>
                            </div>

                            <ColorView
                                color={theme.colors[c.colorIdx].code}
                                canEdit
                                onChange={v => updateColor(i, v)}
                                />

                            <Slider
                                aria-label="lightness"
                                min={-5}
                                max={5}
                                value={c.lightness || 0}
                                step={null}
                                onChange={(e,v) => updateOptionColorVariant(optionIdx, i, { lightness: v as Offset })}
                                valueLabelDisplay="off"
                                marks={OffsetValues.map(x => ({ value: x }))}
                                
                                track={false}
                                />

                            <Slider
                                aria-label="saturation"
                                min={-5}
                                max={5}
                                value={c.saturation || 0}
                                step={null}
                                onChange={(e,v) => updateOptionColorVariant(optionIdx, i, { saturation: v as Offset })}
                                valueLabelDisplay="off"
                                marks={OffsetValues.map(x => ({ value: x }))}
                                track={false}
                                />
                        </>}
                        

                        <ColorView
                            color={theme.makeColorVariant(theme.colors[c.colorIdx], c).code}
                            />
                    </ColorEdit>
                ))}
            </ColorOptionEditWrapper>))}

            <Dialog open={isColorCopyOpen} onClose={() => setIsColorCopyOpen(false)} fullWidth maxWidth="sm">
                <DialogContent>
                    <ActionRow>
                        <OccupyFreeSpace />
                        <ClipboardButton value={colorsCSS} />
                    </ActionRow>
                    <textarea disabled value={colorsCSS} rows={colorsCSS.split("\n").length} style={{ width: "100%" }} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsColorCopyOpen(false)}>close</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
