import React from 'react';
import { FormControl as MuiFormControl, FormHelperText, InputLabel, Input } from '@material-ui/core';
import { FieldType } from '../../hooks/useSchema';
import { expandProps, FormControlProps } from './FormControlProps';

interface TextEditProps extends FormControlProps {
    isPassword?: boolean;
}

export const TextEdit = (props: TextEditProps) => {
    const { row, field, schema, onChange, value, label, extraProps, controlProps, error } = expandProps(props);

    const { hint, disabled } = extraProps || {};
    const { rows, autoRows } = controlProps.view;
    const isLong = schema?.type === FieldType.textlong || rows;

    return <MuiFormControl fullWidth margin="dense" error={error}>
        <InputLabel>{label}</InputLabel>
        <Input
            type={props.isPassword ? "password" : undefined}
            {...controlProps}
            multiline={isLong}
            rows={autoRows ? undefined : (rows || 4)}
            value={value || ""} 
            onChange={({target: {value}}) => onChange(row, {[field]: value})} 
            disabled={disabled}
        />
        {hint && <FormHelperText>{hint}</FormHelperText>}
    </MuiFormControl>;
}
