export type Color = string;

interface BlockColors {
    background: Color;
    text: Color;
};

export interface ColorTheme {
    main: BlockColors;
    block: BlockColors;
    menu: BlockColors;
    menuAction: BlockColors;
    accent: Color;

    action: BlockColors;

    palette: Color[];
}

export type Font = string;

export type FontKeys = 'main' | 'caption' | 'motto' | 'h1' | 'h2' | 'h3' | 'h4' | 'menu' | 'accent' | 'action';

export type TextSizes = Record<FontKeys, number>;

export type FontWeight = 'normal' | 100 | 300 | 400 | 500 | 700;
export const FontTransforms = ["uppercase", "smallcaps", "lowercase", "capitalize"];

export interface FontParams {
    size: number;
    weight: FontWeight;
    transforms?: (typeof FontTransforms)[number];
    italic?: boolean;
    lineHeight: number;
}

export type FontAlterations = Pick<FontParams, 'transforms' | 'italic'>;

export type Rounding = 0 | string;
export type BlockRoundings = Rounding | [Rounding, Rounding, Rounding, Rounding];

export const MenuBehaviorOptions = ['spread', 'center', 'right', 'left', 'left-right'];
export type BlockSeparationOptions = 'none' | 'line' | 'shadow';
export interface BlockSeparation {
    type: BlockSeparationOptions;
    strength?: 1 | 2 | 3;
    position: 'top' | 'bottom' | 'both';
}

export interface MenuProperties {
    behavior: (typeof MenuBehaviorOptions)[number];
    padding: [number, number];
    gap: number;
    separation: BlockSeparation;
}

export const AlignOptions = ['start', 'end', 'center'];

export interface HeaderProperties {
    includeImage: boolean;
    image_url: string;
    block_height: number;
    block_fit_padding: boolean;
    caption_behavior: {
        align_horiozontal: (typeof AlignOptions)[number];
        align_vertical: (typeof AlignOptions)[number];
        background: 'opaque' | 'transparent';
    };
    tint_behavior?: {
        strength: 'normal' | 'strong',
        color: 'normal' | 'grayscale',
        gradient: 'none' | 'from-opaque' | 'from-transparent',
        behavior: 'full' | 'block',
    };
    image_behavior: {
        color: 'asis' | 'grayscale';
    };
    image_params: {
        author: string;
        link: string;
    }
}

export interface CommonGeometry {
    actionRoundings: BlockRoundings;
    horizontalPadding: number;
    innerPadding: number;
    paddingUnit: number;
    actionPadding: [number, number];
}

export interface TextDecoration {
    kind: 'none' | 'underline' | 'twoline' | 'lead' | 'firstletter';
    subtype?: string;
    strength?: number;
    alteration?: number;
}

export interface Decoration {
    headers?: TextDecoration;
}

export interface LogoProperties {
    position: 'menu-left' | 'header-block' | 'header-oppose-block';
    color_variant?: 'text' | 'accent';
    size: 1 | 2 | 3;
}

export interface ElementsProperties {
    card: {
        shadow: 0 | 1 | 2 | 3;
        rounding: Rounding;
        even: boolean;
    },
    tag: {
        variant: 'outlined' | 'contained';
        rounding: Rounding;
        shadow: 0 | 1 | 2;
    },
    list: {
        separator: 1 | 2 | 3;
    },
    input: {
        variant: 'outlined' | 'simple';
        rounding: Rounding;
        highlight: 'lighten' | 'darken' | 'none';
    },

    ribbon: {
        is_present: boolean;
        has_logo: boolean;
        has_app_title: boolean;
        separation: {
            strength: 1 | 2 | 3;
        };
        rounding: Rounding;
    },

    menu: {
        is_present: boolean;
        has_logo: boolean;
        side: 'left' | 'right';
        position: 'static' | 'on-top';
        
        separation: {
            shadow: 0 | 1 | 2 | 3;
            border: 0 | 1 | 2 | 3;
        };

        active_higlight: {
            borders: string;
            border_strength: [number, number];
            border_color: 'accent' | 'text';
            background: 'none' | 'light' | 'dark';
        }
    },
}

export interface Theme {
    colors: ColorTheme;
    fonts: {
        main: Font;
        caption: Font;

        params: Record<FontKeys, FontParams>;
    },
    geometry: CommonGeometry;
    decoration: Decoration;

    menu: MenuProperties;
    header: HeaderProperties;
    logo: LogoProperties;
    action: {
        align_horizontal: (typeof AlignOptions)[number];
        shadow: 0 | 1 | 2 | 3;
    }

    elements: ElementsProperties;
}
