import styled from 'styled-components/macro';
import { withTheme } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';


export const Link = withTheme(styled(RouterLink)`
    text-decoration: none;
    color: ${props => props.theme.palette.primary.dark};
`);

export const PseudoLink = withTheme(styled.div`
    color: ${props => props.theme.palette.primary.main};
    cursor: pointer;
    font-weight: 500;
`);


export const ItemRow = styled.div`
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 0.25rem;
`;
