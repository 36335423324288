export const ruMessages = {
    error: {
        general: "Произошло что-то нехорошее",
        validation: "Некоторые поля содержат ошибки",
        noRights: "У вас нет прав на выполнение этого действия",

        files: {
            content_type_not_allowed: "Недопустимый тип файла. Разрешены: {allowed_types}",
            file_too_large: "Файл слишком большой. Максимальный размер файла: {max_size_kb}кб",
            alias_occupied: "Алиас уже занят",
        },

        auth: {
            login_taken: "Пользователь с таким email уже зарегистрирован",
            password_too_weak: "Пароль должен состоять минимум из 8 символов и включать большие и маленькие латинские буквы и цифры",
            failed_login: "Указан неверный логин или пароль",
            invite_code_invalid: "Код приглашения не подходит. Возможно срок действия ссылки уже истек - пожалуйста, проверьте, не получали ли вы email с новой ссылкой",
            confirmation_code_invalid: "Код подтверждения не подходит. Возможно срок действия ссылки уже истек - пожалуйста, проверьте, не получали ли вы email с новой ссылкой",
            reset_token_invalid: "Код сброса пароля не подходит. Возможно срок действия ссылки уже истек - пожалуйста, проверьте, не получали ли вы email с новой ссылкой",
            user_already_confirmed: "Email пользователя уже подтвержден",
            user_doesnt_need_confirmation: "Email пользователя не требует подтверждения",
            user_blocked: "Пользователь заблокирован. Это могло произойти, если вы несколько раз ввели неправильный пароль. В таком случае вход блокируется на {minutes} минут. Если вы считаете, что произошла ошибка, пожалуйста свяжитесь с администратором",
            passwords_dont_match: 'Пароли не совпадают',
        },

        dictionary: {
            cant_update_synthetic_dictionary: "Нельзя обновить синтетический справочник - обновите значение в исходном справочнике",
        },

        export: {
            invalid_code: "Конфигурация экспорта не найдена",
        },
    },
    success: {
        clipboard: "Значение скопировано в буфер обмена"
    },
    login: {
        email: "Email",
        password: "Пароль",
        submit: "Войти",
        errorcreds: "Введен неправильный логин или пароль. Пожалуйста, попробуйте снова или воспользуйтесь восстановлением пароля"
    },
    signup: {
        errorMessage: "Мы не смогли вас зарегистрировать. Возможно срок действия приглашения истек или что-то еще пошло не так. Пожалуйста, свяжитесь с нами",
        successMessage: "Запрос отправлен. Вы получите доступ в систему, как только его утвердит администратор",
        email: "Email",
        password: "Пароль",
        passwordRepeat: "Повторите пароль",
        submit: "Зарегистрироваться"
    },
    signupConfirmation: {
        defaultMessage: "Подтверждаем email...",
        successMessage: "Email успешно подтвержден. Спасибо!",
        errorMessage: "Что-то пошло не так. Пожалуйста, свяжитесь с нами."
    },
    resetPassword: {
        email: "Email",
        submit: "Сбросить пароль"
    },
    restorePassword: {
        password: "Пароль",
        passwordRepeat: "Повторите пароль",
        submit: "Сменить пароль",
        success: "Пароль успешно изменен. Авторизуйтесь с новым паролем на странице входа",
    },
    emailConfirmation: {
        confirming: "Подтверждаем Email...",
        success: "Email успешно подтвержден, спасибо!",
        failure: "Произошла ошибка. Пожалуйста, попробуйте перезагрузить страницу.",
    },
    searchModal: {
        title: "Поиск",
        searchFieldLabel: "Найти...",
        pickItem: "Выбрать",
        emptyState: "Ничего не удалось найти. Попробуйте другой запрос."
    },
    searchField: {
        label: "Поиск...",
        buttonLabel: "Загрузить",
    },
    userManagement: {
        title: "Управление пользователями",
        header: {
            addUser: "Добавить пользователя",
            sendInvites: "Отправить приглашения",
            sendInvintesTip: "Приглашения будут отправлены только новым пользователям, без повторов",
            export_users: "Скачать список пользователей",
        },
        columns: {
            email: "Email",
            lastLogin: "Последний вход",
            blocked_until: "Блокировка",
            blocked_until_explanation: "Пользователь заблокирован и не сможет войти в систему до указанного времени",
            unblock: "Разблокировать пользователя",
            block: "Заблокировать пользователя",
            invited: "Приглашен",
            confirmed: "Подтвержден",
            resendConfirmRequest: "Отправить повторный запрос подтверждения",
            repeatedConfirmRequestNever: "Повторный запрос не отправлялся",
            repeatedConfirmRequestTime: "Повторный запрос отправлялся {time}",
            invite: "Пригласить",
            signInAs: "Авторизоваться",
            sendPasswordResetToken: "Отправить код для изменения пароля",
        },
        userPopup: {
            title: "Добавить пользователей",
            email: "Email",
            submit: "Создать",
            hint: "укажите email'ы создаваемых пользователей через пробел или точку с запятой"
        },
        changeEmailPopup: {
            title: "Изменить email пользователя",
            oldEmail: "Текущий Email",
            newEmail: "Новый Email",
        },
        password_reset_email_sent: "Письмо с кодом для смены пароля отправлено пользователю",
    },
    emails: {
        fields: {
            code: "Шаблон",
            title: "Название",
            description: "Описание",
            subject: "Тема",
            body: "Тело",
            active: "Активен",
        },

        popup: {
            title: "Редактировать шаблон",
            emailContent: "Содержимое письма",
            testEmail: "Отправить тестовое сообщение себе",
            testEmailTextTip: "Используйте это поле для ввода параметров в формате JSON",
            send: "Отправить",
            delete: "Удалить шаблон?",
        }
    },
    dictionaries: {
        title: "Справочники",
        statusSaved: "Сохранено",
        newRecordLabel: "Код новой записи",
        addRecord: "Добавить",
        selectDictionary: "Справочник",

        fields: {
            code: "Код",
            label: "Название",
            label_translations: "Переводы названий",
            sortorder: "Порядок",
            comment: "Комментарий",
            disabled: "Отключено",
        }
    },
    queryconsole: {
        title: "Название запроса",
        query: "Запрос",
        run: "Выполнить",
        history: "История",
    },
    common: {
        cancel: "Отмена",
        close: "Закрыть",
        add: "Добавить",
        delete: "Удалить",
        edit: "Редактировать",
        replace: "Заменить",
        save: "Сохранить",
        yes: "Да",
        select: "Выбрать",
        deselect: "Убрать",
        copy: "Копировать",

        fullscreen: "Полноэкранный режим",

        invalid_date_format: "Неправильный формат даты",
    },
    currency_rates: {
        date: "Дата",
        rate: "Курс",
        title: "Курсы валют",
    },
    formcontrol: {
        markdown: {
            write: 'Редактирование',
            preview: 'Предпросмотр',
        }
    },
    fieldssettings: {
        title: "Настройки полей",
        available: "Доступные поля",
        active: "Активные поля",
        reset: "Сбросить",
    },
    fieldsfilters: {
        title: "Фильтры",
        emptyState: 'Нажмите здесь или на кнопку "+" выше, чтобы добавить фильтр',
        add: 'Добавить фильтр',
    },
    files: {
        pageTitle: "Файлы",
        
        filename: "Имя файла",
        filetype: "Тип",
        meta: "Свойства",
        alias: "Алиас",
        comments: "Комментарии",

        copyLink: "Скопировать ссылку",

        removeConfirmation: "Удалить файл?",
        editDialogTitle: "Файл",
    },
    statusActions: {
        action: 'Действия',
        cancel: 'Отмена'
    },
    notifications: {
        title: "Уведомления",
        markasread: "Пометить как прочитанное",
        markallasread: "Отметить все как прочитанные",

        task: {
            title: "Название / тема",
            user_id: "Пользователь",
            email: "Email",
            kind: "Тип",
            display_name: "Имя",
            info: "Дополнительная информация",
            text: "Текст уведомления / письма",
            link: "Ссылка (для внутренних уведомлений)",
            comment: "Комментарий",
            targets: "Адресаты",
            is_executed: "Отправлено",
            executed_time: "Отправлено",

            controls: {
                title: "Уведомления",
                title_one: "Уведомление",
                show_executed: "Показывать отправленные",
                execute: "Отправить",
            },

            target: {
                all: "Все",
                add_filtered: "Добавить отфильтрованных",
                remove_all: "Убрать всех",
                noone: "Добавить адресатов",
                count: "Всего: {count} адресатов",
            },
        }
    },
    chat: {
        send: 'Отправить',
        typeMessage: 'Сообщение...',
    },
    errorPasswordsDontMatch: 'Пароли не совпадают',
    comments: {
        title: "Комментарии",
        newComment: "Добавить комментарий (Ctrl+Enter для отправки)",
        deleteConfirmation: "Удалить комментарий?",
    },

    entity: {
        actions: {
            action_id: "ID",
            entity: "Сущность",
            record: "Запись",
            action: "Действие",
            parameters: "Параметры",
            user_id: "Пользователь",
            executed_at: "Время",
        },
    },
    dashboard: {
        update: 'Обновить',
        errors: {
            value_not_configured: 'Для виджета не выбрано поле - значение',
            query_not_configured: 'Для виджета не сконфигурирован запрос',
            details_query_not_configured: 'Для виджета не сконфигурирован запрос деталей',
            unknown: 'Произошла ошибка'
        },
        screen: {
            dash: 'Дэшборд',
            widgets: 'Виджеты',
            edit: 'Редактировать дэшборды',
            create: 'Создать дэшборд',
            params: 'Параметры',
            type: 'Тип',
            add: "Создать виджет",
            addexisting: "Добавить существующий",
            pie: 'Круговая диаграмма',
            bar: 'Столбчатая диаграмма',
            counter: 'Счётчик',
            placeholder: 'Заглушка',
            save: "Сохранить",
            title: 'Название',
            code: 'Код',
            widgettype: 'Тип виджета',
        },
        widget: {
            dialog: 'Настройки дэшборда',
            title: 'Название',
            duplicate: 'Дублировать',
            subtitle: 'Подзаголовок',
            resultfields: 'Поля результатов',
            query: 'Запрос',
            detailsresultfields: 'Поля деталей',
            label: 'Метка',
            name: 'Название',
            link: 'Ссылка',
            links_by_labels: 'Ссылки',
            links_by_labels_hint: 'Общая ссылка или несколько строк в формате "значение: ссылка"',
            empty_state_message: 'Сообщение при пустом состоянии',
            empty_state_message_hint: 'Отображается, если нет записей соответствующих запросу',
            counter_colors: "Цвета счетчика",
            counter_colors_hint: 'HTML код цвета или несколько строк в формате "максимальное-значение: код-цвета"',


            displaySettings: 'Настройки отображения',
            show_pct: 'Показать в процентах',
            show_table: 'Показать таблицу',
            show_chart: 'Показать диаграмму',
            total_row: 'Total строка',
            value_field: 'Поле - значение',
            label_field: 'Поле - метка',
            query_substitutions: 'Подстановки для строки запроса',
            query_string: 'Строка запроса',
            details_query_string: 'Строка запроса деталей',
            remove: 'Удалить виджет',
            params: 'Параметры',

            param: {
                label: 'Метка',
                default: 'Значение по умолчанию',
                code: 'Название',
                fieldtype: 'Тип поля',
                fieldtypes: {
                    number: 'Число',
                    text: 'Текст',
                }
            },

            select_res_field: {
                fieldtype: 'Результат - простое значение',
                entity: 'Результат - значение из словаря',
                fieldtype_label: 'Тип поля',
                number: 'Число',
                text: 'Текст',
                percent: 'Проценты',
                entity_label: 'Значение из словаря',
                entity_field: 'Поле значения',
            }
        }
    },
    pages: {
        pageListTitle: "Страницы",
        editPageTitle: "Редактировать страницу",
        componentListTitle: "Компоненты",
        editComponentTitle: "Редактировать компонент",
        deleteConfirmation: "Удалить страницу?",
        page: {
            slug: "Slug",
            language: "Язык",
            styles: "Стили",
            properties: "Свойства"
        },
        component: {
            order: "Порядок",
            contenttext: "Содержимое",
            subtypetext: "Тип",
            properties: "Свойства"
        }
    },

    images: {
        upload_image_hint: "Нажмите, чтобы загрузить изображение",
        upload: "Загрузить",
        delete: "Удалить",
    },

    contact_form: {
        title: "Сообщения",

        labels: {
            message: "Сообщение",
            user_id: "Пользователь",
            contact: "E-mail",
            is_processed: "Обработано",
            is_discarded: "Спам",
            is_important: "Важное",
            created_datetime: "Дата",
        },
    },

    tags: {
        labels: {
            label: "Тег",
            count: "Число использований",
        },

        create: "Создать тег",
        duplicate: "Дублировать тег",
        edit: "Редактировать тег",
        remove: "Удалить тег?",
        remove_in_use: "Тег используется. При удалении он будет удален у всех записей.",
        tag_usage: "Использования тега ",
        collect_tags: "Собрать теги",

        merge: {
            select_source: "Выберите тег, с которым нужно соединить",
            confirmation: "Соединить теги?",
            execute: "Соединить",
        },

        error: {
            cant_merge_no_tags: "Не выбраны теги для соединения",
        },
    },

    contests: {
        scoring: {
            sheets_list_title: "Оценочные листы",
            sheet_edit_title: "Оценочный лист: {title}",
            create_sheet: "Создать оценочный лист",
            sheet_preview: "Предпросмотр",

            fields: {
                sheet: {
                    code: "Код",
                    title: "Название",
                    description: "Описание",
                    criteria: "Критерии",
                },
                criteria: {
                    name: "Название",
                    comment: "Комментарий/подсказка",
                    type: "Тип",
                    min_value: "Минимум",
                    max_value: "Максимум",
                    options: "Варианты",
                    is_required: "Обязательное поле",

                    custom: "Специальный тип",
                    use_chips: "Кнопки",
                },
            },

            checkbox: {
                yes: "Да",
                no: "Нет",
            },
        },

        rounds: {
            config: {
                title: 'Раунды',
                labels: {
                    configuration: "Конфигурация",
                    code: "Код",
                    has_playoff: "Раунды на вылет",
                    playoff_stages_cnt: "Число стадий",
                    playoff_teams_required: "Требуется команд",
                    playoff_team_matching_rule: "Способ матчинга команд",
                    playoff_has_third_place_game: "Игра за 3-е место",
                    playoff_round_title_final: "Название финального раунда",
                    playoff_round_title_third: "Название раунда за 3-е место",
                    playoff_round_title_po: "Название раундов на вылет",
                    
                    has_rating_rounds: "Рейтинговые раунды",
                    rating_group_kind: "Тип группы",
                    rating_group_kind_values: {
                        pairs_stable: "Пары постоянные",
                        pairs_shift: "Пары сдвигаемые",
                        triplets: "Тройки",
                    },

                    rating_grouping_rule: "Способ формирования групп",
                    rating_grouping_rule_values: {
                        random: "Случайный",
                        sequential: "Последовательный",
                        inward: "Снаружи внутрь",
                        halves: "Половинами",
                    },
                    rating_player_rounds_cnt: "Число раундов на команду",
                    rating_round_title: "Название рейтингового раунда",
                    players_accepted_cnt: "Число допускаемых команд",
                    rating_rounds_cnt: "Число рейтинговых раундов",
                    round_title_hint: "Допустимые подстановки: {stage} {number}",

                    arbiter_access_url: "Формат ссылки для арбитра",
                    player_access_url: "Формат ссылки для команды",
                    access_url_hint: "Доступные подстановки: {round_id}, {party_id}, {access_code}",

                    is_launched: "Запущены",
                    launch: "Запустить",
                    cancel: "Отменить",
                    cancel_confirmation_title: "Отменить раунды?",
                    cancel_confirmation_explanation: "Все созданные стадии, раунды, назначения и поставленные оценки будут удалены. Продолжить?",
                },

                stages: {
                    launch: "Запустить",
                    launch_confirmation: "Стадия будет запущена, команды и арбитры получат доступ к раундам",
                    launch_forced_confirmation: "В раундах есть проблемы. Запустить несмотря на это?",
                    unlaunch: "Отменить запуск",
                    unlaunch_confirmation: "Запуск стадии будет отменен, доступ команд и арбитров к раундам будет отозван.",
                    finish: "Завершить",
                    calc_results: "Подсчитать результаты",
                    export: "Выгрузить данные",
                    finish_confirmation: "Стадия будет завершена, простановка оценок заблокируется, команды будут отправлены в следующую стадию в соответствии с результатами раундов. Продолжить?",
                    finish_forced_confirmation: "В раундах есть проблемы. Завершить несмотря на это?",
                    unfinish: "Отменить завершение",
                    unfinish_confirmation: "Стадия будет возобновлена, обновление прогресса участников будет отменено. Продолжить?",
                    validate: "Проверить",
                    clear_problems: "Очистить проблемы",
                    assign_players: "Назначить команды",
                    assign_players_confirmation: "Существующие назначения будут перезаписаны. Продолжить?",
                    assign_arbiters: "Назначить арбитров",
                    assign_arbiters_confirmation: "Существующие назначения будут перезаписаны. Продолжить?",
                    include_tags: "Включить по тегам",
                    exclude_tags: "Исключить по тегам",

                    clear_arbiters: "Очистить арбитров",
                    clear_arbiters_confirmation: "Будут удалены все назначения арбитров в этой стадии. Продолжить?",
                    clear_players: "Очистить команды",
                    clear_players_confirmation: "Будут удалены все назначения команд в этой стадии. Продолжить?",
                    clear_stage: "Пересоздать",
                    clear_stage_confirmation: "Будут удалены все назначения и вся информация по раундам этой стадии. Продолжить?",

                    qr_codes: "Получить QR коды для доступа к оценкам",

                    view: "Вид",
                    view_short: "Краткий",
                    view_detailed: "Полный",
                    view_results: "Результаты",

                    names: {
                        po_final: "Финал",
                        po_1_2: "Полуфинал",
                        po_1_4: "Четвертьфинал",
                        po_1_8: "Одна восьмая",
                        po_1_16: "Одна шестнадцатая",
                        rating: "Рейтинговые",
                    },

                    validation: {
                        rounds_with_problems: "Есть проблемы в раундах №№ {value}",
                        players_unassigned: "Команды не назначены",
                        arbiters_unassigned: "Арбитры не назначены",
                        conflicts: "Есть конфликты",
                        is_draw: "Ничья",
                        missing_scores: "Не все оценки проставлены: {arbiters}",
                        unequal_player_assignment: "Неравное назначение команд: {players}", 
                    },
                },

                validation: {
                    must_have_some_rounds: "Должны быть раунды",
                    specify_playoff_stages_cnt: "Укажите число стадий на вылет",
                    specify_players_accepted: "Укажите число допущенных команд",
                    not_enough_players_for_playoff: "Недостаточно команд для раундов на вылет",
                    players_count_doesnt_match_required_for_playoff: "Число команд не соответствует необходимому для раундов на вылет",
                    specify_rating_rounds_cnt: "Укажите число рейтинговых раундов",
                    pairs_need_even_teams: "Для разбивки команд на пары необходимо четное число команд",
                    triplets_need_divisible3_teams: "Для разбивки команд на тройки число команд должно делиться на 3",
                    triplets_must_have_2_rounds_per_team: "При разбивке на тройки должно быть по два раунда на команду",
                    cant_update_launched: "Невозможно обновить - раунды уже запущены",
                    problems_with_config: "Пожалуйста, исправьте проблемы с конфигурацией",
                    already_launched: "Раунды уже запущены",

                    arbiter_not_found: "Арбитр не найден",
                    cant_assign_player_against_itself: "Команда не может играть против самой себя",
                    cant_assign_the_same_arbiter_to_a_round_twice: "Арбитр может быть назначен на один и тот же раунд только один раз",
                    dont_know_how_to_create_multiple_rounds: "Некорректные настройки: алгоритм сбора множественных пар",
                    next_stage_is_finished: "Следующая стадия завершена - сначала откатите ее",
                    only_two_rounds_in_triples: "Некорректные настройки: в тройках может быть только по два раунда на команду",
                    player_not_found: "Команда не найдена",
                    players_count_doesnt_match_required_for_rounds: "Некорректные настройки: число команд не соответствует необходимому для раундов",
                    stage_has_problems: "В стадии есть проблемы",
                    stage_is_finished: "Стадия уже завершена",
                    stage_is_launched: "Стадия уже запущена",
                    stage_is_not_finished: "Стадия еще не завершена",
                    stage_is_not_launched: "Стадия еще не запущена",
                    unknown_matching_rule: "Некорректные настройки: правило матчинга команд",
                },
            },

            stage: {
                labels: {
                    kind: "Тип",
                    is_launched: "Запущена",
                    is_finished: "Завершена",
                },
            },

            round: {
                edit: "Раунд {title}",
                labels: {
                    title: "Название",
                    number: "Номер",
                    time: "Время",
                    description: "Описание",
                    winner: "Победитель",
                    arbiters: "Арбитры",
                    vs: "vs",
                    player1: "Команда 1",
                    player2: "Команда 2",
                    player_unassigned: "не выбрана",
                    player1_score: "Результат команды 1",
                    player2_score: "Результат команды 2",
                    arbiter: "Арбитр {position}",
                    arbiter_unassigned: "не выбран",
                },
            },

            player_standing: {
                sort_order: "№",
                display_name: "Команда",
                score: "Результат",
                is_round_winner: "Победа в раунде",
                pass: "Проходит дальше",

                pass_yes: "Да",
                pass_no: "Нет",
            },

            player: {
                select: "Выбрать команду",
                labels: {
                    display_name: "Номер",
                    description: "Описание",
                    rounds_assigned: "Раундов назначено",
                    tags: "Теги",
                }
            },

            arbiter: {
                select: "Выбрать арбитра",
                labels: {
                    display_name: "Имя",
                    description: "Описание",
                    rounds_assigned: "Раундов назначено",
                }
            },

            scores: {
                score: "Оценить",
                scored: "Оценено",
                select_player: "Выбрать",
                selected_player: "Выбрана",
            },

            public: {
                stage_names: {
                    po_final: "Финал",
                    po_1_2: "Полуфинал",
                    po_1_4: "Четвертьфинал",
                    po_1_8: "Одна восьмая",
                    po_1_16: "Одна шестнадцатая",
                    rating: "Рейтинговые раунды",
                }
            }
        },
    },
}