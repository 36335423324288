import React from 'react';
import styled from 'styled-components/macro';
import { LogoConfig } from '../PseudoLogo';
import { Theme } from '../Theme';
import { blockBaseSizing, textSizes } from '../ThemePreview';
import { AppContent } from './AppContent';
import { Themed } from './Common';
import { Menu } from './Menu';
import { Ribbon } from './Ribbon';

interface Props {
    theme: Theme;
    logo: LogoConfig;
}

const Wrapper = styled.div<Themed>`
    position: relative;
    ${blockBaseSizing}
    display: flex;
    flex-direction: ${props => props.t.elements.menu.side === 'right' ? 'row-reverse' : 'row'};
    background: ${props => props.t.colors.main.background};
    color: ${props => props.t.colors.main.text};

    font-family: ${props => props.t.fonts.main};

    & h1, h2, h3, h4 {
        font-family: ${props => props.t.fonts.caption};
        display: inline-block;
        margin-top: 0.75em;
        margin-bottom: 0;
    }

    & a {
        text-decoration: none;
        font-weight: 600;
    }
    
    & p a {
        color: ${props => props.t.colors.accent};
    }

    ${props => textSizes(props.t.fonts)}
`;

const MainPane = styled.div`
    flex: 1;
`;

export const AppThemePreview = (props: Props) => {
    return (
        <Wrapper t={props.theme}>
            <Menu t={props.theme} logo={props.logo} />

            <MainPane>
                <Ribbon t={props.theme} logo={props.logo} />

                <AppContent t={props.theme} />
            </MainPane>
        </Wrapper>
    );
}
