import React from 'react';
import styled from 'styled-components/macro';
import { roundingCss } from '../Common.css';
import { Themed } from './Common';

interface Props extends Themed {
    placeholder: string;
}

const Wrapper = styled.div<Themed>`
    padding: ${({t}) => t.geometry.paddingUnit*0.5}px ${({t}) => t.geometry.paddingUnit*2}px;
    ${props => roundingCss(props.t.elements.input.rounding)}
    display: flex;
    flex-flow: row;
    align-items: center;
    background: ${props => {
        switch(props.t.elements.input.highlight) {
            case 'lighten': return '#ffffff20';
            case 'darken': return '#00000020';
            default: return 'transparent';
        }
    }};

    ${props => {
        switch(props.t.elements.input.variant) {
            case 'simple':
                return `
                    border-bottom: 1px solid ${props.t.colors.main.text};
                    border-radius: 0;
                `;
            case 'outlined':
                return `
                    border: 1px solid ${props.t.colors.main.text};
                `;
        }
    }}

    & input {
        color: ${props => props.t.colors.main.text};
        background: transparent;
        border: none;

        ::placeholder {
            color: ${props => props.t.colors.main.text}80;
        }
    }

    & input:focus {
        outline: none;
    }
`;

export const Input = (props: Props) => {
    return (
        <Wrapper t={props.t}>
            <input placeholder={props.placeholder} />
        </Wrapper>
    );
}
