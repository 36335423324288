import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { TextField, Button, IconButton } from '@material-ui/core';
import { Autorenew, Clear } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

interface SearchFieldProps {
    filter: string;
    setFilter: (v: string) => void;
    doSearch?: () => void;
    fieldLabel?: string;
    buttonLabel?: ReactNode;
    searchOnBlur?: boolean;
    noButton?: boolean;
    noClear?: boolean;
    small?: boolean;
    autoFocus?: boolean;
}

const SearchWrapper = styled.div`
    display: flex;
    flex-flow: row;
    align-items: flex-end;
`;

export const SearchField = (props: SearchFieldProps) => {
    const doSearch = props.doSearch || (() => { });
    return (
        <SearchWrapper>
            <TextField
                size={props.small ? "small" : "medium"}
                value={props.filter}
                onChange={e => props.setFilter(e.target.value)}
                onKeyPress={e => { if(e.key === "Enter") { doSearch(); }}}
                label={props.fieldLabel || <FormattedMessage id="searchField.label" />}
                onBlur={props.searchOnBlur ? doSearch : () => { }}
                autoFocus={props.autoFocus}
                InputProps={props.noClear ? {} : {
                    endAdornment: <IconButton size="small" onClick={() => props.setFilter("")}><Clear /></IconButton>
                }}
                />
            {!props.noButton && <Button color="primary"
                startIcon={<Autorenew />}
                onClick={!props.searchOnBlur ? doSearch : () => { }} >
                    {props.buttonLabel || <FormattedMessage id="searchField.buttonLabel" />}
            </Button>}
        </SearchWrapper>
    )
}
