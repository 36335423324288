import React from 'react';
import styled from 'styled-components/macro';
import { roundingCss, lightButtonShadow } from '../../Common.css';
import { Themed } from '../Common';

const TagWrapper = styled.span<Themed & { isSelected?: boolean }>`
    padding: ${({t}) => t.geometry.paddingUnit}px ${({t}) => t.geometry.paddingUnit*2}px;

    ${props => {
        const isContained = (props.t.elements.tag.variant === 'contained' && !props.isSelected)
        || (props.t.elements.tag.variant === 'outlined' && props.isSelected);

        const cBack = isContained ? props.t.colors.main.text : props.t.colors.main.background;
        const cText = isContained ? props.t.colors.main.background : props.t.colors.main.text;

        return `
            border: 1px solid ${props.t.colors.main.text};
            color: ${cText};
            background: ${cBack};
        `;
    }}

    ${props => lightButtonShadow(props.t.elements.tag.shadow)}
    ${props => roundingCss(props.t.elements.tag.rounding)}

    cursor: pointer;
`;

interface TagProps extends Themed {
    tag: string;
}

export const Tag = (props: TagProps) => {
    return <TagWrapper t={props.t}>{props.tag}</TagWrapper>;
}

const TagContainer = styled.div<Themed>`
    display: flex;
    flex-flow: row wrap;
    gap: ${({t}) => t.geometry.paddingUnit*2}px;
`;

interface Props extends Themed {
    tags: string[];
}

export const Tags = (props: Props) => {
    return (
        <TagContainer t={props.t}>
            {props.tags.map(tag => <Tag key={tag} tag={tag} t={props.t} />)}
        </TagContainer>
    );
}

