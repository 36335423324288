import React, { ReactNode } from "react";
import { FieldDefinition, FormControlsForFields, ValidationErrors } from "../components/schemed";
import { Schema, useSchema, mergeSchema, SchemaChanges } from "../hooks/useSchema";

interface Config {
    schemaKey?: string;
    schema?: Schema;
    schemaChanges?: SchemaChanges;
}

interface Datasource<T> {
    data: T;
    update: (changes: Partial<T>) => void;
    errors?: ValidationErrors;
}

export interface DataItemWithControls<T> extends Datasource<T> {
    controls: (fields: FieldDefinition[]) => ReactNode;
}

interface ControlsConfig {
    withArea?: boolean;
}

export const useItemWithControls = <T,>(datasource: Datasource<T>, config: Config): DataItemWithControls<T> => {
    const { schemaKey, schema, schemaChanges } = config;
    const schemas = useSchema();
    const sourceSchema = (schemaKey ? schemas[schemaKey] : schema) || {} as Schema;

    const finalSchema = mergeSchema(sourceSchema, schemaChanges || {});

    const controls = (fields: FieldDefinition[], cconfig?: ControlsConfig) => (
        <FormControlsForFields
            fields={fields}
            data={datasource.data}
            onChange={(o,c) => datasource.update(c)}
            schema={finalSchema}
            errors={datasource.errors}
            withArea={cconfig?.withArea}
            />
    );


    return {
        ...datasource,
        controls,
    }
}