import React from 'react';
import styled from 'styled-components/macro';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, withTheme } from '@material-ui/core';
import Logo from '../../logo.svg';


const Explanation = withTheme(styled.div`
    & > * {
        margin-bottom: 0.75em;
    }

    & u {
        text-decoration: none;
        border-bottom: 1px dashed ${props => props.theme.palette.primary.main};
    }
`);

const PopupLogoWrapper = styled.div`
    width: 100%;
    height: 30px;

    display: flex;
    flex-flow: row;
    justify-content: center;

    & > img {
        height: 100%;
    }
`;

interface Props {
    isOpen: boolean;
    close: () => void;
}

export const ColorThemeExplanationDialog = (props: Props) => {
    const { isOpen, close } = props;

    return (
        <Dialog open={isOpen} onClose={() => close()}>
            <DialogTitle>
                <PopupLogoWrapper><img src={Logo} alt="baesline" /></PopupLogoWrapper>
            </DialogTitle>
            <DialogContent>
                <Explanation>
                    <Typography>
                        In the color theme mode the app generates color themes. The foundation of a color theme is a palette of 3+ colors. You can define them manually or use the 'next palette' button to generate one. To change any palette color just click it.
                    </Typography>
                    <Typography>
                        The next step is to configure one or more color options, which use the colors from the palette, but can change their saturation or lightness.
                    </Typography>
                    <Typography>
                        The order of colors in the option matters: the first one is considered a background color, the second - main text color. All the remaining colors are accent colors. You may reorder colors in each option independently.
                    </Typography>
                    
                    <Typography>
                        The buttons on the top of the page can do the following:
                    </Typography>
                    <ul>
                        <li><Typography><u>Next palette</u> generates a new set of colors. It picks one of the pre-generated palettes or takes a random triangular/rectangular color scheme.</Typography></li>
                        <li><Typography><u>Add color</u> throws another color into the palette.</Typography></li>
                        <li><Typography><u>Add option</u> creates a new option.</Typography></li>
                        <li><Typography><u>Export CSS</u> allows to get the CSS of the configured theme.</Typography></li>
                    </ul>

                    <Typography>
                        There are also the following buttons on each option:
                    </Typography>
                    <ul>
                        <li><Typography><u>Randomize</u> shuffles colors and changes their settings at random.</Typography></li>
                        <li><Typography><u>Collapse</u> allows to hide option controls when you're done customizing its colors.</Typography></li>
                        <li><Typography><u>Remove</u> deletes the option.</Typography></li>
                    </ul>

                    <Typography>
                        When deciding on the final colors take a note of the contrast values displayed in the option preview. Each figure shows the relative luminosity contrast of the corresponding color against the background. Figures of 4 and above are considered good. Below 4 may be hard to read.
                    </Typography>
                </Explanation>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>got it</Button>
            </DialogActions>
        </Dialog>
    );
}
