import { useEffect, useState } from "react";
import { MasterPalette, Palette } from "../PaletteConfiguration/usePaletteConfig";
import { usePaletteList } from "../PaletteConfiguration/usePaletteList";
import { Color } from "../ThemeBrowser/Theme";
import { ColorExt, rgbToRgbStr, toColorExt, hslToRgb, hslToColorExt } from "../ThemeBrowser/themeGenerator/colorUtil";
import { pickRandom } from "../ThemeBrowser/themeGenerator/util";

export type Offset = number;
export const OffsetValues: Offset[] = Array.from(new Array(11)).map((_,i) => i-5);

export interface ColorVariant {
    lightness?: Offset;
    saturation?: Offset;
}

export interface OptionColorVariant extends ColorVariant {
    colorIdx: number;
}

export interface ColorThemeOption {
    colors: OptionColorVariant[];
}

const saturationFactor = 0.1;
const lightnessFactor = 0.1;

export const makeColorVariant = (color: ColorExt, variant: ColorVariant): ColorExt => {

    const hsl = [
        color.hsl[0],
        Math.max(0, Math.min(1, color.hsl[1] * (1+saturationFactor*(variant.saturation || 0)))),
        Math.max(0, Math.min(1, color.hsl[2] * (1+lightnessFactor*(variant.lightness || 0)))),
    ] as [number,number,number];

    const rgb = hslToRgb(hsl);

    return { hsl, rgb, code: rgbToRgbStr(rgb) };
}

export interface ColorTheme {
    colors: ColorExt[];
    options: ColorThemeOption[];
    makeColorVariant: (color: ColorExt, variant: ColorVariant) => ColorExt,
}

export interface ColorThemeData {
    theme: ColorTheme;
    isLoading: boolean;
    updateColor: (idx: number, code: string) => void;
    removeColor: (idx: number) => void;
    addColor: () => void;

    nextPalette: () => void;
    
    addOption: () => void;
    removeOption: (idx: number) => void;
    moveOptionColor: (option: number, idx: number, direction: 1|-1) => void;
    updateOptionColorVariant: (option: number, idx: number, changes: Partial<ColorVariant>) => void;
    randomOptionVariant: (option: number) => void;
}

const themeFromColors = (colors: Color[]): ColorTheme => {
    return {
        colors: colors.map(c => toColorExt(c)),
        options: [{ colors: colors.map((_,i) => ({ colorIdx: i }))}],
        makeColorVariant,
    }
}

const themeFromPalette = (master: MasterPalette, { colors }: Palette): ColorTheme => {
    return themeFromColors([
        master.colors[colors.back],
        master.colors[colors.text],
        master.colors[colors.accent],
        master.colors[colors.accent_contrast],
    ]);
}

const shuffle = <T,>(x: T[]): T[] => {
    const xx = [...x];
    let res = [];
    while(xx.length) {
        res.push(xx.splice(Math.floor(Math.random()*xx.length), 1)[0]);
    }
    return res;
}

const genRandomTriangleColor = (): ColorExt[] => {
    const hbase = Math.random();

    const [h1,h2,h3] = shuffle([hbase, hbase+1/3, hbase+2/3]);

    const gs = () => 0.25 + 0.75*Math.random();
    const gl = () => 0.25 + 0.55*Math.random();

    return [
        toColorExt("#ffffff"),
        hslToColorExt([h1, gs(), gl()]),
        hslToColorExt([h2 - Math.floor(h2), gs(), gl()]),
        hslToColorExt([h3 - Math.floor(h3), gs(), gl()]),
    ]
}

const genRandomSquareColor = (): ColorExt[] => {
    const hbase = Math.random();
    const [h1,h2,h3, h4] = shuffle([hbase, hbase+0.25, hbase+0.5, hbase+0.75]);

    const gs = () => 0.25 + 0.75*Math.random();
    const gl = () => 0.25 + 0.55*Math.random();

    return [
        toColorExt("#ffffff"),
        hslToColorExt([h1, gs(), gl()]),
        hslToColorExt([h2 - Math.floor(h2), gs(), gl()]),
        hslToColorExt([h3 - Math.floor(h3), gs(), gl()]),
        hslToColorExt([h4 - Math.floor(h4), gs(), gl()]),
    ]
}

const genRandomRectColor = (): ColorExt[] => {
    const hbase = 0.5*Math.random();
    const [h1,h2,h3, h4] = shuffle([hbase,1-hbase, 0.5-hbase, 0.5+hbase]);

    const gs = () => 0.25 + 0.75*Math.random();
    const gl = () => 0.25 + 0.55*Math.random();

    return [
        toColorExt("#ffffff"),
        hslToColorExt([h1, gs(), gl()]),
        hslToColorExt([h2 - Math.floor(h2), gs(), gl()]),
        hslToColorExt([h3 - Math.floor(h3), gs(), gl()]),
        hslToColorExt([h4 - Math.floor(h4), gs(), gl()]),
    ]
}

export const useColorTheme = (): ColorThemeData => {
    const { data: palettes, isLoading, } = usePaletteList(true);
    const [theme, setTheme] = useState<ColorTheme>(themeFromColors(["#ffffff", "#0000cc", "#ffffff", "#0000cc"]));

    const nextPalette = () => {
        if(!isLoading && palettes.length && Math.random() < 1/3.0) {
            const mp = pickRandom(palettes);
            const p = pickRandom(mp.options);
            setTheme(themeFromPalette(mp, p));
        } else {
            const x = Math.random();
            if(x <= 0.33) {
                setTheme(themeFromColors(genRandomTriangleColor().map(c => c.code)));
            } else if(x <= 0.66) {
                setTheme(themeFromColors(genRandomRectColor().map(c => c.code)));
            }
            else {
                setTheme(themeFromColors(genRandomSquareColor().map(c => c.code)));
            }
        }
    };

    useEffect(() => {
        nextPalette();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateColor = (idx: number, code: string) => {
        setTheme(t => ({ ...t, colors: t.colors.map((c,i) => i === idx ? toColorExt(code) : c)}))
    }

    const removeColor = (idx: number) => {
        setTheme(t => ({
            ...t,
            colors: t.colors.filter((_,i) => i !== idx),
            options: t.options.map(o => ({ ...o, colors: o.colors.filter(c => c.colorIdx !== idx).map(c => ({ ...c, colorIdx: c.colorIdx < idx ? c.colorIdx : c.colorIdx - 1}))})),
        }));
    }

    const addColor = () => {
        setTheme(t => ({
            ...t,
            colors: [...t.colors, toColorExt("#ffffff")],
            options: t.options.map(o => ({ ...o, colors: [...o.colors, { colorIdx: t.colors.length } ]}))
        }));
    }


    const addOption = () => {
        setTheme(t => ({
            ...t,
            options: [...t.options, { colors: t.colors.map((_,i) => ({ colorIdx: i })) }],
        }))
    }

    const removeOption = (idx: number) => {
        setTheme(t => ({
            ...t,
            options: t.options.filter((_,i) => i !== idx),
        }));
    }

    const moveOptionColor = (option: number, idx: number, direction: 1|-1) => {
        setTheme(t => {
            let newIdx = idx+direction;
            if(newIdx < 0) {
                newIdx = t.colors.length - 1;
            } else if (newIdx >= t.colors.length) {
                newIdx = 0;
            }

            const colors = t.options[option].colors;
            const col = colors[idx];
            colors.splice(idx, 1);
            colors.splice(newIdx, 0, col);
            
            return {
                ...t,
                options: t.options.map((o,i) => i === option ? { ...o, colors } : o),
            }
        })
    }

    const updateOptionColorVariant = (optionIdx: number, idx: number, changes: Partial<ColorVariant>) => {
        setTheme(t => ({
            ...t,
            options: t.options.map((o,i) => i === optionIdx ? { ...o, colors: o.colors.map((c,ci) => ci === idx ? { ...c, ...changes } : c)} : o),
        }));
    }

    const randomOptionVariant = (optionIdx: number) => {
        setTheme(t => {
            const optionUpdated = { ...t.options[optionIdx]};
            const shuffledColorIdx = shuffle(t.colors.map((_,i) => i));
            optionUpdated.colors = optionUpdated.colors.map((c,i) => ({
                ...c,
                colorIdx: shuffledColorIdx[i],
                lightness: pickRandom(OffsetValues),
                saturation: pickRandom(OffsetValues),
            }));

            return {
                ...t,
                options: t.options.map((o,idx) => idx === optionIdx ? optionUpdated : o),
            };
        })
    }

    return {
        theme,
        isLoading,
        updateColor,
        removeColor,
        addColor,

        nextPalette,

        addOption,
        removeOption,
        moveOptionColor,
        updateOptionColorVariant,
        randomOptionVariant,
    }
}