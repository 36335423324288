export const FeatureImages = [
    ["pexels-luca-nardone-3784143.jpg", "Luca Nardone", "https://www.pexels.com/photo/black-and-brown-leather-surface-3784143/"],
    ["pexels-emre-can-acer-2110951.jpg", "Emre Can Acer", "https://www.pexels.com/photo/blue-and-white-abstract-painting-2110951/"],
    ["pexels-dids-4951277.jpg", "Dids", "https://www.pexels.com/photo/close-up-photo-of-blue-abstract-art-4951277/"],
    ["pexels-tomáš-malík-3607083.jpg", "Tomáš Malík", "https://www.pexels.com/photo/blue-and-white-abstract-painting-3607083/"],
    ["pexels-ben-neale-380337.jpg", "ben neale", "https://www.pexels.com/photo/green-and-gray-wallpaper-380337/"],
    ["pexels-daria-shevtsova-1884303.jpg", "Daria Shevtsova", "https://www.pexels.com/photo/black-and-white-abstract-painting-1884303/"],
    ["pexels-will-mu-3802667.jpg", "Will Mu", "https://www.pexels.com/photo/yellow-geometric-staircase-in-contemporary-apartment-3802667/"],
    ["pexels-scott-webb-1022928.jpg", "Scott Webb", "https://www.pexels.com/photo/white-architectural-structure-1022928/"],
    ["pexels-gdtography-950241.jpg", "gdtography", "https://www.pexels.com/photo/modern-art-painting-in-white-950241/"],
    ["pexels-david-buchi-1070345.jpg", "David Buchi", "https://www.pexels.com/photo/black-and-white-diamond-shape-wallpaper-1070345/"],
    ["pexels-cams-unltd-2563854.jpg", "Cams Unltd", "https://www.pexels.com/photo/linear-green-leaf-plant-2563854/"],
    ["pexels-scott-webb-2346594.jpg", "Scott Webb", "https://www.pexels.com/photo/shapes-and-pattern-2346594/"],
    ["pexels-ir-solyanaya-634548.jpg", "Ir Solyanaya", "https://www.pexels.com/photo/assorted-colored-rocks-634548/"],
    ["pexels-iva-muškić-691710.jpg", "Iva Muškić", "https://www.pexels.com/photo/gray-and-black-hive-printed-textile-691710/"],

    ["pexels-brandon-montrone-1179229.jpg", "Brandon Montrone", "https://www.pexels.com/photo/green-pine-trees-1179229/"],
    ["pexels-trace-hudson-2365457.jpg", "Trace Hudson", "https://www.pexels.com/photo/snow-covered-mountain-2365457/"],
    ["pexels-zhaocan-li-1755243.jpg", "Zhaocan Li", "https://www.pexels.com/photo/snow-covered-mountain-1755243/"],
    ["pexels-jaime-reimer-2662116.jpg", "Jaime Reimer", "https://www.pexels.com/photo/beautiful-view-of-moraine-lake-2662116/"],
    ["pexels-arina-krasnikova-5709186.jpg", "Arina Krasnikova", "https://www.pexels.com/photo/autumn-trees-and-stream-near-mountains-5709186/"],
    ["pexels-daria-shevtsova-1680140.jpg", "Daria Shevtsova", "https://www.pexels.com/photo/aerial-photography-of-seashore-1680140/"],
    ["pexels-fabian-wiktor-994605.jpg", "Fabian Wiktor", "https://www.pexels.com/photo/seaside-994605/"],

    ["pexels-gdtography-911738.jpg", "gdtography", "https://www.pexels.com/photo/white-textile-911738/"],
    ["pexels-henry-&-co-2628393.jpg", "Henry & Co.", "https://www.pexels.com/photo/concrete-building-2628393/"],
    ["pexels-scott-webb-1544947.jpg", "Scott Webb", "https://www.pexels.com/photo/computer-graphics-wallpaper-1544947/"],
    ["pexels-李进-3172740.jpg", "李进", "https://www.pexels.com/photo/low-angle-photography-of-gray-spiral-building-3172740/"],
    ["pexels-rok-romih-3312671.jpg", "Rok Romih", "https://www.pexels.com/photo/photo-of-building-on-top-of-rocks-3312671/"],
    ["pexels-alex-azabache-3214995.jpg", "Alex Azabache", "https://www.pexels.com/photo/gray-and-black-buildings-3214995/"],
    ["pexels-jeffrey-czum-2228208.jpg", "Jeffrey Czum", "https://www.pexels.com/photo/train-near-buildings-2228208/"],
    ["pexels-john-lee-6100567.jpg", "John Lee", "https://www.pexels.com/photo/facade-of-modern-glass-skyscraper-in-daytime-6100567/"],
    ["pexels-maria-orlova-4915844.jpg", "Maria Orlova", "https://www.pexels.com/photo/contemporary-multistory-house-facades-under-foggy-sky-in-city-4915844/"],
    ["pexels-andrea-zanenga-6120151.jpg", "Andrea Zanenga", "https://www.pexels.com/photo/black-concrete-building-6120151/"],
    ["pexels-hert-niks-3224155.jpg", "Hert Niks", "https://www.pexels.com/photo/low-angle-photography-of-high-rise-building-3224155/"],
    ["pexels-vlada-karpovich-4451434.jpg", "Vlada Karpovich", "https://www.pexels.com/photo/city-street-skyline-new-york-4451434/"],
    ["pexels-charles-parker-5847339.jpg", "Charles Parker", "https://www.pexels.com/photo/city-skyline-building-office-5847339/"],
    ["pexels-arthouse-studio-4558327.jpg", "ArtHouse Studio", "https://www.pexels.com/photo/facade-of-ancient-amphitheater-on-sunny-day-4558327/"],
    ["pexels-berk-ozdemir-4200598.jpg", "BERK OZDEMIR", "https://www.pexels.com/photo/city-traffic-building-construction-4200598/"],
    ["pexels-berk-ozdemir-4200603.jpg", "BERK OZDEMIR", "https://www.pexels.com/photo/city-building-construction-office-4200603/"],
    ["pexels-philipp-birmes-830891.jpg", "Philipp Birmes", "https://www.pexels.com/photo/low-angle-photo-of-four-high-rise-curtain-wall-buildings-under-white-clouds-and-blue-sky-830891/"],
    ["pexels-pixabay-534174.jpg", "Pixabay", "https://www.pexels.com/photo/architectural-design-architecture-buildings-clouds-534174/"],
    ["pexels-jonas-ferlin-1963557.jpg", "Jonas Ferlin", "https://www.pexels.com/photo/mirror-facade-of-tall-building-1963557/"],
    ["pexels-burst-373965.jpg", "Burst", "https://www.pexels.com/photo/architecture-buildings-city-fog-373965/"],
];