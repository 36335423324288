import styled from 'styled-components/macro';
import { CircularProgress, CircularProgressProps, withTheme } from '@material-ui/core';

type SizeOption = 'font' | 's' | 'm' | 'l';

interface Props {
    sizeVariant: SizeOption;
}

const sizes = {
    'font': '1em',
    's': 15,
    'm': 30,
    'l': 45,
}

export const LoadingIndicator = withTheme(styled(CircularProgress)
    .attrs((props: CircularProgressProps & Props) => ({ size: sizes[(props.sizeVariant as SizeOption) || 'm'], ...props }))`
`);
