import React from 'react';
import styled from 'styled-components/macro';
import { IconButton } from '@material-ui/core';
import { Color } from '../ThemeBrowser/Theme';
import { usePaletteList } from './usePaletteList';
import { ColorSquareUnset } from './ColorViewers';
import { Form } from '../../toolympus/components/primitives/Forms';
import { LoadingIndicator } from '../../toolympus/components/primitives/LoadingIndicator';
import { OccupyFreeSpace } from '../../toolympus/components/primitives/ActionRow';
import { Add } from '@material-ui/icons';
import { TableForFields } from '../../toolympus/components/schemed';
import { FieldType } from '../../toolympus/hooks/useSchema';
import { useHistory } from 'react-router';

const ColorsPreviewWrapper = styled.div`
    display: flex;
    flex-flow: row;
`;

const ColorsPreview = ({ colors }: { colors: Color[] }) => (
    <ColorsPreviewWrapper>
        {colors.map(c => (<ColorSquareUnset style={{ background: c }} key={c} />))}
    </ColorsPreviewWrapper>
);

export const PalettesList = () => {
    const { data: palettes, isLoading, create } = usePaletteList();

    const history = useHistory();

    return (
        <Form
            title="Palettes"
            formPaperProps={{ elevation: 0 }}
            headerItems={<>
                {isLoading && <LoadingIndicator />}
                <OccupyFreeSpace />
                <IconButton onClick={() => create()}>
                    <Add />
                </IconButton>
            </>}
        >
            <TableForFields
                data={palettes.map((p,nbr) => ({ ...p, nbr: nbr+1 }))}
                autoexpander={{ increment: 20, initialRows: 20}}
                fields={[
                    ["nbr"],
                    ["colors"],
                    ["options_count"],
                    ["is_active"],
                ]}
                schema={{
                    nbr: { type: FieldType.text, label: "#" },
                    colors: { type: FieldType.text, label: "Colors" },
                    options_count: { type: FieldType.text, label: "Options #" },
                    is_active: { type: FieldType.bool, label: "Active" },
                }}
                fieldElement={f => {
                    switch(f) {
                        case "colors":
                            return row => <ColorsPreview colors={row.colors} />;
                        case "options_count":
                            return row => row.options.length.toString();
                        default:
                            return null;
                    }
                }}
                fieldLink={f => f === "nbr" ? (row => `/palette/${row._id}`) : null}
                onDblClick={row => history.push(`/palette/${row._id}`)}
            />
        </Form>
    );
}
