import { FontFamilies } from "../FontFamilies";
import { Theme, FontWeight, FontAlterations, FontTransforms, FontKeys } from "../Theme";
import { pickRandom } from "./util";


const generateRandomFontCombo = () => {
    const [primaryFont, fCombinations] = FontFamilies[Math.floor(Math.random()*FontFamilies.length)];
    const fontCombinations = [primaryFont, ...fCombinations];
    const singleFont = Math.random() > 0.5;
    const fonts = [primaryFont, singleFont ? primaryFont : fontCombinations[Math.floor(Math.random()*fontCombinations.length)]];
    return Math.random() > 0.5 ? fonts : fonts.reverse();
}

const textSizeFactors = {
    h4: [1, 1.2, 1.4],
    h3: [1, 1.2, 1.4, 1.6, 1.8, 2.0],
    h2: [1.2, 1.4, 1.6, 1.8, 2.0, 2.2, 2.4],
    h1: [1.4, 1.6, 1.8, 2.0, 2.2, 2.4],
    caption: [1.4, 1.6, 1.8, 2.0, 2.2, 2.4, 3.0],
    motto: [0.8, 1.0, 1.2, 1.4, 1.6],
    menu: [0.8, 1.0, 1.2, 1.4],
}

const createRandomTextSizes = (): Record<FontKeys, number> => {
    const base = 16;
    const fontSize = (factor: number | null) => {
        return Math.round(base * (factor || 1.0));
    }
    return {
        main: base,
        caption: fontSize(pickRandom(textSizeFactors.caption)),
        motto: fontSize(pickRandom(textSizeFactors.motto)),
        h1: fontSize(pickRandom(textSizeFactors.h1)),
        h2: fontSize(pickRandom(textSizeFactors.h2)),
        h3: fontSize(pickRandom(textSizeFactors.h3)),
        h4: fontSize(pickRandom(textSizeFactors.h4)),
        menu: fontSize(pickRandom(textSizeFactors.menu)),
        accent: base,
        action: base,
    };
}

const CaptionWeightOptions: FontWeight[] = ['normal', 100, 300, 500, 700];
const MainWeightOptions: FontWeight[] = ['normal', 100, 300, 500];

const MainLineHeightOptions = [1, 1.1, 1.2, 1.25, 1.3, 1.5];

interface FontTransformConfig {
    noTransform?: boolean;
    exclude?: string[];
}

const randomFontAlteration = (cfg?: FontTransformConfig): FontAlterations => {
    const { noTransform, exclude } = cfg || {};
    return {
        italic: Math.random() > 0.9 ? true : undefined,
        transforms: (!noTransform && pickRandom([...FontTransforms, undefined].filter(x => !x || !exclude || !exclude.includes(x)))) || undefined,
    }
}

export const createRandomFontTheme = (): Theme["fonts"] => {
    const [fCaptions, fMain] = generateRandomFontCombo();

    const mainLineHeight = pickRandom(MainLineHeightOptions);

    const captionWeight = pickRandom(CaptionWeightOptions);
    const accentWeight = pickRandom(CaptionWeightOptions);
    const captionLineHeight = pickRandom(MainLineHeightOptions);

    const sizes = createRandomTextSizes();

    return {
        main: fCaptions,
        caption: fMain,
        
        params: {
            main:  {
                size: sizes.main,
                weight: pickRandom(MainWeightOptions),
                lineHeight: mainLineHeight,
            },
            caption:  {
                size: sizes.caption,
                weight: captionWeight,
                lineHeight: captionLineHeight,
                ...randomFontAlteration(),
            },
            motto:  {
                size: sizes.motto,
                weight: pickRandom(CaptionWeightOptions),
                lineHeight: pickRandom(MainLineHeightOptions.slice(0, 4)),
                ...randomFontAlteration(),
            },

            h1:  {
                size: sizes.h1,
                weight: captionWeight,
                lineHeight: captionLineHeight,
                ...randomFontAlteration(),
            },

            h2:  {
                size: sizes.h2,
                weight: captionWeight,
                lineHeight: captionLineHeight,
                ...randomFontAlteration(),
            },

            h3:  {
                size: sizes.h3,
                weight: captionWeight,
                lineHeight: captionLineHeight,
                ...randomFontAlteration(),
            },

            h4:  {
                size: sizes.h4,
                weight: captionWeight,
                lineHeight: captionLineHeight,
                ...randomFontAlteration(),
            },

            accent:  {
                size: sizes.accent,
                weight: accentWeight,
                lineHeight: mainLineHeight,
                ...randomFontAlteration({ noTransform: true }),
            },

            menu:  {
                size: sizes.menu,
                weight: pickRandom(CaptionWeightOptions),
                lineHeight: 1,
                ...randomFontAlteration(),
            },

            action:  {
                size: sizes.action,
                weight: accentWeight,
                lineHeight: 1,
                ...randomFontAlteration({ exclude: ["smallcaps"]}),
            },
        }
    };
}