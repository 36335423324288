import React from 'react';
import { Schema } from '../../hooks/useSchema';
import { FormControl } from './FormControl';
import { FieldDefinition, OnFieldChange } from './Common';
import { ValidationErrors } from './useValidationErrors';

interface FormControlsForFieldsProps {
    fields: FieldDefinition[];
    data: any;
    schema: Schema;
    onChange: OnFieldChange;
    errors?: ValidationErrors;
    withArea?: boolean;
}

export const FormControlsForFields = (props: FormControlsForFieldsProps) => {
    const { fields, data, schema, onChange, errors, withArea } = props;
    return <>
        {fields.map(([field, extraProps]) => (
            <FormControl
                key={field}
                field={field}
                schema={schema[field]}
                row={data}
                extraProps={{ withArea, ...extraProps}}
                onChange={onChange}
                errors={errors}
                />))}
    </>;
};
