import { Font } from "./Theme";

type FontFamily = [Font, Font[]];

// collect all font families for google cdn links
// const ffs = new Set(FontFamilies.map(([ff, combines]) => [ff, ...combines]).reduce((r, ffs) => [...r, ...ffs], []));
// console.log(Array.from(ffs).map(ff => `<link rel="stylesheet" href="https://fonts.googleapis.com/css?family=${encodeURIComponent(ff)}">`).join(""));


export const FontFamilies: FontFamily[] = [
    ["Roboto", ["Open Sans", "Noto Sans JP", "Lato"]],
    ["Open Sans", ["Roboto", "Lato", "Montserrat", "Poppins"]],
    ["STIX Two Text", ["Roboto", "Open Sans", "Raleway", "Source Sans Pro", "Playfair Display"]],
    ["Montserrat", ["Open Sans", "Roboto Slab", "Raleway", "Lato"]],
    ["Source Sans Pro", ["Roboto", "Lato", "Roboto Condensed", "Open Sans", "Poppins"]],
    ["Roboto Condensed", ["Roboto", "Source Sans Pro", "Lato", "Poppins", "Open Sans"]],
    ["Oswald", ["Open Sans", "Roboto", "Lato", "Montserrat", "Raleway"]],
    ["Noto Sans", ["Roboto", "Open Sans", "Noto Serif", "Noto Sans JP", "Source Sans Pro"]],
    ["Roboto Mono", ["Roboto", "Open Sans", "Roboto Slab", "Montserrat", "Lato"]],
    ["Raleway", []],
    ["Nunito", []],
    ["Merriweather", []],
    ["Roboto Slab", []],
    ["Ubuntu", []],
    ["PT Sans", []],
    ["Playfair Display", []],
    ["Open Sans Condensed", []],
    ["Rubik", []],
    ["Lora", []],
    ["Inter", []],
    ["Fira Sans", []],
    ["PT Serif", []],
    ["Noto Serif", []],
    ["Arimo", []],
    ["PT Sans Narrow", []],
    ["Source Code Pro", []],
    ["Bitter", []],
    ["Lobster", []],
    ["Yanone Kaffeesatz", []],
];
