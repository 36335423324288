import { useHistory } from "react-router-dom";
import { DefaultUser, login, useUser } from "../../userContext/UserContext";
import { SendData, useSendData } from "../useSendData";

export interface Credentials {
    login: string;
    password: string;
}

export interface Login extends SendData<Credentials, string> {
}

interface Config {
    redirectPathFromUser: (user: DefaultUser) => string;
}

export const useLoginForm = (loginApiPath: string, redirectPath: string, config?: Config): Login => {
    const history = useHistory();
    const user = useUser();
    const getTargetPath = config?.redirectPathFromUser || (() => redirectPath);

    return useSendData<Credentials, string>(loginApiPath, { login: "", password: "" }, {
        apiExtraParams: { noAuth: true },
        onSuccess: (token: string) => {
            const userDecoded = login(user, token);
            history.push(getTargetPath(userDecoded));
        }
    });
}