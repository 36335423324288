import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { Themed } from './Common';
import { Checkbox, Tags } from './Controls';
import { MockItem } from './MockListData';

const ListItemWrapper = styled.div<Themed>`
    display: flex;
    flex-flow: column;
    gap: ${props => props.t.geometry.paddingUnit}px;
    padding: ${props => props.t.geometry.paddingUnit*4}px 0;

    & p, & h4 {
        margin: 0;
    }

    & > div:first-child {
        & h4 {
            margin: 0;
        }
        display: flex;
        flex-flow: row;
        align-items: flex-end;
        justify-content: space-between;
    }
`;

const ListContainer = styled.div<Themed>`
    display: flex;
    flex-flow: column;
    
    & > ${ListItemWrapper}:not(:last-child) {
        border-bottom: ${props => props.t.elements.list.separator}px solid ${props => props.t.colors.main.text}40;
    }
`;

interface Props extends Themed {
    items: MockItem[];
}

const ListItem = ({ item, idx, t }: { item: MockItem, idx: number } & Themed) => {
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [id] = useState<string>(Math.ceil(10000*Math.random()).toString());

    return <ListItemWrapper key={idx} t={t}>
        <div>
            <h4>{item.title}</h4>
            <Checkbox
                id={id}
                label="turpis orci vel"
                isChecked={isSelected}
                setIsChecked={setIsSelected}
                t={t}
                />
        </div>
        <p>{item.description}</p>
        <Tags tags={item.tags} t={t} />
    </ListItemWrapper>
}

export const List = (props: Props) => {
    return <ListContainer t={props.t}>
        {props.items.map((item,idx) => <ListItem key={idx} idx={idx} item={item} t={props.t} />)}
    </ListContainer>
}
