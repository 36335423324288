import React from 'react';
import styled from 'styled-components/macro';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, withTheme } from '@material-ui/core';
import Logo from '../../logo.svg';


const Explanation = withTheme(styled.div`
    & > * {
        margin-bottom: 0.75em;
    }

    & u {
        text-decoration: none;
        border-bottom: 1px dashed ${props => props.theme.palette.primary.main};
    }
`);

const PopupLogoWrapper = styled.div`
    width: 100%;
    height: 30px;

    display: flex;
    flex-flow: row;
    justify-content: center;

    & > img {
        height: 100%;
    }
`;

interface Props {
    isOpen: boolean;
    close: () => void;
}

export const ExplanationDialog = (props: Props) => {
    const { isOpen, close } = props;

    return (
        <Dialog open={isOpen} onClose={() => close()}>
            <DialogTitle>
                <PopupLogoWrapper><img src={Logo} alt="baesline" /></PopupLogoWrapper>
            </DialogTitle>
            <DialogContent>
                <Explanation>
                    <Typography>
                        The app generates random themes that may serve as a visual <em>baseline</em> for a website or a different software product.
                    </Typography>
                    <Typography>
                        As of now, the theme is generated randomly, but we are working on a more sophisticated mechanism that would produce finer results.
                    </Typography>
                    <Typography>
                        A theme includes a combination of fonts, colors, font parameters, a couple variations of menu behaviors and little things like paddings.
                    </Typography>
                    <Typography>
                        Use the buttons or hotkeys to try different variants and browse the generated theme properties:
                    </Typography>
                    <ul>
                        <li><Typography><u>Try another (key N)</u> helps looking for options.</Typography></li>
                        <li><Typography><u>Remix colors (key C)</u> allows to alter the colors inside the same palette and helps to fine-tune.</Typography></li>
                        <li><Typography><u>Change all but colors (key T)</u> just does what it says and allows to browse options when you feel you've found the right color scheme.</Typography></li>
                        <li><Typography><u>Theme info (key i)</u> will show information about the current theme. Currently we only expose color information and font parameters there, but it will get better soon.</Typography></li>
                    </ul>
                </Explanation>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => close()}>got it</Button>
            </DialogActions>
        </Dialog>
    );
}
