import React, { ReactNode } from 'react';
import { withSnackbar, ProviderContext } from 'notistack';
import { UnauthenticatedError } from '../../api/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

interface State {
    isError: boolean;
}

interface Props {
    message?: string | ReactNode;
    showErrorDetails?: boolean;
}

export class CodedError extends Error {
    response: {};
    constructor(code: string) {
        super(code);
        this.response = { data: { error_code: code }};
    }
}

class ErrorBoundary extends React.Component<ProviderContext & RouteComponentProps & Props, State> {
    constructor(props: any) {
        super(props);
        this.state = { isError: false };
    }

    processError(error: Error) {
        const { enqueueSnackbar } = this.props;

        if(error instanceof UnauthenticatedError) {
            this.props.history.push('/login');
            
        } else {
            const errorHeader = this.props.message || <FormattedMessage id="error.general" />;
            let errorMessage: ReactNode = null;
            const e = error as any;
            if(e.response && e.response.status === 403) {
                errorMessage = <FormattedMessage id="error.noRights" />;
            } else if(e.response && e.response.data && e.response.data.error_code) {
                errorMessage = <FormattedMessage id={e.response.data.error_code} values={e.response.data.error_params || {}} />;
            } else if(this.props.showErrorDetails) {
                errorMessage = error.message;
            }
            
            const errorContent = errorMessage ?
                <div>
                    <p><b>{errorHeader}</b></p>
                    <p>{errorMessage}</p>
                </div> :
                errorHeader;
            enqueueSnackbar(errorContent, { variant: 'error', autoHideDuration: 5000 });
        }
    }

    componentDidMount() {
        window.onunhandledrejection = (error: PromiseRejectionEvent) => {
            this.processError(error.reason);
        }
    }

    componentDidCatch(error: Error, info: any) {
        this.processError(error);
    }

    render() {
        if(this.state.isError) {
            return <>Something dreadful happenned</>
        } else {
            return this.props.children;
        }
    }
}

export default withSnackbar(withRouter(ErrorBoundary));