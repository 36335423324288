import React from 'react';
import styled from 'styled-components/macro';
import { TextField, Typography } from '@material-ui/core';
import { Color, FontParams, Theme } from './Theme';

interface Props {
    theme: Theme;
}

const Block = styled.div`
    display: flex;
    flex-flow: column;
    width: 100%;

    & > h5 {
        margin-top: 1em;
    }
`;

const MainWrapper = styled.div`
    display: flex;
    flex-flow: column;
    gap: 30px;
    width: 100%;
`;

const BaseGrid = styled.div`
    width: 100%;
    display: grid;
    gap: 6px;

    & > * {
        width: 100px;
    }
`;

const FontParamsGrid = styled(BaseGrid)`
    grid-template-columns: repeat(5, 1fr);
`;

const ColorsGrid = styled(BaseGrid)`
    grid-template-columns: repeat(5, 1fr);
`;

const ColorViewWrapper = styled.div<{ color: Color }>`
    display: flex;
    flex-flow: row;

    & > ::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        content: '';
        width: 24px;
        height: 24px;
        border-radius: 2px;
        margin-left: 8px;
        background: ${props => props.color};
        box-shadow: 0 0 2px 0px rgba(0, 0, 0, 0.4);
    }
`;

export const ThemeInfo = (props: Props) => {
    const { theme } = props;

    const colorView = (label: string, color: string) => (
        <ColorViewWrapper color={color} key={label}>
            <TextField
                margin="normal"
                label={label}
                value={color}
                />
        </ColorViewWrapper>
    );

    const fontParamsView = (label: string, params: FontParams) => (
        <>
            <TextField
                key={`${label}-s`}
                margin="normal"
                label={`${label} size`}
                value={params.size}
                />
            <TextField
                key={`${label}-lh`}
                margin="normal"
                label={`${label} line h`}
                value={params.lineHeight}
                />
            <TextField
                key={`${label}-w`}
                margin="normal"
                label="Weight"
                value={params.weight}
                />
            <TextField
                key={`${label}-t`}
                margin="normal"
                label="Variant"
                value={params.transforms}
                />
            <TextField
                key={`${label}-st`}
                margin="normal"
                label="Style"
                value={params.italic ? 'italic' : ''}
                />
        </>
    )

    return (
        <MainWrapper>
            <Block>
                <Typography variant="h5">Colors</Typography>
                <ColorsGrid>
                    {colorView("Main b/g", theme.colors.main.background)}
                    {colorView("Block b/g", theme.colors.block.background)}
                    {colorView("Menu b/g", theme.colors.menu.background)}
                    {colorView("Action b/g", theme.colors.action.background)}
                    {colorView("Accent", theme.colors.accent)}

                    {colorView("Main text", theme.colors.main.text)}
                    {colorView("Block text", theme.colors.block.text)}
                    {colorView("Menu text", theme.colors.menu.text)}
                    {colorView("Action text", theme.colors.action.text)}
                </ColorsGrid>
            </Block>

            <Block>
                <Typography variant="h5">Fonts</Typography>
                <TextField
                    margin="normal"
                    label="Primary"
                    value={theme.fonts.main}
                    />
                <TextField
                    margin="normal"
                    label="Secondary"
                    value={theme.fonts.caption}
                    />
            </Block>

            <Block>
                <Typography variant="h5">Font sizes, px</Typography>
                <FontParamsGrid>
                    {([
                        ["Base", theme.fonts.params.main],
                        ["Caption", theme.fonts.params.caption],
                        ["Motto", theme.fonts.params.motto],
                        ["H1", theme.fonts.params.h1],
                        ["H2", theme.fonts.params.h2],
                        ["H3", theme.fonts.params.h3],
                        ["H4", theme.fonts.params.h4],
                        ["Accent", theme.fonts.params.accent],
                        ] as [string, FontParams][]).map(([l,p]) => fontParamsView(l,p))}
                </FontParamsGrid>
            </Block>
        </MainWrapper>
    );
}
