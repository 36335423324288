import { useEffect, useState } from "react";
import { Theme } from "../Theme";
import { createRandomTheme, replaceThemeColors } from ".";
import { useColorThemeGenerator } from "./useColorThemeGenerator";
import { LogoConfig, useLogoGenerator } from "../PseudoLogo/useLogoGenerator";


interface RandomThemeGenerator {
    identity: number;
    theme: Theme;
    logo: LogoConfig;
    next: () => void;
    remixColors: () => void;
    nextKeepColors: () => void;
}

export const useRandomTheme = (): RandomThemeGenerator => {
    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const { theme: colorTheme, createColorTheme, remixColors, identity } = useColorThemeGenerator();

    const createTheme = () => createRandomTheme({ colorTheme });
    const [theme, setTheme] = useState<Theme>(createTheme());
    const [themeBase, setThemeBase] = useState<Theme>(createTheme());

    const { logo, next: nextLogo } = useLogoGenerator();

    const nextKeepColors = () => {
        nextLogo();
        setThemeBase(createRandomTheme({ colorTheme: colorTheme }));
    }

    const next = () => {
        nextLogo();
        nextKeepColors();
        createColorTheme();
    }

    useEffect(() => {
        setTheme(replaceThemeColors(themeBase, colorTheme));
    }, [colorTheme, themeBase]);

    useEffect(() => {
        if(!isInitialized && identity) {
            setIsInitialized(true);
            next();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isInitialized, identity]);

    return {
        identity,
        theme,
        logo,

        next,
        nextKeepColors,
        remixColors,
    };
}