import { Color } from "../Theme";

type N3 = [number,number,number];

/**
* adapted from http://en.wikipedia.org/wiki/HSL_color_space.
* Assumes r, g, and b are contained in the set [0, 255] and
* returns h, s, and l in the set [0, 1].
*/
export const rgbToHsl = (rgb: number[]) => {
    const [r, g, b] = rgb.map(x => x / 255);
    
    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b);
    let h, s, l = (max + min) / 2;
    
    if (max === min) {
        h = s = 0; // achromatic
    } else {
        var d = max - min;
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
        
        switch (max) {
            case r: h = (g - b) / d + (g < b ? 6 : 0); break;
            case g: h = (b - r) / d + 2; break;
            case b: h = (r - g) / d + 4; break;
        }
        
        h = (h || 0) / 6;
    }
    
    return [ h, s, l ];
}

export const hslToRgb = ([h,s,l]: number[]): N3 => {
    var r, g, b;

    if(s === 0){
        r = g = b = l; // achromatic
    } else {
        var hue2rgb = function hue2rgb(p: number, q: number, t: number) {
            if(t < 0) t += 1;
            if(t > 1) t -= 1;
            if(t < 1/6) return p + (q - p) * 6 * t;
            if(t < 1/2) return q;
            if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
            return p;
        }

        var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        var p = 2 * l - q;
        r = hue2rgb(p, q, h + 1/3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1/3);
    }

    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export const rgbStrToNs = (rgb: string) => {
    return [rgb.substr(1,2), rgb.substr(3,2), rgb.substr(5,2)].map(x => parseInt(x, 16));
}

const rgbComp = (n: number) => n.toString(16).padStart(2,'0');

export const rgbToRgbStr = ([r,g,b]: N3) => {
    return `#${rgbComp(r)}${rgbComp(g)}${rgbComp(b)}`;
}


export interface ColorExt {
    code: string;
    rgb: [number, number, number];
    hsl: [number, number, number];
}

export const toColorExt = (code: Color) => {
    const rgb = rgbStrToNs(code);
    return {
        code,
        rgb,
        hsl: rgbToHsl(rgb),
    } as ColorExt;
}

export const hslToColorExt = (hsl: N3): ColorExt => {
    const rgb = hslToRgb(hsl);
    return {
        hsl,
        rgb,
        code: rgbToRgbStr(rgb),
    }
}


export const calcContrast = (c1: ColorExt, c2: ColorExt): number => {
    const [,, l1x] = c1.hsl;
    const [,, l2x] = c2.hsl;
    const l1 = Math.max(l1x, l2x);
    const l2 = Math.min(l1x, l2x);
    return (l1 + 0.05) / (l2 + 0.05);
}

export const calcRelativeLuminence = (c: ColorExt) => {
    const [r,g,b] = c.rgb.map(x => x/255.0).map(x => x <= 0.03928 ? x/12.92 : Math.pow((x + 0.055)/1.055, 2.4));
    return 0.2126*r + 0.7152*g + 0.0722*b;
}

export const calcContrastRL = (c1: ColorExt, c2: ColorExt): number => {
    const rl1 = calcRelativeLuminence(c1);
    const rl2 = calcRelativeLuminence(c2);
    return rl1 > rl2 ? (rl1 + 0.05) / (rl2 + 0.05) : (rl2 + 0.05) / (rl1 + 0.05);
}
