import { Button, ButtonGroup, Dialog, DialogContent, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { Theme } from './Theme';
import { ThemeInfo } from './ThemeInfo';
import { ThemePreview } from './ThemePreview';
import { useRandomTheme } from './themeGenerator';
import { InfoOutlined, NotificationsNone } from '@material-ui/icons';
import { Page } from '../Common/Page';
import { useGtag } from '../../useGtag';
import { LogoConfig } from './PseudoLogo';
import { ContactForm } from './ContactForm';
import { UpdatesForm } from './UpdatesForm';
import { ExplanationDialog } from './ExplanationDialog';
import { AppThemePreview } from './AppMode/AppThemePreview';
import { Link } from 'react-router-dom';

const PageWrapper = styled.div`
    width: 100%;
    padding: 25px 50px 0;
    gap: 25px;
    box-sizing: border-box;
    display: grid;
    grid-template-rows: max-content 1fr;
    overflow: hidden;
    align-items: center;

    max-height: 100%;
`;


const TopSection = styled.div`
    width: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
`;

const Controls = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
`;

const PreviewContainer = styled.div`
    width: 90%;
    box-shadow: 0 0 20px -10px rgba(0, 0, 0, 0.6);
    overflow-y: auto;
    justify-self: center;
    max-height: 100%;

    ::-webkit-scrollbar {
        width: 1px;
        background-color: #aaa;
    }
`;

const useSettings = () => {
    const mode_k = '__bs_mode';
    const explanation_k = '__bs_explanation_shown';

    return {
        mode: localStorage.getItem(mode_k) || 'app',
        explanationShown: localStorage.getItem(explanation_k) === 'y',

        setMode: (v: string) => localStorage.setItem(mode_k, v),
        setExplanationShown: (v: boolean) => localStorage.setItem(explanation_k, v ? 'y' : 'n'),
    }
}

interface Props {
    identity: number;
    theme: Theme;
    logo: LogoConfig;
    next: () => void;
    nextKeepColors: () => void;
    remixColors: () => void;
}

export const ThemeBrowser = (props: Props) => {
    const settings = useSettings();
    const [isInfoDialogOpen, setIsInfoDialogOpen] = useState<boolean>(false);
    const [isExplanationOpen, setIsExplanationOpenX] = useState<boolean>(!settings.explanationShown);
    const [isContactOpen, setIsContactOpen] = useState<boolean>(false);
    const [isUpdatesOpen, setIsUpdatesOpen] = useState<boolean>(false);
    const [actionCounter, setActionCounter] = useState<number>(0);

    const [mode, setModeX] = useState<"app" | "site">(settings.mode as 'app' | 'site');

    const setIsExplanationOpen = (x: (v: boolean) => boolean) => {
        settings.setExplanationShown(true);
        setIsExplanationOpenX(x);
    }

    const setMode = (m: 'app' | 'site') => { setModeX(m); settings.setMode(m); }

    const gtag = useGtag();

    const next = () => {
        props.next();
        setActionCounter(c => c+1);
        gtag.event("next_theme", "theme");
    }

    const nextKeepColors = () => {
        props.nextKeepColors();
        setActionCounter(c => c+1);
        gtag.event("next_keep_color", "theme");
    }

    const remixColors = () => {
        props.remixColors();
        setActionCounter(c => c+1);
        gtag.event("remix_colors", "theme");
    }

    const info = () => {
        setIsInfoDialogOpen(s => !s);
        setActionCounter(c => c+1);
        gtag.event("show_info", "info", undefined, isInfoDialogOpen ? 0 : 1);
    }

    useEffect(() => {
        const keyHandler = (e: KeyboardEvent) => {
            if(e.key === "n" || e.key === "т") {
                next();
                return;
            }

            if(e.key === "t" || e.key === "е") {
                nextKeepColors();
                return;
            }
    
            if(e.key === "c" || e.key === "с") {
                remixColors();
                return;
            }
    
            if(e.key === "i" || e.key === "ш") {
                info();
                return;
            }
        };
        document.addEventListener("keyup", keyHandler);
        return () => document.removeEventListener("keyup", keyHandler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.theme]);

    return (
        <Page
            ribbonLeft={<>
                <IconButton size="small" onClick={() => setIsExplanationOpen(x => !x)}>
                    <InfoOutlined />
                </IconButton>
                <ButtonGroup variant="outlined" disableElevation color="primary" size="small">
                    <Button variant={mode === "app" ? "contained" : undefined} onClick={() => setMode("app")}>app</Button>
                    <Button variant={mode === "site" ? "contained" : undefined} onClick={() => setMode("site")}>site</Button>
                </ButtonGroup>

                <Link to="/colors" style={{ textDecoration: 'none' }}><Button color="primary">color theme</Button></Link>
            </>}
            ribbonRight={<>
                <Button size="small" onClick={() => setIsContactOpen(true)}>contact</Button>
                {actionCounter > 4 &&
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        startIcon={<NotificationsNone />}
                        onClick={() => setIsUpdatesOpen(true)}>
                        updates
                    </Button>}
            </>}>
            <PageWrapper>
                <TopSection>
                    <Controls>
                        <Button onClick={next} color="primary">try another (n)</Button>
                        <Button onClick={remixColors}>remix colors (c)</Button>
                        <Button onClick={nextKeepColors}>change all but colors (t)</Button>
                        <Button onClick={info}>theme info (i)</Button>
                        
                    </Controls>
                </TopSection>
                
                <PreviewContainer>
                    {mode === "app" && <AppThemePreview theme={props.theme} logo={props.logo} />}
                    {mode === "site" && <ThemePreview theme={props.theme} logo={props.logo} />}
                </PreviewContainer>
                
            </PageWrapper>
            <ExplanationDialog isOpen={isExplanationOpen} close={() => setIsExplanationOpen(() => false)} />
            
            <Dialog open={isInfoDialogOpen} onClose={() => setIsInfoDialogOpen(false)} fullWidth maxWidth="md">
                <DialogContent>
                    <ThemeInfo theme={props.theme} />
                </DialogContent>
            </Dialog>

            <ContactForm isOpen={isContactOpen} close={() => setIsContactOpen(false)} />
            <UpdatesForm isOpen={isUpdatesOpen} close={() => setIsUpdatesOpen(false)} />
        </Page>
    );
}

export const ThemeBrowserContainer = () => {
    const tg = useRandomTheme();
    return <ThemeBrowser {...tg} />;
}
