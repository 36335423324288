import { MasterPalette, Palette } from "../../PaletteConfiguration/usePaletteConfig";
import { Color, ColorTheme } from "../Theme";
import { calcContrast, ColorExt, toColorExt } from "./colorUtil";
import { pickRandom } from "./util";

interface ColorThemeConfig {
    injectOffBackground?: boolean;
    rotate?: number;
    minContrast?: number;
}

interface ColorExtWithC extends ColorExt {
    contrasters: ColorExt[];
}

const groupColors = (palette: Color[], minContrast: number): ColorExtWithC[] => {
    const enoughContrast = (c1: ColorExt, c2: ColorExt) => calcContrast(c1, c2) >= minContrast;

    const withHsl = palette.map(toColorExt);

    return withHsl.map(c => ({
        ...c,
        contrasters: withHsl.filter(cx => enoughContrast(c, cx)),
    }))
}

export const createColorTheme = (palette: Color[], config: ColorThemeConfig): ColorTheme => {
    const { injectOffBackground } = config;
    const rotate = config.rotate || 0;
    const intermPalette = [...palette, ...palette].slice(rotate, rotate+palette.length);
    const resultPalette = [ ...(injectOffBackground ? ["#ffffff"] : []), ...intermPalette];

    const colorsExt = groupColors(resultPalette, config.minContrast || 0);

    // TODO: check if have two contrast pairs. Throw in a pair if needed
    const mainBack = pickRandom(colorsExt.filter(c => c.contrasters.length > 0));
    const mainText = pickRandom(mainBack.contrasters);

    const main = {
        background: mainBack.code,
        text: mainText.code,
    };

    const blockBack = pickRandom(colorsExt.filter(c => c !== mainBack && c.contrasters.length));

    const block = {
        background: blockBack.code,
        text: pickRandom(blockBack.contrasters).code,
    };

    const accent = mainBack.contrasters.length > 1 ?
        pickRandom(mainBack.contrasters.filter(c => c.code !== mainText.code)).code
        : mainText.code;

    const actionBackPick = pickRandom(mainBack.contrasters);
    const actionBack = colorsExt.find(c => c.code === actionBackPick.code) as ColorExtWithC;

    const action = {
        background: actionBack.code,
        text: pickRandom(actionBack.contrasters).code,
    }

    const menuSameAsMain = Math.random() > 0.5;

    const menu = menuSameAsMain ? { ...main } : { ...block };
    const menuAction = menuSameAsMain ? { ...action } : { ...main };

    return {
        main,
        block,
        menu,
        menuAction,
        accent,
        action,
        palette: resultPalette,
    };
}

export const createColorThemeFromPalette = (palette: MasterPalette): ColorTheme => {
    const paletteOptionToColors = (po: Palette) => {
        return {
            back: palette.colors[po.colors.back],
            text: palette.colors[po.colors.text],
            accent: palette.colors[po.colors.accent],
            accent_contrast: palette.colors[po.colors.accent_contrast],
        }
    }
    const p1 = paletteOptionToColors(pickRandom(palette.options));
    const p2 = paletteOptionToColors(pickRandom(palette.options));

    const resultPalette = Array.from(new Set([...Object.values(p1), ...Object.values(p2)]));

    const menuPalette = Math.random() > 0.5 ? p1 : p2;

    return {
        main: {
            background: p1.back,
            text: p1.text,
        },
        block: {
            background: p2.back,
            text: p2.text,
        },
        menu: {
            background: menuPalette.back,
            text: menuPalette.text,
        },
        menuAction: {
            background: menuPalette.accent,
            text: menuPalette.accent_contrast,
        },
        accent: p1.accent,
        action: {
            background: p1.accent,
            text: p1.accent_contrast,
        },
        palette: resultPalette,
    };
}

