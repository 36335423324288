import React from 'react';
import { ThemeProvider, StylesProvider, } from '@material-ui/core/styles';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { SnackbarProvider } from 'notistack';
import ErrorBoundary from './toolympus/components/app/ErrorBoundary';
import { theme } from './theme';
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import LocalizationProvider from './toolympus/components/localization/LocalizationProvider';
import { FormatsProvider } from './toolympus/components/schemed';
import { ThemeBrowserContainer } from './components/ThemeBrowser';
import { PaletteConfiguratorPage } from './components/PaletteConfiguration/PaletteConfigurator';
import { PalettesList } from './components/PaletteConfiguration/PalettesList';
import { LoginPage } from './Auth/LoginPage';
import { ContactFormPage } from './toolympus/components/ContactForm/ContactFormPage';
import { SimpleSvgEditor } from './components/SvgEditor/SimpleSvgEditor';
import { ColorThemeBrowserContainer } from './components/ColorThemeCreator/ColorThemeBrowser';

const App: React.FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <StylesProvider injectFirst >
                <LocalizationProvider locale="ru" defaultLocale="ru">
                    <FormatsProvider>
                        <Router>
                            <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                                <ErrorBoundary>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <Switch>
                                            <Route path='/palette/:id' component={PaletteConfiguratorPage} />
                                            <Route path='/palette' component={PalettesList} />
                                            <Route path='/contact-forms' component={() => <ContactFormPage apiPath="/api/contact-form" />} />
                                            <Route path='/svg-edit' component={SimpleSvgEditor} />
                                            <Route path="/login" component={LoginPage} />
                                            <Route path='/colors' component={ColorThemeBrowserContainer} />
                                            <Route path='/' component={ThemeBrowserContainer} />
                                        </Switch>
                                    </MuiPickersUtilsProvider>
                                </ErrorBoundary>
                            </SnackbarProvider>
                        </Router>
                    </FormatsProvider>
                </LocalizationProvider>
            </StylesProvider>
        </ThemeProvider>
    );
}

export default App;