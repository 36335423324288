import React, { ReactNode } from 'react';
import styled from 'styled-components/macro';
import { CheckBox as CheckboxIcon, CheckBoxOutlineBlank as CheckboxOutlineIcon } from '@material-ui/icons';
import { Themed } from '../Common';

const CheckboxLabel = styled.label<Themed & { isChecked?: boolean }>`
    display: flex;
    flex-flow: row;
    align-items: center;

    gap: ${props => props.t.geometry.paddingUnit}px;

    & input {
        display: none;
    }

    & svg {
        color: ${props => props.isChecked ? props.t.colors.accent : 'inherit'}
    }
`;

interface Props extends Themed {
    id?: string;
    label: ReactNode;
    isChecked: boolean;
    setIsChecked: (v: boolean) => void;
}

export const Checkbox = (props: Props) => {
    const { id, label, isChecked, setIsChecked, t } = props;
    return (
        <CheckboxLabel t={t} htmlFor={id} isChecked={isChecked}>
            {isChecked && <CheckboxIcon />}
            {!isChecked && <CheckboxOutlineIcon />}
            <input id={id} type="checkbox" checked={isChecked} onChange={e => setIsChecked(e.target.checked)} />
            {label}
        </CheckboxLabel>
    );
}
