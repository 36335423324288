import React from 'react';
import { ButtonGroup, Button } from '@material-ui/core';

interface ButtonOptionPickerProps<OptionType> {
    selected: OptionType;
    setSelected: (o: OptionType) => void;
    options: [string, OptionType][];
}

export const ButtonOptionPicker = <OptionType, >({ options, selected, setSelected }: ButtonOptionPickerProps<OptionType>) => {
    return <ButtonGroup>
        {options.map(([ label, option]) => (
            <Button
                size="small"
                color="primary"
                variant={selected === option ? 'contained' : 'outlined'}
                onClick={() => { if(selected !== option) { setSelected(option) }}}>
                {label}
            </Button>
        ))}
    </ButtonGroup>
}